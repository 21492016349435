<template>
    <div class="slo-creation-sidebar">
        <div class="flex-fill flex-column slo-creation-sidebar-panel pa-3" :class="{ 'panel-closed': closed }">

            <div class="d-flex justify-space-between flex-shrink-0 flex-grow-0">
                <h3 class="pt-3">Create SLO</h3>
                <fe-icon-btn useIcon="fas fa-times" @click="close" />
            </div>

            <div style="overflow: scroll;" class="flex-shrink-1 flex-grow-1">

                <fe-remote-combo
                    label="Assessment"
                    v-model="selectedAssessment"
                    :items="assessmentList"
                    itemText="tooltip"
                    itemValue="sub_category_id"
                    :rules="rules.assessment"
                    validateOnBlur
                />

                <fe-remote-combo
                    label="Starting Window"
                    v-model="selectedWindow"
                    :items="windowList"
                    itemText="displayText"
                    itemValue="dataPointId"
                    :rules="rules.window"
                    :disabled="!selections.assessment"
                />

                <fe-remote-combo
                    label="Performance Band Target Group"
                    v-model="selectedDescriptors"
                    :items="descriptorList"
                    itemText="target_descriptor_name"
                    itemValue="target_descriptor_id"
                    :disabled="!selections.window"
                    multiple
                    moreText
                />

                <fe-remote-combo
                    label="Students"
                    v-model="selectedStudents"
                    :items="studentList"
                    itemText="student_full_name"
                    itemValue="student_id"
                    :disabled="!selections.window"
                    multiple
                    byId
                    moreText
                />

            </div>

            <div class="flex-shrink-0 flex-grow-0">
                <fe-btn usage="primary" block width="95%" :disabled="invalid" @click="beginCreate">
                    Confirm
                </fe-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'SloCreationDatawallSidebar',
        components: { },
        props: {
            selections: { type: Object, required: true },
            records: { type: Array, required: true },
            raw: { type: Object, required: true },
            studentIds: { type: Array, required: true }
        },
        data () {
            return {
                closed: true,
                rules: {
                    assessment: [
                        (v) => !!v || 'Please select an Assessment'
                    ],
                    window: [
                        (v) => !!v || 'Please select a Window'
                    ],
                }
            }
        },
        computed: {
            invalid () { return this.studentIds.length == 0 },
            columns () { return this.raw.columns },
            selectedAssessment: {
                get () { return this.selections.assessment },
                set (v) { this.$emit('selections', { assessment: v }) }
            },
            assessmentList () {
                return this.columns.filter(c => {
                    return !!c.dataPointTypeId
                })
            },
            selectedWindow: {
                get () { return this.selections.window },
                set (v) {
                    this.$emit('selections', {
                        assessment: this.selectedAssessment,
                        window: v
                    })
                }
            },
            windowList () {
                return (this.selectedAssessment?.columns || []).map(itm => {
                    return Object.assign({}, itm, {
                        displayText: this.$stripHtml(itm.text)
                    })
                })
            },
            selectedDescriptors: {
                get () { return this.selections.targetDescriptors },
                set (v) {
                    // If one or more target set is selected, then any students that
                    // does not have a score for that target group(s) must be de-selected
                    if (this.selectedWindow && v.length && this.selectedStudents?.length) {
                        let targetDescriptorIds = v.map(itm => itm.target_descriptor_id)

                        this.selectedStudents = this.selectedStudents.filter(studentId => {
                            const target_descriptor_id = 0
                            let key = `${this.selectedWindow.dataIndex}:Details`
                            return !!this.raw.students.find(itm => (itm.student_id == studentId && itm[key]?.[target_descriptor_id] && targetDescriptorIds.includes(itm[key][target_descriptor_id])))
                        })
                    }

                    // If user removes the all selected (clear all or remove last remaining),
                    // any currently selected student should remain selected (because no filtering is occurring)
                    // The ag-grid will need an emit reminder to re-check those checkboxes after it
                    // redraws (redraw occurs when target descriptor choices change)
                    else if (v.length === 0) {
                        this.$emit('setStudentIds', this.studentIds)
                    }

                    let selections = {
                        assessment: this.selectedAssessment,
                        window: this.selectedWindow,
                        targetDescriptors: v,
                        students: this.selectedStudents
                    }

                    this.$emit('selections', selections)
                }
            },
            descriptorIds () {
                if (!this.selectedWindow) return []
                let k = `${this.selectedWindow.dataIndex}:Details`
                const target_descriptor_id = 0
                let descriptorIds = this.records.filter(itm => itm[k]?.[target_descriptor_id]).map(itm => itm[k][target_descriptor_id])
                return [...new Set(descriptorIds)]
            },
            descriptorList () {
                return Object.keys(this.raw.target_descriptors).map(k => {
                    return this.raw.target_descriptors[k]
                }).filter(itm => {
                    return this.descriptorIds.includes(itm.target_descriptor_id)
                }).sort((a,b) => {
                    return a.target_descriptor_rank - b.target_descriptor_rank
                })
            },
            selectedStudents: {
                get () { return this.studentIds },
                set (v) { this.$emit('setStudentIds', v) }
            },
            studentList () {
                if (this.selectedDescriptors?.length && this.selectedWindow) {
                    let targetDescriptorIds = this.selectedDescriptors.map(itm => itm.target_descriptor_id)
                    let key = `${this.selectedWindow.dataIndex}:Details`
                    const target_descriptor_id = 0
                    return this.records.filter(itm => (itm[key]?.[target_descriptor_id] && targetDescriptorIds.includes(itm[key][target_descriptor_id])))
                } else {
                    return this.records
                }
            }
        },
        methods: {
            close () {
                this.closed = true
                setTimeout(() => {
                    this.$emit('closed')
                }, 500)
            },
            beginCreate () {
                let me = this
                let w = this.$dockableWindow({
                    name: 'Create SLO',
                    component: 'slo-creation-datawall',
                    attrs: {
                        raw: this.raw,
                        assessment: this.selectedAssessment,
                        window: this.selectedWindow,
                        windowItems: this.windowList,
                        descriptorItems: this.selectedDescriptors,
                        studentIds: this.studentIds
                    },
                    events: {
                        close() {
                            me.$store.commit('global/removeDockedWindow', w)
                            me.$emit('closed') // closes sidebar panel
                        }
                    }
                })
            }
        },
        mounted () {
            this.$nextTick(() => {
                this.closed = false
            })
        }
    }
</script>

<style lang="scss" scoped>
    .slo-creation-sidebar {
        position: fixed;
        top: 50px;
        right: 0;
        width: 350px;
        padding-left: 10px;
        bottom: 0;
        box-shadow: -4px 4px 4px 0px #000000 6%;
        z-index: 10;
        display: flex;
        overflow: hidden;
        &-panel {
            box-shadow: -4px 0 4px 0 rgba(#000000, 12%);
            background: #fff;
            transition: transform .5s;
            &.panel-closed {
                transform: translateX(310px);
            }
        }
    }
</style>
