export const global = {
    namespaced: true,
    state: {
        demographics: null,
        demographicsCacheUntilMoment: null,
        currentModule: null,
        recentNavigation: [],
        lastLaunchpadVisit: null,
        pings: {},
        loading: false,
        sessionUser: null,
        staleDashboardWidgets: {},
        userPreferences: {},
        defaultSchool: null,
        sessionFlags: {},
        alphaMaps: null,
        currentYear: null,
        dockableWindows: [],
        hideNav: false,
        restrictedUI: false,
        selectedStudent: false,
        dockedWindowId: 1,
        districtConfig: null,
        uiSavedSearch: null,
        googleClientId: null,
        googleCalendarEnabled: false,
        googleCalendarSyncPreference: null, // Should be 'rememberYes', 'rememberNo', or 'ask'
        panelObjectEvents: [],
        uris: [],
        shareableStores: {},
        studentCard: null,
        studentsToTag: {
            show: false,
            students: [],
            callback: null
        },
        fbMonitorAvailable: false,
        fbMonitorManage: false,
        kpiDashboardCount: null,
        launchpadWidgetHeights: {
            firstRow: '300px',
            secondRow: '435px',
            thirdRow: '435px'
        }

},
    mutations: {
        set(state, payload) {
            state[payload.state] = payload.value
        },
        updateSessionFlag(state, { flag, value }) {
            if (flag) {
                state.sessionFlags = {
                    ...state.sessionFlags,
                    [flag]: value
                }
            }
        },
        updateDistrictInfo(state, { flag, value }) {
            state.shareableStores = {
                ...state.shareableStores,
                district: {
                    ...state.shareableStores.district,
                    [flag]: value,
                }
            }
        },
        updateDockableWindow(state, payload) {
            state.dockableWindows = state.dockableWindows.map(win => {
                return (win.dockedWindowId === payload.id)
                    ? Object.assign(win, payload.obj)
                    : win
            })
        },
        flagStaleDashboardWidget(state, { id }) {
            state.staleDashboardWidgets[id] = true
        },
        unflagStaleDashboardWidget(state, { id }) {
            delete state.staleDashboardWidgets[id]
        },
        addDockableWindow(state, payload) {
            payload.dockedWindowId = state.dockedWindowId++
            state.dockableWindows.push(payload)
        },
        addPanelObjectEvent(state, payload) {
            state.panelObjectEvents.push(payload)
        },
        reloadLaunchpad(state, payload) {
            state.lastLaunchpadVisit = new Date().getTime()
        },
        ping(state, payload) {
            state.pings = { ...state.pings, [payload]: new Date().getTime() }
        },
        tagStudents(state, payload) {
            state.studentsToTag.show=payload.show
            state.studentsToTag.students = payload.students

            if (payload.show === false && state.studentsToTag.callback) {
                state.studentsToTag.callback()
            } else if (payload.show) {
                state.studentsToTag.callback = payload.callback ? payload.callback : null
            }
        },
        showStudentCard(state, payload) {
            if (!payload) {
                state.studentCard = null
            } else {
                let year = payload.schoolYearId ? payload.schoolYearId : state.currentYear.id
                let image = payload.imageUrl ? payload.imageUrl : '/educlimber/students.php?action=get&property=student_image&id='+payload.studentId
                state.studentCard = {
                    show: true,
                    studentUrl: 'students.php?action=get&property=grade_history&id=' + payload.studentId+'&school_year_id='+year,
                    imageUrl: image,
                    top: payload.top > 500 ? (payload.top-200)+'px' : payload.top+'px',
                    left: payload.left+'px'
                }
            }
        },
        removeDockedWindow(state, payload) {
            if (!payload.isStrict && state.dockableWindows.length == 1) {
                state.dockableWindows = []
                return
            }

            state.dockableWindows = state.dockableWindows.filter(w => w.dockedWindowId !== payload.dockedWindowId)
        },
        // Does this client/user have FastBridge monitors?
        setFbMonitorAvailable(state, { value }) {
            state.fbMonitorAvailable = value
        },
        setFbMonitorManage(state, { value }) {
            state.fbMonitorManage = value
        },
        setKpiDashboardCount(state, { value }) {
            state.kpiDashboardCount = value
        },
    },
    actions: {
        // global stores and states
        loadSessionUser({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                this._vm.$axios.get('users.php?action=get&activeUser=1').then(response => {
                    // let idx = response.data.security_codes.indexOf('MANAGE_THRESHOLDS')
                    // response.data.security_codes.splice(idx, 1)
                    commit('set', { state: 'sessionUser', value: response.data })
                    dispatch('flags/initialize', {
                        force: true,
                        userId: response.data.user.id,
                        userFirst: response.data.user.fname,
                        userLast: response.data.user.lname,
                        userEmail: response.data.user.email_addr,
                        userLogin: response.data.user.login,
                        districtId: response.data.district.login_id
                    }, {
                        root: true
                    })
                    resolve()
                })
                .catch(error => {
                    reject()
                })
            })

        },
        loadUserPreferences({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                this._vm.$axios.get('preferences.php?action=get').then(response => {
                    let prefs = response.data.preferences.reduce((acc,v) => {
                        acc[v.code] = v
                        return acc
                    },{})

                    // Fetch the school object if a default school exists
                    if (prefs.DEFAULT_SCHOOL?.user_value) {
                        this._vm.$modelGet('school', { school_id: prefs.DEFAULT_SCHOOL.user_value, property: 'single_school', incl_groups: 1 }).then(schools => {
                            commit('set', { state: 'userPreferences', value: prefs })
                            commit('set', { state: 'defaultSchool', value: schools.length > 0 ? schools[0] : null })
                            resolve()
                        })
                    }

                    else {
                        commit('set', { state: 'userPreferences', value: prefs })
                        resolve()
                    }
                }).catch(error => {
                    reject()
                })
            })

        },
        loadAlphaMaps({ dispatch, commit }) {
            this._vm.$axios.get('alphaMap.php', {
                params: {
                    action: 'get',
                    property: 'detailed',
                }
            }).then(response => {
                commit('set',{ state:'alphaMaps', value:response.data.alpha_maps })
            })
        },
        // createUiSavedSearch({ dispatch, commit }, payload) {
        //     this._vm.$axios.post('crud.php?action=create&property=UiSavedSearch', JSON.stringify({
        //         ui_key: payload.key
        //     }))
        // },
        updateUiSavedSearch({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                let p = {
                    ui_key: payload.uiKey,
                    json: payload.json,
                    args: payload.args
                }
                this._vm.$axios.post('savedSearch.php?action=update&property=ui_saved_search', JSON.stringify({ ui_saved_search: [p] }))
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },

        getUiSavedSearch({ dispatch, commit, state }, payload) {
            return new Promise((resolve, reject) => {
                this._vm.$axios.get('crud.php?action=get&property=UiSavedSearch&ui_key='+payload.uiKey)
                    .then(response => {
                        // Retain existing saved search data; update the requested ui key
                        let newState = {
                            ...state.uiSavedSearch,
                            [payload.uiKey]: response.data.ui_saved_search,
                        }

                        commit('set', {
                            state: 'uiSavedSearch',
                            value: newState
                        })

                        if (response.data?.ui_saved_search) {
                            return resolve(response.data.ui_saved_search)
                        }

                        reject("Unable to retrieve ui_saved_search")
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },

        // This will load all currently configured demographics and their options (if applicable).
        //
        // If you pass { ifEmpty: true } into the payload, values will only load once and
        // will then work off the store.
        //
        // If you pass { cacheUntilMoment: [moment] } into the payload, values will only
        // load once until the moment you specify has been reached.
        async loadDemographics({ commit, state }, payload) {
            let isRefreshRequired = !state.demographics // If data has never been fetched, we must load it for the first time
            if (!payload?.ifEmpty) {
                // ifEmpty was not provided as truthy; reload data
                isRefreshRequired = true

                // Unless user previously indicated to cache pulled data until a given moment
                if (!!state.demographicsCacheUntilMoment && this._vm.$dayjs() <= state.demographicsCacheUntilMoment) {
                    isRefreshRequired = false
                }
            }

            if (isRefreshRequired) {
                let demographicsResponse = await this._vm.$axios.get('crud.php?property=Demographic&action=get')
                let demographics = (demographicsResponse.data && demographicsResponse.data.demographic) || []

                // Retrieve options for any custom demographic that requires selecting
                // from a pre-prescribed list of options (e.g. dropdown)
                let withOptions = demographics.filter(itm => itm.value_type == 'option')
                let ids = withOptions.map(itm => itm.id)

                if (ids.length > 0) {
                    let res = await this._vm.$axios.get(`crud.php?action=get&property=DemographicOption&demographic_id=${ids.join(',')}`)

                    // Options will come back as a flat array, so we'll have to
                    // map each option item to the correct demographic item
                    for (let opt of res.data.demographic_option) {
                        let demo = demographics.find(itm => itm.id === opt.demographic_id)
                        if (demo) {
                            if (!demo.options) {
                                demo.options = []
                            }

                            demo.options.push(opt)
                        }
                    }
                }

                commit('set', {
                    state: 'demographics',
                    value: demographics
                })

                // Future executions of this action will skip refresh as long as
                // the fetched data from this call is still available
                if (payload?.cacheUntilMoment) {
                    commit('set', {
                        state: 'demographicsCacheUntilMoment',
                        value: payload.cacheUntilMoment
                    })
                }

                return demographics
            } else {
                return state.demographics
            }
        },

        // Reload shareable stores when we know data within has been changed (e.g. intervention levels)
        reloadShareableStores({ dispatch, commit }) {
            this._vm.$axios.get('qry.php?property=shareable_stores').then(shareables => {
                let d = this._vm.$ecResponse(shareables, 'shareable_stores')

                commit('set', {
                    state: 'shareableStores',
                    value: d,
                })
            })
        },
    },
    getters: {
        demographicsAll: state => state.demographics || [],
        demographicsSearchable: (state, getters) => getters.demographicsAll.filter(dmo => dmo.can_search),
        demographicsViewable: (state, getters) => getters.demographicsAll.filter(dmo => dmo.can_view),
        dockableWindows: state => state.dockableWindows || [],
    }
}
