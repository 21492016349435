<template>
    <fe-window
        title="View Attachment"
        dismissButtonText="Cancel"
        @dismiss="$emit('close')"
        @close="$emit('close')"
        :acceptButtonDisabled="true"
        :showTools="false"
    >
        <div v-if="this.viewType == 'pdf'" style="display: block; width: 100%; margin: 0 auto; height: 100%;">
            <iframe
                :src="pdfData"
                style="display: block; width: 100%; height: 100%;"
            ></iframe>
        </div>

        <img v-if="this.viewType == 'image'" :src="this.pdfData" class="my-4" style="display: flex; width: 50%; margin: 0 auto;" />
    </fe-window>
</template>

<script>
export default {
    name: 'PdfView',

    props: {
        pdfData: {
            type: String,
            default: ''
        },
        viewType: {
            type: String,
            default: ''
        }
    },

    data() {
		return {
			src: '',
			numPages: 0
		}
    },
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.student-controls {
            width: 242px;
        }
    }

    ::v-deep .fe-window-title {
        width: calc(100vw - 25%) !important;
    }
</style>