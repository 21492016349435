<template>
    <div>
        <v-menu
            v-model="dateMenuOpen"
            content-class="fe-filter-btn-menu"
            :close-on-content-click="false"
            :close-on-click="!showCustomDatePicker"
            :nudge-bottom="5"
            offset-y
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    rounded
                    depressed
                    class="last-sent-filter-btn"
                    data-test="last-sent-filter-btn"
                >
                    <span>{{ selectedDateLabel || buttonLabel }}</span>
                    <i class="fas fa-caret-down"></i>
                </v-btn>
            </template>
            
            <v-list>
                <v-list-item
                    :data-test="`last-sent-filter-option-${item.label.toLowerCase().replace(/\s+/g, '-')}`"
                    v-for="(item, i) in dateOptions"
                    :key="`date-range-option-${i}`"
                    @click="onOptionSelected(item)"
                >
                    <span>{{ item.label }}</span>
                </v-list-item>
            </v-list>
        </v-menu>
        
        <div :style="pickerContainerStyle">
            <div v-if="showCustomDatePicker" class="calendar" :style="[{ position: 'absolute', top: '250px' }, calendarStyle]">
                <functional-calendar
                    v-model="calendarDateRange"
                    dateFormat="yyyy-mm-dd"
                    @input="onCalendarInput"
                    is-date-range
                />

                <v-divider/>

                <div class="d-flex" style="padding: 4px 0; margin-top: 6px; margin-bottom: 6px;">
                <v-spacer />
                <fe-btn
                    data-test="last-sent-custom-date-range-apply-btn"
                    usage="primary"
                    :disabled="!selectedDateRange.dateRange.start || !selectedDateRange.dateRange.end"
                    @click="onApplyClicked"
                >
                    Apply
                </fe-btn>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  import { FunctionalCalendar } from 'vue-functional-calendar'
  import { useCalendarDateRange } from '@/components/common/util/FunctionalCalendarUtil'
  
  export default {
    name: 'DateRangeFilter',
    
    components: {
      FunctionalCalendar
    },
    
    props: {
        dateOptions: {
            type: Array,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
        buttonLabel: {
            type: String,
            default: 'Last Sent Date'
        },
        pickerContainerStyle: {
            type: Object,
            default: () => ({
                position: 'relative'
            })
        },
        calendarStyle: {
            type: Object,
            default: () => ({})
        }
    },
    
    data() {
      return {
        dateMenuOpen: false,
        showCustomDatePicker: false,
        selectedDateRange: {
          dateRange: {
            start: null,
            end: null
          }
        }
      }
    },
    
    computed: {
        calendarDateRange: {
            get() {
                return useCalendarDateRange(this.selectedDateRange).get()
            },
            set(value) {
                if (value?.dateRange) {
                    this.selectedDateRange.dateRange.start = value.dateRange.start
                    this.selectedDateRange.dateRange.end = value.dateRange.end
                    this.$emit('calendar-input', value)
                }
            }
        },
      selectedDateLabel() {
        return this.filters.dates?.included?.[0]?.label || ''
      }
    },
    
    methods: {
        onOptionSelected(item) {
            if (item.value === 'custom') {
                this.showCustomDatePicker = true
                return
            }
        
            this.$emit('date-selected', item)
        },
      
        onCalendarInput(event) {
            if (!event?.dateRange) return
            
            this.selectedDateRange.dateRange = { 
                start: event.dateRange.start,
                end: event.dateRange.end 
            }
            this.$emit('calendar-input', event)
        },
      
        onApplyClicked() {
            this.$emit('apply-date-range', this.selectedDateRange)
            this.closeMenu()
        },
      
        closeMenu() {
            this.dateMenuOpen = false
            this.showCustomDatePicker = false
        }
    }
  }
</script>
  
<style lang="scss" scoped>
.last-sent-filter-btn {
    min-width: 170px !important;
    max-width: 188px;
    margin-top: 0 !important;
    background-color: #E5F0F4 !important;
    height: 36px !important;
    color: #005673 !important;
}
  
.last-sent-filter-btn i {
    margin-left: 4px;
    font-size: 16px !important;
}

.calendar {
    position: absolute;
    z-index: 1;
    border: 1px solid #ECEDF1;
    box-shadow: 0 1px 5px #9297A6;
    background-color: white;
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;

    ::v-deep .vfc-main-container {
        box-shadow: none !important;
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        height: 300px;
    }

    ::v-deep .vfc-top-date {
        font-size: 14px !important;
    }

    ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
        height: 10px !important;
        width: 10px !important;
    }

    ::v-deep .vfc-dayNames span {
        color: #7E8494 !important;
        margin-bottom: 0 !important;
        font-size: 13px;
    }

    ::v-deep .vfc-today:not(.vfc-marked) {
        background-color: transparent !important;
        color: #000 !important;
        border: 1px solid var(--fe-primary) !important;
    }

    ::v-deep .vfc-week {
        font-size: 13px;
    }

    ::v-deep .vfc-months-container {
        .vfc-content {
            width: 55% !important;
        }
    }

    ::v-deep .vfc-marked {
        background: #CCE2E9 !important;
    }

    ::v-deep .vfc-start-marked {
        background: #006C90 !important;
    }

    ::v-deep .vfc-end-marked {
        background: #006C90 !important;
    }
}
</style>