<template>
    <explore-widget
        widgetTitle="Chronic Absence"
        toolTip="Chronic absence is when a student is absent (excused or unexcused) for at least 10% of the days. The overall rate indicates the percentage of students who are chronically absent in a given time period."
        dataTestBaseString="chronic-absence"
        :cardHeight="launchpadWidgetHeights.thirdRow"
        :countCard="countCard"
        @exploreClick="caExploreClicked"
        ref="chronic-absence-explore-widget"
    >
        <template v-slot:widget-body>
            <div class="center-ca-data" v-if="ready">
                <div v-if="error" id="ca-change-container">
                    <v-icon style="float: left; padding-right: 4px;" color="#bf0f00 !important">far fa-exclamation-circle</v-icon>
                    <div style="color: #bf0f00; float: right;">Data temporarily unavailable</div>
                </div>
                <div v-if="unavailable" id="ca-change-container">
                    <v-icon style="float: left; padding-right: 4px;" color="#136eb3 !important">fal fa-info-circle</v-icon>
                    <div style="float: right; color:#136eb3">Attendance data not yet available</div>
                </div>
            </div>
        </template>
    </explore-widget>
</template>

<script>
import { mapState } from 'vuex'
import ExploreWidget from './ExploreWidget.vue';

export default {
    name: 'ChronicAbsenceCard',

    components: {
        ExploreWidget
    },

    data() {
        return {
            percentChange: null,
            error: false,
            unavailable: false,
            ready: false,
            countCard: {
                text: 'Students chronically absent this school year',
                show: false,
                change: null,
                error: false,
                unavailable: false,
                count: null,
                percentage: true,
                displayUpDownArrow: null,
                title: 'chronic-absence'
            }
        }
    },

    computed: {
        ...mapState('global', ['launchpadWidgetHeights']),
    },

    mounted() {
        this.loadCAData()
    },

    methods: {
        loadCAData() {
            this.$axios.get(this.$models.getUrl('chronicAbsenceSummary','read'))
                .then(response => {
                    if (response.status !== 200) {
                        this.error = true
                        this.ready = true
                        this.countCard.show = true
                        this.countCard.error = true
                        return
                    }
                    if (response.data.chronically_absent_percentage === null && response.data.percent_change === null) {
                        this.unavailable = true
                        this.ready = true
                        this.countCard.show = true
                        this.countCard.unavailable = true
                        return
                    }
                    this.error = false,
                    this.unavailable = false
                    this.ready = true
                    this.countCard.change = response.data.percent_change
                    this.countCard.displayChange = response.data.percent_change.toString().includes('-') ? response.data.percent_change.toString().substring(1) : response.data.percent_change
                    this.countCard.displayUpDownArrow = response.data.percent_change < 0 ? 'down' : 'up'
                    this.countCard.show = true
                    this.countCard.error = false
                    this.countCard.unavailable = false
                    this.countCard.count = response.data.chronically_absent_percentage
                })
        },

        caExploreClicked() {
            this.$router.push({path: 'ChronicAbsence'})
        }
    }
}
</script>

<style scoped lang="scss">

    .center-ca-data {
        text-align: center;

        .ca-percentage {
            margin: 5px;
            font-size: 64px;
            font-weight: 700;
        }

        .ca-no-data {
            color: #495b8f;
        }

        .ca-text {
            margin: 5px;
            padding-top: 24px;
            font-size: 16px;
            color: #495b8f;
        }

        .ca-change {
            color: #495b8f !important;
        }

        #ca-change-container {
            margin-left: auto;
            margin-right: auto;
            max-width:fit-content;
            font-size: 16px;
            color: #495b8f;
        }

        #ca-percent-change {
            float: left;
            padding-right: 4px;
        }

        #ca-percent-change-text {
            float: right;
        }
    }

</style>
