<template>
    <div class="student-profile-container flex-fill" style="overflow: scroll;">
        <div class="flex-grow-1">
            <v-slide-y-transition mode="out-in">
                <div v-if="selectedStudent" key="show-data">
                    <div class="d-flex" style="height: 55px;">
                        <div class="student-name mr-auto" style="width: auto;" @click="studentList=[]; selectedStudent = null; selectedSchoolYear = currentYear">
                            <span class="name">
                                <v-icon style="font-size: 24px;" class="mr-3 fas fa-chevron-left"></v-icon>
                                {{ selectedStudent ? selectedStudent.student_full_name : '' }}
                            </span>
                        </div>

                        <!-- student icon pager -->
                        <div v-if="iconPage > 1 || studentIcons.length > 1" class="d-flex flex-grow-1 flex-wrap icon-container align-items-center justify-center">
                            <fe-icon-btn class="mt-2" useIcon="fas fa-chevron-left" @click="iconPage--" :disabled="iconPage === 1"/>
                            <div v-for="(s, idx) in studentIcons" :key="`stdnt-icon-${idx}`">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-avatar size="48px" v-on="on" @click="doStudentIcon(s)" class="mx-1 my-2"
                                            :class="[{'student-icon-selected': s.rec.student_id === selectedStudent.student_id }]"
                                        >
                                            <v-img :src="getStudentImg(s)"/>
                                        </v-avatar>
                                        <!-- <div

                                            class="student-icon"


                                        > -->
                                    </template>
                                    <span>{{ s.rec.student_full_name }}</span>
                                </v-tooltip>
                            </div>
                            <fe-icon-btn class="mt-2" useIcon="fas fa-chevron-right" @click="iconPage++" :disabled="iconPage === Math.ceil(studentList.length / iconCount)"/>
                        </div>

                        <!-- school year picker -->
                        <div>
                            <v-select
                                v-if="selectedStudent"
                                v-model="selectedSchoolYear"
                                :items="schoolYears"
                                :disabled="!selectedStudent || !selectedStudent.student_id"
                                style="width: 130px !important;"
                                data-test="student-profile-SY-dropdown"
                                itemText="name"
                                itemValue="id"
                                class="mt-2"
                                flat solo dense
                                return-object
                            />
                        </div>

                        <div>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <fe-btn usage="primary" v-on="on" data-test="student-profile-create-btn">Create</fe-btn>
                                </template>
                                
                                <v-list>
                                    <v-list-item @click="doAssessmentReport">Assessment Report</v-list-item>
                                </v-list>

                                <v-list>
                                    <v-list-item @click="doMonitoringMeasure">Add Monitoring Measure</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>

                    <div class="d-flex">
                        <student-demographics class="flex-shrink-0 ma-3" :studentRec="studentAndYear" :selectedStudent="selectedStudent" :elpLevel="elpLevel" />

                        <div class="ma-3 flex-grow-1">
                            <div class="align-left">
                                <count-bar ref="counts" :studentRec="localStudent" @elpLevel="elpLevel = $event" />
                            </div>

                            <div class="d-flex">
                                <div style="width: 250px;">
                                    <student-incident-card class="my-2" :rec="localStudent" :schoolYears="schoolYears" />

                                    <student-intervention-card ref="interventionCard" :rec="localStudent" @add-monitor-click="doMonitoringMeasure"/>
                                </div>

                                <div class="flex-grow-1">
                                    <student-score-group :student="localStudent" @score-click="doScoreClick"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="pa-4" key="show-search">
                    <student-search @studentSelect="setStudents" @selectYear="selectedSchoolYear = $event" />
                </div>
            </v-slide-y-transition>
        </div>

        <fe-dialog
            v-if="assessmentTrendline.show"
            @dismiss="assessmentTrendline.show=false"
            :width="600"
        >
            <assessment-trendline :params="assessmentTrendline.params"/>
        </fe-dialog>

        <add-academic-monitor
            v-if="monitorDialog.show"
            :students="monitorDialog.students"
            @dismiss="monitorDialog.show=false"
            @save="monitorDialog.show=false; $refs.interventionCard.loadMonitors()"
        />

        <fe-dialog
            v-if="showPdf"
            width="80%"
            @close="showPdf = false"
            @cancel="showPdf = false"
            title="Assessment Report"
            persistent
        >
            <iframe style="height: 60vh;" border=0 width="100%" :src="pdfUrl" />
        </fe-dialog>

        <fe-dialog
            title="Assessment Report"
            v-if="reportDialog.show"
            @accept="submitReportDialog"
            @close="reportDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Generate"
            :acceptButtonDisabled="!reportDialog.valid"
        >
            <v-form v-model="reportDialog.valid">
                <fe-remote-combo
                    label="Skill Area"
                    placeholder="Blank for all"
                    url="crud.php?action=get&property=Category"
                    multiple
                    rootProperty="category"
                    v-model="reportDialog.params.category_id"
                    byId
                />

                <fe-remote-combo
                    label="School Year"
                    placeholder="Blank for all"
                    multiple
                    :url="$models.getUrl('schoolYear', 'read')"
                    rootProperty="years"
                    v-model="reportDialog.params.school_year_id"
                    byId
                    mulitple
                />

                <fe-remote-combo
                    label="Assessment Group"
                    data-test="assessment-group-dropdown"
                    placeholder="Blank for all"
                    :items="activeAssessmentGroups"
                    itemText="name"
                    itemValue="id"
                    v-model="reportDialog.params.data_point_type_id"
                    byId
                    multiple
                />

                <fe-remote-combo
                    label="Assessment"
                    placeholder="Blank for all"
                    :url="subcatUrl"
                    rootProperty="subcategories"
                    v-model="reportDialog.params.sub_category_id"
                    :disabled="!reportDialog.params.data_point_type_id"
                    byId
                    mulitple
                />

                <fe-switch
                    label="Exclude Sub Scores"
                    v-model="reportDialog.params.exclude_sub_scores"
                />

                <fe-label class="mb-4">Intervention Options</fe-label>
                <fe-switch
                    label="Include Interventions"
                    v-model="reportDialog.params.include_intervention"
                />
                <fe-switch
                    label="Include Comments"
                    v-model="reportDialog.params.include_intervention_comments"
                />

                <fe-label class="mb-4">Incident Options</fe-label>
                <fe-remote-combo
                    label="Incident Type"
                    :url="$models.getUrl('incidentBehaviorType', 'read')"
                    v-model="reportDialog.params.incident_behavior_type_id"
                    rootProperty="incident_behavior_type"
                    multiple
                    byId
                    mulitple
                />

                <fe-remote-combo
                    :items="incidentChartTypes"
                    label="Chart Types"
                    v-model="reportDialog.params.chart_types"
                    byId
                    :disabled="!reportDialog.params.incident_behavior_type_id"
                />
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
import StudentSearch from './Search'
import StudentDemographics from './Demographics'
import StudentIncidentCard from './IncidentCard'
import StudentInterventionCard from './InterventionCard'
import StudentScoreGroup from './ScoreGroup'
import AssessmentTrendline from '@/components/charts/AssessmentTrendline'
import AddAcademicMonitor from '@/components/modules/intervention/monitor/AddAcademicMonitor'
import CountBar from './CountBar'
import {mapState} from 'vuex'

export default {
        name: 'StudentProfile',
        components: {
            StudentIncidentCard,
            StudentDemographics,
            StudentInterventionCard,
            StudentScoreGroup,
            CountBar,
            AssessmentTrendline,
            StudentSearch,
            AddAcademicMonitor
        },
        props: {
            id: {
            },
            year: {
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'uiSavedSearch']),
            localStudent() {
                if (!this.selectedStudent || !this.selectedStudent.student_id) return null

                let s = {}
                s.student_id = this.selectedStudent.student_id
                s.school_year_id = this.selectedSchoolYear ? this.selectedSchoolYear.id : this.selectedStudent.school_year_id
                s.school_id = this.selectedStudent.school_id  // used to get school-specific assessments
                s.student_full_name = this.selectedStudent.student_full_name
                s.student_first_name = this.selectedStudent.fname
                return s
            },
            subcatUrl() {
                if (!this.reportDialog.params.data_point_type_id) return ''

                return 'subcategories.php?action=get&data_point_type_id='+this.reportDialog.params.data_point_type_id.join(',')
            },
            iconCount() {
                return this.$vuetify.breakpoint.mdAndDown ? 5 : 9
            },
            studentIcons() {
                let count = this.iconCount

                let arr = this.paginate(this.studentList, count, this.iconPage)
                let icons = []

                arr.forEach(rec => {
                    let label = ''
                    if (rec.student_full_name) {
                        let a = rec.student_full_name.split(',')
                        label = a[1]+' '+a[0]
                    } else {
                        label = rec.student_fname.substring(0, 1) + rec.student_lname.substring(0, 1)
                    }
                    icons.push({
                        label: label,
                        rec: rec
                    })
                })

                return icons
            },
            studentAndYear() {
                let s =  {
                    student_id: this.studentId,
                    school_year_id: this.selectedSchoolYear ? this.selectedSchoolYear.id : false
                }

                return s
            },
            student(v) {
                if (this.items.length > 0) {
                    return this.items[0]
                } else {
                    return false
                }
            },
            studentId(v) {
                if (this.selectedStudent) {
                    return this.selectedStudent.student_id
                } else {
                    return false
                }
            }
        },
        watch: {
            'selectedStudent.student_id'(v) {
                if (v) {
                    // this.id = v.student_id
                    if (this.uiSavedSearch.RECENT_STUDENTS.length > 0) {
                        let sf = this.uiSavedSearch.RECENT_STUDENTS[0]
                        let arr = []

                        // Get existing arguments, but except we exclude this student if already in
                        // the recent students so that we can bump them to the newest of them all
                        arr = [...sf.arguments].filter(itm => itm.argument_value != v)
                        arr.push({
                            param_code: 'STUDENT_ID',
                            argument_value: v
                        })
                        this.$updateUiSavedSearch('RECENT_STUDENTS', arr.slice(-20))
                    }
                }
            },
            selectedSchoolYear(v) {

            },
            studentIndex(v) {
                this.selectedStudent = this.studentList[v]
            },
            id(v) {
                this.$modelFetch(this.$models.schoolYear)
                    .then(data => {
                        let year = this.$route.params?.year
                        year = parseInt(year)
                        this.schoolYears = data
                        this.selectedSchoolYear = year ? data.find(el => el.id == year) : data[0]
                        if (v) {
                            this.loadStudent()
                        }
                    })
            },
            '$route.params.year'(v) {
                this.$modelFetch(this.$models.schoolYear)
                    .then(data => {
                        let year = this.$route.params?.year
                        year = parseInt(year)
                        this.schoolYears = data
                        this.selectedSchoolYear = year ? data.find(el => el.id == year) : data[0]
                        if (v) {
                            this.loadStudent()
                        }
                    })
            }
        },
        data() {
            return {
                creating: false,
                items: [],
                selectedStudent: null,
                studentIndex: null,
                iconPage: 0,
                selectedSchoolYear: null,
                schoolYears: [],
                studentList: [],
                assessmentTrendline: {
                    params: false,
                    show: false
                },
                reportDialog: {
                    show: false,
                    params: {
                        school_year_id: null,
                        include_intervention: true,
                        include_intervention_comments: false,
                        exclude_sub_scores: true,
                    }
                },
                incidentChartTypes: [
                    { id: 'incident_behavior_type', name: 'By Type'},
                    { id: 'incident_behavior', name: 'By Behavior'},
                    { id: 'incident_month', name: 'By Month'},
                    { id: 'incident_weekday', name: 'By Weekday'},
                    { id: 'incident_location', name: 'By Location'}
                ],
                showPdf: false,
                pdfUrl: false,
                monitorDialog: {
                    show: false,
                    students: []
                },
                elpLevel: null,
                activeAssessmentGroups: [],
            }
        },
        mounted() {
            this.$modelFetch(this.$models.schoolYear)
            .then(data => {
                this.schoolYears = data

                // If route has a year in it, attempt to use that year
                // (might not be possible if it's not an active school year, though)
                let selectedSchoolYear = this.schoolYears.find(sy => sy.id == this.$store.state.global.currentYear)
                let year = this.$route.params?.year
                if (year) {
                    year = parseInt(year)
                    if (!isNaN(year)) {
                        selectedSchoolYear = this.schoolYears.find(sy => sy.id == year) || selectedSchoolYear
                    }
                }

                // If invalid year param was given, or none given, or the given year is not
                // an active school year, default to the most recent (first in array) school year
                this.selectedSchoolYear = selectedSchoolYear || this.schoolYears[0]

                if (this.id) {
                    this.loadStudent()
                }
            })
            .catch(error => {
                console.error(error)
            })

            this.$getUiSavedSearch('RECENT_STUDENTS')
        },
        methods: {
            doMonitoringMeasure() {
                this.monitorDialog.students = [{
                    student_id: this.selectedStudent.student_id,
                    student_full_name: this.selectedStudent.student_full_name,
                    school_id: this.selectedStudent.school_id // used to get school-specific assessments
                }]

                this.monitorDialog.show = true
            },
            doAssessmentReport() {
                this.reportDialog.params = {
                    school_year_id: this.currentYear?.id,
                    include_intervention: true,
                    include_intervention_comments: false,
                    exclude_sub_scores: true,
                }
                this.reportDialog.show=true
                this.getActiveAssessmentGroups()
            },
            submitReportDialog() {
                let host = this.$axios.defaults.baseURL
                let p = this.$_.cloneDeep({...this.reportDialog.params})

                p.exclude_sub_scores = p.exclude_sub_scores ? 1 : 0
                p.include_intervention = p.include_intervention ? 1 : 0
                p.include_intervention_comments = p.include_intervention_comments ? 1 : 0
                this.pdfUrl =`${host}reportCreate.php?type=student_report&student_id=`+this.selectedStudent.student_id+'&'+this.$objectToParams(p)
                // this.reportDialog.show = false
                this.showPdf = true
            },
            updateCounts () {
                if (this.$refs.countbar) this.$refs.countbar.update()
            },
            paginate(arr, pageSize, pageNum) {
                --pageNum

                return arr.slice(pageNum*pageSize, (pageNum+1) * pageSize)
            },
            setStudents(students) {
                this.studentList = students
                this.selectedStudent = students[0]
                this.studentIndex = 0
                this.iconPage = 1

            },
            doStudentIcon(s) {
                this.studentIndex = this.studentList.findIndex(rec => {
                    return rec.student_id === s.rec.student_id
                })
            },
            loadStudent() {
                let me = this
                let id = this.id
                let params = '&id='+id
                this.$axios.get(me.$models.getUrl('studentGradeHistory', 'read')+params)
                    .then(response => {
                        if (response && response.data) {
                            me.selectedStudent = _.cloneDeep(response.data[0])
                        }
                    })
            },
            doScoreClick(rec, title) {
                this.$store.commit('global/addDockableWindow', {
                    name: title,
                    data: rec,
                    component: 'assessment-history',
                    attrs: {
                        studentId: rec.student_id,
                        subCategoryId: rec.sub_category_id,
                        dataPointScoreId: rec.data_point_score_id,
                        dataPointTypeId: rec.data_point_type_id
                    }
                })
            },
            getStudentImg(s) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + s.rec.student_id
            },
            async getActiveAssessmentGroups() {
                try {
                    const response = await this.$axios.get(this.$models.getUrl('assessment', 'read'))
                    this.activeAssessmentGroups = response.data.filter(group => !group.hidden)
                } catch (error) {
                    console.error('Error fetching assessment groups:', error)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.student-profile-container {
    background: #F5F6F8;
}

.icon-container {
    overflow: hidden;
}

.student-name {
    cursor: pointer;
    padding: 16px 20px 5px 20px;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .name {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #152452;

    }
}

.student-icon {
    cursor: pointer;
    border-radius: 50%;
    height: 100%;
    width: 36px;
    height: 36px;
    margin: 16px 8px 2px 2px;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    background-color: lightgray;
    color: white;

    &-selected {
        border: 4px solid var(--v-primary-base);
    }
}
</style>
