import LaunchPad from '@/components/launchpad/LaunchPad'

export default [{
    path: '/',
    component: LaunchPad,
    name: 'Launchpad',
    meta: {
        navigation: 'launchpad',
        routes: []
    }
}, {
    path: '*',
    component: LaunchPad,
    name: 'Launchpad',
    meta: {
        navigation: 'launchpad',
        routes: ['/']
    }
}]
