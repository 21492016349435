<template>
    <div class="flex-column flex-fill pa-4 ec-collections-main">
        <fe-mask v-show="loading" :showLoader="true" />

        <create-smart-form v-if="createDialog.show" @created="loadData(); createDialog.show=false" @dismiss="createDialog.show=false"/>
        <create-folder v-if="createFolderDialog.show" @created="loadData" @dismiss="createFolderDialog.show=false"/>
        <add-template v-if="createTemplateDialog.show" create @close="createTemplateDialog.show=false" />
        <add-status v-if="createStatusDialog.show" @close="reloadStatuses" />

        <fe-dialog
            width="500"
            title="Rename Folder"
            v-if="renameDialog.show"
            dismissButtonText="Cancel"
            @dismiss="renameDialog.show=false"
            @close="renameDialog.show=false"
            @accept="renameFolder"
            :acceptButtonDisabled="!formValid"
            acceptButtonText="Save"
            persistent
        >
            <v-form @submit.prevent v-model="formValid" ref="form">
                <v-text-field
                    v-model="renameDialog.name"
                    flat solo dense
                    :counter="255"
                    placeholder="Enter a name..."
                    :rules="$fieldValidators('text', null, { required: true, limit: 255 })"
                />
            </v-form>
        </fe-dialog>

        <div class="d-inline-flex">
            <div v-if="breadcrumbs.length == 1" style="font-size: 24px;">smartFORMS</div>
            <div v-else class="d-flex">
                <breadcrumb class="my-4" fontSize="18px" v-model="breadcrumbs" @click="setFolder"/>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn class="ml-auto" style="margin-top: 5px" v-on="on" useIcon="fas fa-ellipsis-v"/>
                    </template>
                    <v-list dense>
                        <v-list-item @click="renameDialog.show=true; renameDialog.name=selectedFolder.display_name">
                            <v-list-item-content>
                                Rename Folder
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="deleteFolder">
                            <v-list-item-content>
                                Delete Folder
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-spacer/>

            <div style="width: 300px">
                <v-autocomplete
                    data-test="smartforms-search-dropdown"
                    v-model="searchForm"
                    :search-input.sync="searchText"
                    flat solo dense
                    placeholder="Search for smartFORMS"
                    prepend-inner-icon="fas fa-search"
                    style="margin-top: 7px;"
                    :items="searchResults"
                    itemText="display_name"
                    itemValue="form_instance_id"
                    return-object
                    clearable
                    :no-data-text="noSearchString ? '' : 'No data available'"
                    :hide-no-data="noSearchString"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.display_name}}</v-list-item-title>
                            <v-list-item-subtitle class="pl-4">{{data.item.created_by_full_name}} {{data.item.created}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>

            <v-menu :nudge-bottom="46">
                <template v-slot:activator="{ on }">
                    <fe-btn usage="primary" v-on="on" data-test="smartforms-new-btn">New</fe-btn>
                </template>
                <v-list>
                    <v-list-item @click="createDialog.show = true" v-show="!noTemplatePermission">Form</v-list-item>
                    <v-list-item @click="createFolderDialog.show = true">Folder</v-list-item>
                    <v-list-item @click="createTemplate" v-show="$hasSecurity('CREATE_FORMS')" >Template</v-list-item>
                    <v-list-item @click="createStatusDialog.show = true" v-show="$hasSecurity('CREATE_FORMS')" >Status Code</v-list-item>
                </v-list>
            </v-menu>
        </div>

        <div v-show="breadcrumbs.length > 1">
            <list
                ref='clickedFolderList'
                key="folder-smart-forms"
                :params="folderParams"
                @select="doSelection"
                :showCreateButton="false"
                :smartformFolder="true"
            />
        </div>
        <div v-show="breadcrumbs.length <= 1">
            <div key="non-folder-smartforms" class="d-flex flex-wrap smartform-folders-wrapper">
                <template v-for="(folder, fi) in foldersToShow" v-if="breadcrumbs.length <= 1">
                    <fe-tooltip bottom :key="`folder-`+fi" :tooltip="folder.display_name">
                        <div hover class="ma-0 form-folder form-folder-main"
                            @click="doSelection(folder)"
                        >
                            <div class="form-folder-content">
                                <div class="text-xs-center">
                                    <v-icon color="#80b6c7" class="form-folder-icon">fas fa-folder</v-icon>
                                </div>
                                <div class="form-folder-name text-xs-center">
                                        {{ folder.display_name.substring(0, 25) }}
                                </div>
                            </div>
                        </div>
                    </fe-tooltip>
                </template>

                <div hover width="98" class="ma-0 form-folder"
                    @click="hideFolderOverflow = !hideFolderOverflow"
                    v-if="foldersHidden > 0 || !hideFolderOverflow"
                >
                    <div class="form-folder-content">
                        <div class="text-xs-center mb-3">
                            <v-icon color="#050F2D" class="form-folder-icon" style="font-size: 24px;">
                                {{ hideFolderOverflow ? 'fal fa-folders' : 'fal fa-folder-minus' }}
                            </v-icon>
                        </div>

                        <span style="font-size: 12px;">{{ hideFolderOverflow ? 'Show More Folders' : 'Show Fewer Folders'}}</span>
                    </div>
                </div>

                <v-tabs height="28px" v-model="activeTab" class="ec-collection-tabs">
                    <v-tab class="small-tab small-tab-all" data-test="smartforms-recents-tab">Recents</v-tab>
                    <v-tab class="small-tab small-tab-all" data-test="smartforms-my-forms-tab">My smartFORMS</v-tab>
                    <v-tab class="small-tab small-tab-all" data-test="smartforms-shared-forms-tab">Shared with Me</v-tab>
                    <v-tab class="small-tab small-tab-all" data-test="student-forms-tab">Student Forms</v-tab>
                    <v-tab class="small-tab small-tab-all" v-show="$hasSecurity('CREATE_FORMS') || $hasSecurity('MANAGE_ALL_FORM_TEMPLATES')" data-test="smartforms-templates-tab">Form Templates</v-tab>
                    <v-tab v-if="guardianEmailDisplay" class="small-tab small-tab-all" data-test="smartforms-guardian-emails-tab">Emails to Guardians</v-tab>
                </v-tabs>

                <v-carousel
                    class="mt-2"
                    v-model="activeTab"
                    height="100%"
                    hide-delimiters light
                    :show-arrows="false"
                >
                    <v-carousel-item key="0" data-test="recents-tab">
                        <list
                            ref='folderFormList'
                            key="recent-smart-forms"
                            :showToolbar="false"
                            :params="recentFormParams"
                            :orderedIds="recentSmartForms"
                            @select="doSelection"
                            @loading="loading = $event"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="1" data-test="my-smartforms-tab">
                        <list
                            ref='formList'
                            @select="doSelection"
                            :params="listParams"
                            :showCreateButton="false"
                            key="my-smartforms"
                            @loading="loading = $event"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="2" data-test="shared-with-me-tab">
                        <list
                            ref='shared'
                            :showToolbar="false"
                            @select="doSelection"
                            :params="sharedParams"
                            key="shared-smartforms"
                            @loading="loading = $event"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="3" data-test="student-forms-tab">
                        <student-forms-list
                            ref='StudentForms'
                            @select="doSelection"
                            :params="{property:'student_instance'}"
                            key="student-forms-list"
                            @loading="loading = $event"
                        />
                    </v-carousel-item>

                    <v-carousel-item key="4" data-test="form-templates-tab">
                        <fe-left-menu-layout
                            :menuItems="templateTabItems"
                            v-model="templateTabActiveItem"
                            class="template-left-nav"
                            menuWidth="200px"
                        >
                            <keep-alive>
                                <component ref="templateComponent" :is="templateTabActiveItem
                                    ? templateTabActiveItem.component
                                    : (templateTabActiveItem=templateTabItems[0]) && templateTabActiveItem.component
                                " style="height: calc(100vh - 300px)"/>
                            </keep-alive>
                        </fe-left-menu-layout>
                    </v-carousel-item>

                    <v-carousel-item key="5">
                        <guardian-emails data-test="guardian-emails-tab"/>
                    </v-carousel-item>

                </v-carousel>
            </div>
        </div>

        <fe-crud
            ref="permissionsCrud"
            :config="permissionConfig"
            autoload
            @read="v => userPermissions = v"
        />
        <fe-crud
            ref="crud"
            :config="formsConfig"
            autoload
            @read="v => itemsLoaded(v)"
        />
    </div>
</template>

<script>
    import lodash from 'lodash'
    import { mapState } from 'vuex'
    import List from './List'
    import StudentFormsList from './StudentFormsList'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import CreateSmartForm from './components/CreateForm'
    import CreateFolder from './components/CreateFolder'
    import TemplateList from './template'
    import ClimberCloud from './components/ClimberCloud'
    import FormInstanceStatus from './template/FormInstanceStatus'
    import AddStatus from './template/AddStatus'
    import AddTemplate from './template/AddTemplate'
    import Reporting from './reporting'
    import GuardianEmails from './GuardianEmails.vue'

    export default {
        name: 'SmartForms',
        components: {
            List,
            StudentFormsList,
            Breadcrumb,
            CreateSmartForm,
            CreateFolder,
            TemplateList,
            ClimberCloud,
            AddTemplate,
            AddStatus,
            Reporting,
            FormInstanceStatus,
            GuardianEmails,
        },
        data() {
            return {
                createTemplateDialog: { show:false },
                createStatusDialog: { show:false },
                createFolderDialog: {
                    show: false
                },
                createDialog: {
                    show: false
                },
                renameDialog: {
                    show: false,
                    name: ''
                },
                formValid: false,
                activeTab: 3,
                selectedFolder: null,
                searchForm: '',
                searchText: '',
                searchResults: [],
                breadcrumbs: [{
                    name: 'smartFORMS',
                    data: []
                }],
                templateTabItems: [
                    {name: 'District Templates', component: TemplateList},
                    {name: 'climberCLOUD', component: ClimberCloud},
                    {name: 'Reporting', component: Reporting},
                    {name: 'Status Codes', component: FormInstanceStatus},
                ],
                templateTabActiveItem: null,
                noSearchString: true,
                noTemplatePermission: false,
                userPermissions: [],
                hideFolderOverflow: true,
                items: [],
                editing: false,
                foldersHidden: 0,
                loading: false,
            }
        },
        watch: {
            searchForm(v) {
                let me = this
                if (v) {

                    this.$store.commit('global/addDockableWindow', {
                        name: v.display_name,
                        data: v,
                        component: 'smart-form',
                        attrs: {
                            id: v.form_instance_id,
                            template_id: v.form_id
                        }
                    })
                }
            },
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }
            }, 500),
            userPermissions(v) {
                let create = []
                Object.keys(this.userPermissions[this.sessionUser.user.id]).forEach(key => {
                    if(this.userPermissions[this.sessionUser.user.id][key].can_create) create.push(parseInt(key))
                })
                this.noTemplatePermission = !create.length
            },
            hideFolderOverflow: {
                deep: true,
                handler(newVal, oldVal) {
                    if(!newVal && newVal !== oldVal) {
                        document.querySelectorAll('.form-folder-main').forEach(folder => {
                            folder.style.display = 'block'
                        })
                        this.foldersHidden = 0
                    } else if (newVal && newVal !== oldVal) {
                        this.itemsLoaded(this.items)
                    }
                }
            },
            breadcrumbs(v) {
                if(v.name == 'smartFORMS') {
                    this.breadcrumbs = [{
                        name: 'smartFORMS',
                        data: []
                    }]
                    this.activeTab = 3
                }
                this.hideFolderOverflow = true
                this.itemsLoaded(this.items)
            },
            '$route.hash': {
                handler(newHash) {
                    if(newHash === '#emails-to-guardians'){
                        this.activeTab = 5
                    }
                }
            },
            activeTab(newValue, oldValue) {
                if (oldValue === 5 && newValue !== 5) {
                    this.clearUrlHash()
                }
            }
        },
        mounted() {
            this.$getUiSavedSearch('RECENT_SMART_FORMS')
            if (this.$route.hash === '#emails-to-guardians') {
                this.activeTab = 5
            }
        },
        methods: {
            reloadStatuses() {
                this.createStatusDialog.show = false
                if(this.$refs.templateComponent?.$options.name == 'FormInstanceStatus') {
                    this.$refs.templateComponent.reload()
                }
            },
            createTemplate() {
                if(this.$refs.templateComponent?.$options.name == 'TemplateList') {
                    this.$refs.templateComponent.editRec()
                } else {
                    this.createTemplateDialog.show = true
                }
            },
            renameFolder() {
                let me = this
                me.$axios.post('formResponse.php?action=update&property=form_instance', {
                    form_instances: [{
                        form_name: '',
                        form_id: '',
                        folder_id: me.selectedFolder.folder_id,
                        folder_name: me.renameDialog.name,
                        display_name: me.renameDialog.name,
                        created_by: me.selectedFolder.created_by
                    }]
                }).then(resp => {
                    me.$ecResponse(resp)
                    me.breadcrumbs[me.breadcrumbs.length-1].name = me.renameDialog.name
                    me.renameDialog.show = false
                    this.$refs.crud.read()
                })
            },
            deleteFolder() {
                let me = this
                let f = this.breadcrumbs[this.breadcrumbs.length-1]

                if (!f) return
                this.$confirmDelete([f], () => {
                    me.$axios.post('form.php?action=destroy&property=folder', {
                        folders: [{
                            id: f.data.folder_id,
                            name: f.name,
                            created_by: f.data.created_by
                        }]
                    }).then(resp => {
                        me.$ecResponse(resp)
                        me.selectedFolder = null
                        me.breadcrumbs = [{
                            name: 'smartFORMS',
                            data: null
                        }]
                        this.$refs.crud.read()
                    })
                })
            },
            doSearch() {
                this.$data.searchResults = []
                this.$modelGet('formInstanceList', { search: this.searchText, flat: 1, user_id: this.sessionUser.user.id })
                    .then(response => {
                        this.$data.searchResults = response

                    })
                    .catch(err => {
                        console.warn('Unable to load forms', err)
                    })
            },
            loadData() {
                this.createFolderDialog.show=false
                this.createDialog.show = false
                // only called after we've created a new form or folder
                if (this.activeTab === 1) {
                    this.$refs.formList.loadData()
                } else if (this.activeTab === 0) {
                    this.$refs.folderFormList.loadData()
                } else if(this.activeTab === 3) {
                    this.$refs.StudentForms.loadData()
                }
                this.$refs.crud.read()

                this.createFolderDialog.show = false
                this.createDialog.show = false
            },
            setFolder(v) {
                this.selectedFolder = v.data
            },
            doSelection(item) {
                if (item.is_folder) {
                    this.selectedFolder = item
                    this.selectedForm   = null
                    this.breadcrumbs.push({
                        name: item.display_name,
                        data: item
                    })
                } else {
                    // doesn't look like this anything every gets here anymore
                    // batch printing probably in the future

                    // this.selectedForm = item
                    if (item.form_instance_locked_flag || item.form_instance_status_lock_flag) {
                        window.open(process.env.VUE_APP_BASE_URI+'/formPrint.php?printformid=' + item.form_instance_id)
                        return
                    }
                }
            },
            itemsLoaded(v) {
                this.items = v
                setTimeout(function() {
                    let cardWidth    = document.querySelector('.smartform-folders-wrapper')?.offsetWidth
                    let folderWidth  = 0
                    let hiddenItems  = 0
                    let stoppedIndex = 0
                    document.querySelectorAll('.form-folder-main').forEach((folder, idx) => {
                        folderWidth += folder.offsetWidth
                        if(folderWidth + 60 > cardWidth) {
                            hiddenItems++
                            folder.style.display = 'none'
                        } else {
                            stoppedIndex = idx
                        }
                    })
                    if(hiddenItems > 0) {
                        // hide one more to make room for "show more folders" on same line
                        document.querySelectorAll('.form-folder-main')[stoppedIndex].style.display = 'none'
                        // this.hideFolderOverflow = true
                        this.foldersHidden = hiddenItems
                    }
                }.bind(this), 100)
                // v-show for studentformslist.vue beforeunload to not run inside folders
                // v-if on folders div to redraw (otherwise stuck with display: none on every folder)
            },
            clearUrlHash() {
                this.$router.replace({ hash: '' })
            },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'dockableWindows', 'uiSavedSearch']),
            listParams() {
                let p = {
                    property: 'list',
                    user_id: this.sessionUser.user.id
                }

                if (this.selectedFolder) {
                    p.folder_id = this.selectedFolder.folder_id
                }
                return p
            },
            sharedParams() {
                let p = {
                    property: 'list',
                    shared: 1,
                    user_id: this.sessionUser.user.id
                }
                return p
            },
            recentFormParams() {
                return this.recentSmartForms.length
                    ? { property: 'list', flat: 1, form_instance_id: this.recentSmartForms.join(','), user_id: this.sessionUser.user.id }
                    : undefined
            },
            recentSmartForms() {
                let recent = this.uiSavedSearch?.RECENT_SMART_FORMS
                if (recent) {
                    if (recent.length === 1) {
                        let ids = recent[0].arguments.map(rec => rec.argument_value).sort((a, b) => a.argument_id - b.argument_id)
                        return ids
                    } else {
                        return []
                    }
                }
                return []
            },
            permissionConfig() {
                let cfg = this.$_.cloneDeep(this.$models.formPermissions)
                cfg.read.params.user_id = this.sessionUser.user.id
                return cfg
            },
            formsConfig() {
                let cfg = this.$_.cloneDeep(this.$models.formInstance)
                cfg.read.params.property = 'list'
                cfg.read.params.get_all = '1'
                cfg.read.params.user_id = this.sessionUser.user.id
                return cfg
            },
            foldersToShow() {
                return this.items.filter(rec => rec.is_folder)
            },
            totalFolders() {
                return this.items.filter(r => r.is_folder).length
            },
            folders() {
                if (this.editing) return []

                return this.items.filter((r) => {
                    return r.is_folder
                })
            },
            folderParams() {
                let p = {
                    property: 'list',
                    get_all: '1',
                    user_id: this.sessionUser.user.id
                }
                if (this.selectedFolder) {
                    p.folder_id = this.selectedFolder.folder_id
                }
                return p
            },
            guardianEmailDisplay() {
                return this.$store.getters['flags/flags']['ec-guardian-emails'] && this.$hasSecurity('SEND_GUARDIAN_FORM_EMAIL')
            },
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (to.query.activeTab) {
                    // set active tab from query param
                    vm.activeTab = Number(to.query.activeTab)
                } else {
                    // set default active tab to student forms
                    vm.activeTab = 3
                }
            })
        },
    }
</script>

<style lang="scss" scoped>
.template-left-nav ::v-deep {
    .menu-title-toolbar {
        display: none !important;
    }
    .nav-container {
        border: none;
        padding-top: 16px;
    }
    .nav-container-items {
        padding-left: 0;
    }
}
.ec-collections-main {
    background: white;
}
.small-tab {
    text-transform: none;
    letter-spacing: normal;
}
.small-tab::before {
    border-radius: 4px;
}
.ec-collection {
    &-tabs {
        margin-bottom: 8px;

        ::v-deep .v-item-group {
            height: 40px !important;
        }

        ::v-deep .v-tabs-slider {
            border-radius: 1.5px;
            &-wrapper {
                height: 3px !important;
            }
        }
    }
}

.form-folder {
    position: relative;
    height: 90px;
    width: 110px;
    margin: 4px;
    cursor: pointer;
    .form-folder-content {
        height: 100%;
        padding-top: 10px;
        text-align: center;
        .form-folder-icon {
            font-size: 48px;
            color: #80b6c7;
        }
        .form-folder-name {
            font-size: 14px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 4px;
        }
    }
}
</style>
