let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

let formCrud = {
    ...standardCrud,
    destroy: { params: { action: 'destroy' } }
}

export default {
    form: {
        defaults: {
            endpoint: 'form.php?property=form',
            rootProperty: 'forms'
        },
        ...formCrud,
    },
    formDataId: {
        rootProperty: 'data_id',
        read: 'form.php?action=get&property=data_id'
    },
    formField: {
        defaults: {
            endpoint: 'form.php?property=field',
            rootProperty: 'form_fields',
        },
        ...formCrud,
    },
    formFieldOption: {
        defaults: {
            endpoint: 'form.php?property=form_field_option',
            rootProperty: 'form_field_options',
        },
        ...formCrud,
    },
    formFieldSavedSearch: {
        defaults: {
            endpoint: 'form.php?property=saved_searches',
            rootProperty: 'saved_searches',
        },
        ...formCrud,
    },
    formFolder: {
        defaults: {
            endpoint: 'form.php?property=folder',
            rootProperty: 'folders'
        },
        ...formCrud,
    },
    interventionFormInstance: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'interventionView.php?action=get&property=forms',
            rootProperty: 'form_instances'
        },
        ...standardCrud
    },
    formInstance: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'formResponse.php?property=form_instance',
            rootProperty: 'form_instances'
        },
        ...standardCrud
    },
    formInstanceUser: {
        defaults: {
            endpoint: 'formResponse.php?property=user',
            rootProperty: 'form_instance_users'
        },
        ...standardCrud
    },
    formInstanceList: {
        defaults: {
            endpoint: 'formResponse.php?property=list',
            rootProperty: 'form_instances'
        },
        ...standardCrud
    },
    formInstanceResponse: {
        rootProperty: 'form_responses',
        read: 'formResponse.php?action=get&property=form_response',
        update: 'formResponse.php?action=update&property=form_response',
        create: 'formResponse.php?action=create&property=form_response',
        destroy: 'formResponse.php?action=delete&property=form_response'
    },
    formInstanceStatus: {
        defaults: {
            endpoint: 'crud.php?property=FormInstanceStatus',
            rootProperty: 'form_instance_status'
        },
        ...standardCrud

    },
    formItemDependency: {
        defaults: {
            endpoint: 'crud.php?property=FormItemDependency',
            rootProperty: 'form_item_dependency',
        },
        ...standardCrud,
        read: {
            endpoint: 'form.php?property=FormItemDependency',
            params: { action: 'get' },
        }

    },
    formLogic: {
        defaults: {
            endpoint: 'crud.php?property=FormLogic',
            rootProperty: 'form_logic'
        },
        ...standardCrud
    },
    formSection: {
        defaults: {
            endpoint: 'form.php?property=section',
            rootProperty: 'sections',
        },
        ...formCrud,
    },
    formSubscriber: {
        defaults: {
            endpoint: 'form.php?property=form_subscribers',
            rootProperty: 'form_subscribers'
        },
        ...formCrud,
    },
    formTask: {
        defaults: {
            endpoint: 'crud.php?property=FormTask',
            rootProperty: 'form_task'
        },
        ...standardCrud
    },
    formUserSecurity: {
        defaults: {
            endpoint: 'crud.php?property=FormUserSecurity',
            rootProperty: 'form_user_security'
        },
        ...standardCrud
    },
    formPermissions: {
        defaults: {
            endpoint: 'form.php?property=permissions',
            rootProperty: 'permissions'
        },
        ...formCrud,
    },
    formExportSFTPConfig: {
        defaults: {
            endpoint: 'crud.php?property=FormExportSftpConfig',
            rootProperty: 'form_export_sftp_config'
        },
        ...standardCrud
    },
    formLanguage: {
        defaults: {
            endpoint: 'crud.php?property=LanguageCode',
            rootProperty: 'language_code'
        },
        ...standardCrud
    },
    homeLanguageAlias: {
        defaults: {
            endpoint: 'crud.php?property=HomeLanguageAlias',
            rootProperty: 'home_language_alias'
        },
        ...standardCrud
    },
    formNameConfig: {
        defaults: {
            endpoint: 'crud.php?property=FormNameConfig',
            rootProperty: 'form_name_config'
        },
        ...standardCrud
    },
    guardianFormEmailSetup: {
        defaults: {
            endpoint: '/api/core/guardianformemail/setup',
            rootProperty: 'guardian_setup_email'
        },
        read: {
        }
    },
    guardianFormEmailSend: {
        defaults: {
            endpoint: '/api/core/guardianformemail/send'
        },
        read: { },
    },
    guardianFormEmails: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list'
        },
        read: { },
    },
    guardianFormEmailsV2: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/v2'
        },
        read: { },
    },
    guardianFormReporting: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/guardians'
        },
        read: { },
    },
    guardianFormReportingUndeliverable: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/undeliverable'
        },
        read: { },
    },
    guardianFormReportingResponded: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/responded'
        },
        read: { },
    },
    guardianFormReportingNoResponse: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/noresponse'
        },
        read: { },
    },
    guardianFormReportingCounts: {
        defaults: {
            endpoint: '/api/core/guardianformemail/sent/list/totals'
        },
        read: { },
    },
}

