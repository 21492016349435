<template>
    <div v-if="filterChips.length">
        <v-flex grow style="padding-left: 8px !important">
            <template v-for="chip in filterChips">
                <fe-chip ref="chips" close 
                    @click:close="$emit('removeChipFilter',chip, chip.i)" 
                    :data-test="dataTestLocation + '-chip-' + chip.name" 
                    class="filter-chips-chip"
                    close-icon="fal fa-times-circle"
                    color="#050F2D"
                >
                    {{ chip.displayName }}
                    <template #tooltip>
                        {{ chip.tooltip }}
                    </template>
                </fe-chip>
            </template>
            <v-btn 
                class="label ml-2" 
                text 
                @click="$emit('clearAllFilters')" 
                :data-test="dataTestLocation + '-chips-clear-filters-btn'" 
                style="color: #006C90"
            >
                Clear Filters
            </v-btn>
        </v-flex>
    </div>
</template>

<script>
    import * as DemographicUtil from '@/components/common/util/DemographicUtil'

    export default {
        name: 'FilterChips',
        props: {
            dataTestLocation: { 
                type: String, 
                default: '' 
            },
            quickFilters: { 
                type: Object, 
                default: () => {} 
            },
            moreFilters: { 
                type: [Object, Array], 
                default: () => [] 
            },
            updateChips: { 
                type: Boolean, 
                default: false 
            },
        },
        data() {
            return {
                filterChips: []
            }
        },
        computed: {
            quickFiltersEmpty() {
                return Object.keys(this.quickFilters).length && Object.values(this.quickFilters).every(x => x === null || x === '' || x === undefined || x.included?.length === 0)
            },

            moreEmpty() {
                return this.$_.isArray(this.moreFilters) ? this.moreFilters.length === 0 : Object.keys(this.moreFilters).length === 0
            },
            demographics() {
                return this.$store.getters['global/demographicsSearchable']
            }
        },
        watch: {
            updateChips: {
                immediate: true,
                handler(v) {
                    if(v) this.loadData()
                }
            }
        },
        methods: {
            loadData() {
                let filters = []
                if (!this.quickFiltersEmpty) {
                    let quickFilters = Object.keys(this.quickFilters).filter(key => this.quickFilters[key] !== null && this.quickFilters[key] !== '' && this.quickFilters[key] !== undefined)
                    quickFilters.forEach(filterName => {
                        let search = this.quickFilters[filterName]
                        let searchChip = {name: filterName, filters: [], displayName: '', key: filterName}
                        search.included 
                            ? search.included.forEach((activeFilter, i) => {
                                let keyValue = Object.entries(activeFilter).find(key => key.includes("name") ? key.includes("name") : key.includes("display_name"))
                                searchChip.filters.push({i: i, name: keyValue[1], id: activeFilter.id ? activeFilter.id : null})
                            }) 
                            : search.forEach((activeFilter, i) => {
                                searchChip.filters.push({i: i, name: activeFilter.name, id: activeFilter.id ? activeFilter.id : null})
                            })
                        searchChip.displayName = this.concatChipDisplayNames(searchChip)
                        searchChip.tooltip = this.concatChipDisplayNames(searchChip, true)
                        if (searchChip.displayName !== "") filters.push(searchChip)
                    })
                } 

                if(!this.moreEmpty) {
                    let moreFilters = this.$_.cloneDeep(this.moreFilters)
                    if (this.$_.isArray(moreFilters) && moreFilters.length > 0) {
                        moreFilters.forEach((filter, i) => {
                            let currentFilter = this.moreFilters[filter] ? this.moreFilters[filter] : filter
                            filters.push(
                                DemographicUtil.getDemoName(this.demographics, currentFilter)
                                ? this.setAdvancedFilterChip(currentFilter, i) 
                                : {
                                    i: i, 
                                    filter: currentFilter, 
                                    name: currentFilter.name, 
                                    displayName: this.concatChipDisplayNames(currentFilter), 
                                    tooltip: this.concatChipDisplayNames(currentFilter, true),
                                    key: currentFilter.name
                                }
                            )
                        })
                    } else {
                        let i = 0
                        for (let [key,value] of Object.entries(moreFilters)) {
                            if(DemographicUtil.getDemoName(this.demographics, [key, value])) {
                                filters.push(this.setAdvancedFilterChip([key, value], i))
                            } else {
                                let chip = {name: key, filters: [], displayName: '', key: key}
                                if(moreFilters[key] !== null) {
                                    if(this.$_.isArray(moreFilters[key])) {
                                        moreFilters[key].forEach((f, idx) => {
                                            chip.filters.push({i: idx, name: f.name, id: f.id ? f.id : null })
                                        })
                                        chip.label = moreFilters[key][0].label ? moreFilters[key][0].label : ''
                                        chip.displayName = this.concatChipDisplayNames(chip)
                                        chip.tooltip = this.concatChipDisplayNames(chip, true)
                                        filters.push(chip)
                                    } else {
                                        let name = moreFilters[key].name ? moreFilters[key].name : moreFilters[key]
                                        if(key === 'student_active_flag') {
                                            switch(parseInt(name)) {
                                                case 0:
                                                    name = 'Inactive'
                                                    break;
                                                case 1:
                                                    name = 'Active'
                                                    break;
                                                case 2:
                                                    name = 'Both'
                                                    break;
                                            }
                                        }
                                        chip.filters.push({i: i, filter: moreFilters[key], name: name })
                                        chip.label = moreFilters[key].label ? moreFilters[key].label : ''
                                        chip.displayName = this.concatChipDisplayNames(chip)
                                        chip.tooltip = this.concatChipDisplayNames(chip, true)
                                        filters.push(chip)
                                    }
                                }
                            }
                            i++
                        }
                    }
                }

                this.filterChips = filters
                this.$emit('filterChips', this.filterChips)
                if(this.updateChips) this.$emit('reloadupdateChips')
            },
            concatChipDisplayNames(chips, tooltip = false) {
                let displayFilters
                if (chips.filters.length > 3 && !tooltip) {
                    displayFilters = `${chips.filters.filter(scf => scf.i < 3).map(df => df.name).join(', ')} + ${chips.filters.length - 3} more`
                } else if (tooltip) {
                    let label = chips.label ? chips.label : this.$_.capitalize(chips.name.replace('_id','').replaceAll('_',' ')) 
                    displayFilters = label + ': ' + chips.filters.map(scf => scf.name).join(', ')
                } else {
                    displayFilters = chips.filters.map(scf => scf.name).join(', ')
                }

                return displayFilters
            },
            setAdvancedFilterChip(filter, i) {
                let demos = this.demographics
                let keyValue = this.$_.isArray(filter) ? filter : Object.entries(filter)

                return {
                    i: i,
                    filter: filter,
                    name: keyValue[0],
                    displayName: DemographicUtil.getDemoName(demos, filter),
                    tooltip: DemographicUtil.getDemoName(demos, filter),
                    key: keyValue[0]
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .filter-chips-chip {
        ::v-deep .v-chip__content {
            background-color: #E0E1E6 !important;
            color: #050F2D !important;
            max-width: none !important;
        }
    }
</style>