<template>
    <div class="center-no-data-text">
        <v-icon class="mr-2" style="color: #47578a; font-size: 32px;">far fa-exclamation-circle</v-icon>
        <div :style="{'font-weight': 'bold', 'padding': '8px'}">
            Data temporarily unavailable
        </div>
        <div>
            The {{dataType}} data is not available right now.
        </div>
        <div>
            Refresh the page or try again later.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DataUnavailable',
        props: {
            dataType: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
.center-no-data-text {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center !important;
}
</style>
