export const useCalendarDateRange = (selectedDateRange) => {
    return {
        get() {
        if (selectedDateRange.dateRange.start && selectedDateRange.dateRange.end) {
            return {
                multipleDateRange: [],
                selectedDate: false,
                selectedDateTime: false,
                selectedDates: [],
                selectedDatesItem: "",
                selectedHour: "00",
                selectedMinute: "00",
                dateRange: {
                    start: selectedDateRange.dateRange.start,
                    end: selectedDateRange.dateRange.end
                }
            }
        } else {
            return undefined
        }
        },
        // Not setting any value so it only sets when the user clicks 'Apply'
        set() {}
    }
}

export const updateSelectedDateRange = (selectedDateRange, event) => {
    if (event?.dateRange) {
        selectedDateRange.dateRange.start = event.dateRange.start
        selectedDateRange.dateRange.end = event.dateRange.end
    }
}

export const closeCalendar = (component) => {
    component.dateMenuOpen = false
    component.showCustomDatePicker = false
}