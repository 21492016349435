<template>
    <div :class="dataType === 'enrollment' ? border : null">
        <div class="center-no-results-text">
            <v-icon class="far fa-analytics fe-icon" style="color: #687baa; font-size: 32px; padding: 18px;"></v-icon>

            <div style="padding: 8px;">
                <div :style="{'font-weight': 'bold', 'padding': '8px'}">
                    No results
                </div>
                <div>
                    We can't find {{ dataType }} data for your selections.
                </div>
                <div>
                    Adjust or clear your filters and try again.
                </div>
            </div>

            <div style="padding: 14px;">
                <fe-btn
                    @click="$emit('clearFilters')"
                    style="padding: 8px;"
                    data-test="enr-overlay-reset-filters-btn"
                >
                    Reset Filters
                </fe-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoFilterResults',
        props: {
            dataType: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
.border {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #dddddd;
    margin-top: 12px;
    border-radius: 4px;
}

.center-no-results-text {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center !important;
}
</style>
