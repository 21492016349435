<template>
    <div v-if="ready">
        <div v-if="useNewLaunchPad" class="new-landing-main-container" >
            <v-row class="d-flex">
                <v-col cols=4>
                    <today-card/>
                </v-col>

                <v-col cols=4>
                    <intervention-participation-card :launchpadStyle=true />
                </v-col>

                <v-col cols=4>
                    <intervention-tools-card/>
                </v-col>
            </v-row>

            <v-row class="d-flex">
                <v-col cols=4>
                    <students-card/>
                </v-col>

                <v-col cols=4>
                    <incidents-card/>
                </v-col>

                <v-col cols=4>
                    <attendance-card/>
                </v-col>
            </v-row>

            <v-row class="d-flex">
                <v-col cols=4 v-if="showChrnAbsenceCard && prefs.sisReportedConfigured">
                    <chronic-absence-card/>
                </v-col>
                <v-col cols="4" v-else>
                    <enrollment-card/>
                </v-col>

                <v-col cols=4 v-if="showChrnAbsenceCard && prefs.sisReportedConfigured">
                    <enrollment-card/>
                </v-col>
                <v-col cols=4 v-else>
                    <quick-links-card/>
                </v-col>

                <v-col cols=4>
                    <quick-links-card v-if="showChrnAbsenceCard && prefs.sisReportedConfigured"/>
                </v-col>
            </v-row>

        </div>
        <div v-else class="d-flex flex-fill flex-wrap">
            <v-row class="d-flex" style="min-height: 300px;">
                <v-col cols=4>
                    <today-card/>
                </v-col>

                <v-col cols=4>
                    <intervention-participation-card :launcpadStyle=false />
                </v-col>

                <v-col cols=4>
                    <intervention-tools-card/>
                </v-col>
            </v-row>

            <v-row class="d-flex flex-fill" style="min-height: 300px;">
                <v-col cols=4>
                    <visualizations-card/>
                </v-col>

                <v-col cols=4>
                    <students-card/>
                </v-col>

                <v-col cols=4>
                    <quick-links-card/>
                </v-col>
            </v-row>

        </div>
    </div>
</template>

<script>
    import LayoutTemplate from '@/components/modules/aatemplate/LayoutTemplate'
    import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
    import Student3d from '@/components/modules/students3d/View'
    import CollectionButton from './CollectionButton'
    import TodayCard from './TodayCard'
    import StudentsCard from './StudentsCard'
    import InterventionParticipationCard from './InterventionParticipationCard'
    import VisualizationsCard from './VisualizationsCard.vue'
    import AttendanceCard from './AttendanceCard.vue'
    import IncidentsCard from './incidents/IncidentsCard.vue'
    import ChronicAbsenceCard from './ChronicAbsenceCard.vue'
    import EnrollmentCard from './EnrollmentCard.vue'
    import QuickLinksCard from './QuickLinksCard'
    import InterventionToolsCard from './InterventionToolsCard'
    import { mapState } from 'vuex'

    export default {
        name: 'LaunchPad',
        components: {
            LayoutTemplate,
            ProgramEvaluation,
            CollectionButton,
            Student3d,
            TodayCard,
            StudentsCard,
            InterventionParticipationCard,
            VisualizationsCard,
            AttendanceCard,
            IncidentsCard,
            ChronicAbsenceCard,
            EnrollmentCard,
            QuickLinksCard,
            InterventionToolsCard
        },
        computed: {
            ...mapState('global', ['sessionUser', 'lastLaunchpadVisit', 'dockableWindows', 'districtPreferences']),
            dashboardOnly() {
                return this.sessionUser.district.dashboard_only ? true : false
            },
            useNewLaunchPad() {
                return this.$store.getters['flags/flags']['e-c-split-viz']
            },
            showChrnAbsenceCard() {
                return this.$store.getters['flags/flags']['ec-attendance-mlp-1']
            }
        },
        mounted() {
            this.fetchPrefs()
            this.setupPanels()
        },
        data() {
            return {
                showDialog: true,
                fieldConfig: [{
                    field: 'name',
                    label: 'Incident Type',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 50 },
                    width: '100%',
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        style: {
                            width: '100%'
                        }
                    }
                }, {
                    field: 'created',
                    label: 'Created',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 20 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        readonly: true,
                        style: {
                            width: '100%'
                        }
                    }
                }],
                formRecords: [],
                tools: [],
                incidents: [],
                deeperOptions: [],
                collections: [],
                cards: [{
                    id: 1,
                    title: 'Action Items'
                }, {
                    id: 2,
                    title: 'My Students'
                }, {
                    id: 3,
                    title: 'Timeline'
                }, {
                    id: 4,
                    title: 'My Collections'
                }],
                prefs: {},
                ready: false,
            }
        },
        watch: {
            dockableWindows(v) {
                // If all dockable windows have closed, anticipate that
                // user has just created a new incident (for example) and
                // trigger a refresh of launchpad data
                if (v.length === 0) {
                    this.$store.commit('global/reloadLaunchpad', { })
                }
            },
        },
        methods: {
            go() {
                this.programs.push('program-evaluation')
            },
            setupPanels() {
                let me = this

                this.deeperOptions = [{
                    id: 'layout-templates',
                    title: 'Layout Templates',
                    handler() {
                        me.$router.replace('/LayoutTemplate')
                    }
                }, {
                    id: 'program-evaluation',
                    title: 'Program Evaluation',
                    handler() {
                        me.$router.replace('/ProgramEvaluation')
                    }
                }, {
                    id: 'students-3d',
                    title: 'Students 3D',
                    handler() {
                        me.$router.replace('/Students3D')
                    }
                }, {
                    id: 'analytics',
                    title: 'Analytics',
                    handler() {
                    }
                }]

                this.loadUserData()
            },
            loadUserData() {
                this.$store.dispatch('doQuery', {
                    endpoint: 'dashboard.php?action=get&property=home&_dc=1568385261019&schedule_date_occurrence=2019-09-13',
                    rootProperty: 'dashboard'
                })
                .then(response => {
                    this.collections = response.user_dashboards
                })
            },
            fetchPrefs() {
                this.$axios.get(this.$models.getUrl('preferences', 'read')+'&property=district').then(response => {
                    response.data.preferences.forEach(pref => {
                        this.prefs[pref.code] = pref.user_value !== "0"
                        this.prefs.sisReportedConfigured = this.prefs['ATTENDANCE_EQUIV_RATE']
                        this.ready = true
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

.new-landing-main-container {
    display: grid;
    grid-template-rows: 0.8fr 1fr 1fr;
    background-color: #F3F5F7;
    padding: 0px;
}

.collection-button {
    height: 72px;
    width: 72px;
    background-color: blue;
}
</style>
