<template>
    <standard-widget
        widgetTitle="Recent Students"
        :cardHeight="launchpadWidgetHeights.secondRow"
        :thumbnail="useNewLaunchPad ? null : thumbnail"
        cardStyle="margin-top: 0 !important; overflow: hidden;"
    >
        <template v-slot:widget-body>
            <div :class="{'student-image-container': useNewLaunchPad, 'd-flex': !useNewLaunchPad, 'flex-wrap': !useNewLaunchPad}" v-if="photoURL">
                <fe-tooltip
                    v-for="item in studentImages"
                    :key="item.id"
                    :tooltip="item.student_full_name"
                >
                    <div v-if="useNewLaunchPad">
                        <div v-if="item.isImage" class="ma-2 outer-student-circle"
                            @click="goToStudentProfile(item.id)"
                            data-test="recent-student-icon"
                        >
                            <div class="student-img">
                                <div :style="item.styles"></div>
                            </div>
                        </div>
                        <div v-else class="ma-2 outer-student-circle"
                            @click="goToStudentProfile(item.id)"
                            data-test="recent-student-icon"
                        >
                            <div class="inner-student-circle">
                                {{ item.initials }}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="ma-2 student-img"
                            @click="goToStudentProfile(item.id)"
                            data-test="recent-student-icon"
                        >
                            <div :style="item.styles"></div>
                        </div>
                    </div>
                </fe-tooltip>
            </div>
        </template>
    </standard-widget>
</template>

<script>
import { mapState } from 'vuex'
import StandardWidget from './StandardWidget.vue';

export default {
    name: 'StudentCard',

    components: {
        StandardWidget
    },

    data() {
        return {
            reloading: false,
            studentImages: [],
            photoURL: false,
            tools: [],
            thumbnail: {
                icon: 'far fa-address-card'
            },
        }
    },

    computed: {
        ...mapState('global', ['uiSavedSearch', 'lastLaunchpadVisit', 'launchpadWidgetHeights']),
        useNewLaunchPad() {
            return this.$store.getters['flags/flags']['e-c-split-viz']
        }
    },

    mounted() {
        this.loadData()
    },

    methods: {
        loadData() {
            this.$getUiSavedSearch('RECENT_STUDENTS')
            if(this.uiSavedSearch) {
                this.generateSpriteSheet()
            } else {
                this.reloading = false
            }
        },
        generateSpriteSheet() {
            let ss = this.uiSavedSearch.RECENT_STUDENTS || []
            let ids = ss?.length && ss[0].arguments.map(x => x.argument_value)

            if(ids) {
                ids = this.$_.uniq(ids).slice(0,20)

                // Students for whom an image cannot be generated for any reason can
                // fall back to the generic silhouette
                this.$fetchStudentImages(ids, 64, null, true, true).then((obj) => {
                    this.photoURL = obj.img

                    // Preserve the order of the saved search / recent students
                    this.studentImages = ids.map(studentId => obj.images.find(img => img.id == studentId)).filter(itm => !!itm).reverse().slice(0, 16)

                    this.reloading = false
                })
            } else {
                this.reloading = false
            }

        },
        goToStudentProfile(id) {
            if(id) {
                this.$router.push({path: '/StudentProfile/' + id})
            }
        }
    },

    watch: {
        uiSavedSearch(v) {
            if(v) {
                this.generateSpriteSheet()
            }
        },
        lastLaunchpadVisit(v) {
            if (!this.reloading) {
                this.reloading = true
                this.loadData()
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.student-image-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 16px;
    align-items: center;
}

.outer-student-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #e0e1e6;
    background-color: transparent;
    cursor: pointer;
}

.student-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}

.inner-student-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #495b8f;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

</style>
