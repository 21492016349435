<template>
    <fe-remote-combo
        byId
        v-bind="$attrs"
        v-on="$listeners"
        :items="users"
        itemText="user_full_name"
        itemValue="id"
        style="width: 100%"
    >
        <template #selection="{item}">
            <fe-tooltip :disabled="fieldRec.tooltip ? false : true" :tooltip="fieldRec.tooltip" direction="bottom">
                <span
                    :style="{
                        'font-weight': fieldRec.bold ? '700' : '400',
                        'font-size': fieldRec.font_size ? fieldRec.font_size + 'px' : '13px',
                        'font-style': fieldRec.italic ? 'italic' : '',
                        'text-decoration': fieldRec.underline ? 'underline' : 'none'
                    }"
                >
                    {{item.user_full_name}}
                </span>
            </fe-tooltip>
        </template>
        <template #item="{item}">
            <v-list-item-content>
                <v-list-item-title>{{item.user_full_name}}</v-list-item-title>
            </v-list-item-content>
        </template>
    </fe-remote-combo>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'UserField',
        props: ['fieldRec','params'],
        data() {
            return {
                users: []
            }
        },
        mounted() {
            // these load without the active tag... if we want to control active users we should
            // add a computed field to mark them as disabled... do not filter disabled users
            if(this.fieldRec.tag_id) {
                this.$modelGet('user',{
                    property: 'by_tag',
                    school_year_id: this.sessionUser.currentYear.id,
                    tag_id: this.fieldRec.tag_id,
                }).then(r => {
                    this.users = r.map(x => ({
                        id: x.user_id,
                        user_full_name: x.user_full_name,
                    }))
                })
            } else {
                //for large districts, params.users was not loading 
                //switching to calling url directly like other remote combos for users
                if(this.params.users.length) {
                    this.users = this.params.users
                } else {
                    this.$axios.get('users.php?action=get&v=2&active=1')
                       .then(response => this.users = response.data)
                }
            }
        },
        computed: {
            ...mapState('global',['sessionUser']),
        },
    }
</script>

<style lang="scss" scoped>
    .field-label {
        max-width: 120px;
        padding: 10px 10px 0 0;
        margin: 0;
    }
</style>
