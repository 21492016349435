<template>
    <standard-widget
        widgetTitle="Quick Links"
        :cardHeight="launchpadWidgetHeights.thirdRow"
        cardStyle="margin-top: 0 !important;"
    >
        <template v-slot:widget-body>
            <div :class="{'link-container': newLaunchPadEnabled, 'd-flex': !newLaunchPadEnabled, 'flex-wrap': !newLaunchPadEnabled}" style="height: 350px;">
                <div
                    v-for="item in items.filter(r=>!r.hidden)"
                    class="tile"
                    :style="[{backgroundColor: item.color, width: newLaunchPadEnabled ? '100%' : '82px', margin: newLaunchPadEnabled ? null : '4px'}]"
                    :data-test="item.dataTest"
                    @click="navigation(item)"
                >
                    <div class="tile-text" :style="[{'font-weight': newLaunchPadEnabled ? 'bold' : null, 'font-size': newLaunchPadEnabled ? '14px': '12px'}]">{{ item.name }}</div>
                </div>
            </div>
            <div>
                <v-dialog
                    title="Resource Center"
                    v-model="resourceDialog.show"
                    persistent
                    fullscreen
                    @accept="resourceDialog.show=false"
                    @close="resourceDialog.show=false"
                >
                    <v-card>
                        <v-card-title>
                            <div class="d-flex" style="width: 100%;">
                                <div>Resource Center</div>

                                <v-spacer/>

                                <fe-icon-btn useIcon="fas fa-times" @click="resourceDialog.show=false"/>
                            </div>
                        </v-card-title>

                        <v-card-text style="overflow: hidden;">
                            <iframe
                                src="https://schoolcity.com/learning-hub/index.php/ec/"
                                style="border: none; height: 85vh;"
                                class="pdf-iframe"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </standard-widget>

</template>

<script>
import { mapState } from 'vuex'
import StandardWidget from './StandardWidget.vue'

export default {
    name: 'QuickLinksCard',

    components: {
        StandardWidget
    },

    data() {
        return {
            tools: [],
            studentTag: false,
            userTag: false,
            resourceDialog: {show: false},
            newLaunchPadEnabled: this.$store.getters['flags/flags']['e-c-split-viz']
        }
    },

    computed: {
        ...mapState('global', ['launchpadWidgetHeights']),
        items() {
            let me = this

            return [{
                name: 'User Tag Manager',
                color: this.newLaunchPadEnabled ? '#5A53C9' : '#746DE0',
                dataTest: 'quick-link-user-tag',
                handler() {
                    me.$dockableWindow({
                        name: 'User Tag Manager',
                        component: 'user-tags',
                        attrs: {
                            class: 'pa-5',
                            hideTitle: true
                        }
                    })
                }
            }, {
                name: 'Student Tag Manager',
                color: this.newLaunchPadEnabled ? '#0028A1' : '#2B87FF',
                dataTest: 'quick-link-student-tag',
                handler() {
                    me.$dockableWindow({
                        name: 'Student Tag Manager',
                        component: 'student-tags',
                        attrs: {
                            class: 'pa-5',
                            hideTitle: true
                        }
                    })
                }
            }, {
                name: 'Collections',
                color: this.newLaunchPadEnabled ? '#2B3963' : '#FFCA26',
                path: '/Collections',
                dataTest: 'quick-link-collections'
            }, {
                name: 'Program Evaluation',
                color: this.newLaunchPadEnabled ? '#0B7D38' : '#746DE0',
                dataTest: 'quick-link-program-eval',
                hidden: !me.$hasSecurity('PROGRAM_EVALUATION_DASHBOARD'),
                handler() {
                    me.$dockableWindow({
                        name: 'Program Evaluation',
                        component: 'program-evaluation',
                        attrs: {
                            class: 'pa-5'
                        }
                    })
                }
            }, {
                name: this.newLaunchPadEnabled ? 'Student Learning Objectives' : 'SLO',
                color: this.newLaunchPadEnabled ? '#0049FF' : '#49C379',
                dataTest: 'quick-link-slo',
                hidden: !this.sloEnabled,
                handler() {
                    me.$dockableWindow({
                        name: 'SLO Dashboard',
                        component: 'slo',
                        attrs: {
                            class: 'pa-5'
                        }
                    })
                }
            }]
        },

        sloEnabled() {
            return this.$store.getters['flags/flags']['ec-slo'] === true
        },

    },

    methods: {
        navigation(item) {
            if (item.handler) {
                item.handler()
                return
            }
            if (item.window) {
                this.$store.commit('global/addDockableWindow', {
                    name: item.name,
                    component: item.component
                })
            } else if (item.path) {
                this.$router.push(item.path)
            } else if (item.name === 'Student Tag Manager') {
                this.studentTag = true
            } else if (item.name === 'User Tag Manager') {
                this.userTag = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.link-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    align-content: flex-start;
}

.tile {
    border-radius: 5px;
    height: 77px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
        padding: 10px;
        color: white;
        text-align: center;
    }
}
</style>
