<template>
    <div style="width: 100%">
        <div v-if="!showChart">
            <fe-spinner class="spinner"/>
        </div>
        <div v-else style="position: absolute; z-index: 100; top: 58px;display: flex;width: 60%;left: 20%;">
            <v-btn text
                style="color: #006C90; font-size: 14px"
                @click="changeMonth('back')" 
                data-test="incident-heatmap-back-btn"
                :style="{visibility: withinBackRange ? 'visible': 'hidden'}"
            >
                <v-icon class="fal fa-chevron-left calendar-heat-map-arrow-icon pr-2"></v-icon>
                <span>{{ lastMonth }}</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text
                style="color: #006C90; font-size: 14px"  
                @click="changeMonth('forward')" 
                data-test="incident-heatmap-forward-btn"
                :style="{visibility: withinForwardRange ? 'visible': 'hidden'}"
            >
                <span class="pr-2">{{ nextMonth }}</span>
                <v-icon small class="fal fa-chevron-right calendar-heat-map-arrow-icon"></v-icon>
            </v-btn>
        </div>
        <highcharts
            v-if="showChart"
            ref='calendar-heat-map'
            class="calendar-heat-map"
            :options="chartOptions"
        />
    </div>
</template>

<script>
    import Highcharts from 'highcharts'
    import hcHeatmap from 'highcharts/modules/heatmap'
    hcHeatmap(Highcharts)

    export default {
        props: {
            params: { default: null }, // initial data
            endpoint: { default: null }, // needed to grab new data
            height: { default: '250' },
        },
        data() {
            return {
                weekdays: ['M', 'Tu', 'W', 'Th', 'F'],
                chartOptions: {},
                showChart: false,
                selectedMonthYr: this.$dayjs(new Date()).format('MMM YYYY'),
                currMonthYr: this.$dayjs(new Date()).format('MMM YYYY'),
            }
        },
        computed: {
            withinBackRange() {
                let diff = this.$dayjs(this.selectedMonthYr).diff(this.$dayjs(this.currMonthYr), 'month') 
                return diff === 0 || diff === -1  // if starting month OR went forward a month 
            },
            withinForwardRange() {
                let diff = this.$dayjs(this.selectedMonthYr).diff(this.$dayjs(this.currMonthYr), 'month')
                return diff === -1 || diff == -2  // only show if back one or two months
            },
            lastMonth() {
                return this.$dayjs(this.selectedMonthYr).subtract(1, 'month').format('MMM')
            },
            nextMonth() {
                return this.$dayjs(this.selectedMonthYr).add(1, 'month').format('MMM')
            }
        },
        mounted() {
            if(this.params) {
                this.buildChart(this.params)
            } else if(this.endpoint) {
                // make endpoint call
                this.$axios.get(this.$models.getUrl(this.endpoint, 'read')) 
                    .then(response => {
                        this.buidChart(response)
                    })
            }
        },
        methods: {
            generateCalendar(data) {
                // Calculate the starting weekday index (0-4 of the first date in the given
                // array)
                const firstWeekday = new Date(data[0].date).getDay(),
                    monthLength = data.length,
                    lastElement = data[monthLength - 1].date,
                    lastWeekday = new Date(lastElement).getDay(),
                    lengthOfWeek = 4,
                    emptyTilesFirst = firstWeekday,
                    chartData = []

                // Add the empty tiles before the first day of the month with null values to
                // take up space in the chart
                for (let emptyDay = 0; emptyDay < emptyTilesFirst; emptyDay++) {
                    chartData.push({
                        x: emptyDay,
                        y: 5,
                        value: null,
                        date: null,
                        custom: {
                            empty: true
                        }
                    })
                }

                // Loop through and populate with count and dates from the dataset
                for (let day = 1; day <= data.length; day++) {
                    // Get date from the given data array
                    const date = data[day - 1].date
                    // Offset by the number of empty tiles
                    const xCoordinate = (emptyTilesFirst + day - 1) % 5
                    const yCoordinate = Math.floor((firstWeekday + day - 1) / 5)
                    const id = day

                    // Get the corresponding count for the current day from the given
                    // array
                    let count = data[day - 1].count

                    chartData.push({
                        x: xCoordinate,
                        y: 5 - yCoordinate,
                        value: count,
                        date: new Date(date).getTime(),
                        custom: {
                            monthDay: id
                        }
                    })
                }

                // Fill in the missing values when dataset is looped through.
                const emptyTilesLast = lengthOfWeek - lastWeekday
                for (let emptyDay = 1; emptyDay <= emptyTilesLast; emptyDay++) {
                    chartData.push({
                        x: (lastWeekday + emptyDay) % 5,
                        y: 0,
                        value: null,
                        date: null,
                        custom: {
                            empty: true
                        }
                    })
                }
                return chartData
            },
            buildChart(data) {
                let me = this
                this.chartOptions = {
                    chart: {
                        type: 'heatmap', 
                        height: me.height,
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 70
                    },
                    title: {
                        text: me.selectedMonthYr,
                        style: {
                            fontWeight: '500',
                            color: '#050F2D',
                            fontSize: '16px'
                        }
                    },
                    tooltip: {
                        useHTML: true,
                        headerFormat: '',
                        pointFormatter() {
                            let month = me.$dayjs(me.currMonthYr).format('M')
                            let day = this.custom.monthDay 
                            let yr = me.$dayjs(me.currMonthYr).format('YYYY')
                            return `<div class='mb-2'><b>${month}/${day}/${yr}</b></div><div>Total Incidents: <b>${this.value}</b><div>`
                        },
                        nullFormatter() {
                            return 'No data'
                        }
                    },
                    xAxis: {
                        categories: me.weekdays,
                        opposite: true,
                        accessibility: {
                            description: 'weekdays'
                        },
                        labels: {
                            style: {
                                fontSize: '16px'
                            }
                        },
                        min: 0,
                        max: 4,
                        gridLineWidth: 0,
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        lineColor: 'transparent',
                        minorTickLength: 0,
                        tickLength: 0,
                        offset: -10
                    },
                    yAxis: {
                        min: 1,
                        max: 5,
                        accessibility: {
                            description: 'weeks'
                        },
                        visible: false,
                        endOnTick: false,
                        startOnTick: false,
                        gridLineWidth: 0
                    },
                    legend: {
                        labelFormatter() {
                            return this.name.replace(/.00/g, '').replace('>','&#8805;').replace('<', '&#8804;')
                        },
                        align: 'center',
                        symbolRadius: 2,
                        margin: 5,
                        paddingBottom: 0
                    },
                    colorAxis: {
                        dataClasses: [{
                            color: '#ECEDF1',
                            to: 3
                        }, {
                            color: '#2B87FF',
                            from: 4,
                            to: 7
                        }, {
                            color: '#0028A1',
                            from: 8
                        }]
                    },
                    series: [{
                        keys: ['x', 'y', 'value', 'date', 'id'],
                        data: me.generateCalendar(data),
                        nullColor: '#fff',
                        borderWidth: 2,
                        borderColor: '#fff',
                        dataLabels: [{
                            enabled: true,
                            style: {
                                textOutline: 'none',
                                fontWeight: 'normal',
                                fontSize: '1rem'
                            },
                            y: 1,
                            formatter: function() {
                                if (this.point.custom.empty) return
                                if (this.color === '#2B87FF') {
                                    return '<span style="color: #000;">' + this.point.value + '</span>'
                                }
                                return this.point.value
                            }
                        }]
                    }]
                }
                this.showChart = true
            },
            changeMonth(direction) {
                if(direction == 'back') {
                    this.selectedMonthYr = this.$dayjs(this.selectedMonthYr).subtract(1, 'month').format('MMM YYYY')
                } else {
                    this.selectedMonthYr = this.$dayjs(this.selectedMonthYr).add(1, 'month').format('MMM YYYY')
                }
                this.$refs['calendar-heat-map'].chart.title.update({text: this.selectedMonthYr})

                if(this.endpoint) {
                    this.$axios.get(this.$models.getUrl(this.endpoint, 'read')) 
                        .then(response => {
                            this.buidChart(response)
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.calendar-heat-map {
    ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
} 

.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
}

.calendar-heat-map-arrow-icon {
    font-size: 12px !important;
}
</style>
