import Vue from 'vue'
import {
    Defaults,
    EcUpdateTheme,
    EcFrontendLibrary,
    EcFrontendVuetify,
    GetVuetifyInstance
} from '@illuminateeducation/ec-frontend-library'
import '@illuminateeducation/ec-frontend-library/dist/ec-frontend-library.css'
// dev only
// import { Defaults, EcUpdateTheme, EcFrontendLibrary, EcFrontendVuetify, GetVuetifyInstance } from 'ec-frontend-library/plugin'
// import 'ec-frontend-library/src/styles/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsExporting from 'highcharts/modules/export-data'
import HighchartsXrange from 'highcharts/modules/xrange'
import Sankey from 'highcharts/modules/sankey'
import chartTheme from './assets/chart-theme.js'
import patternFill from 'highcharts/modules/pattern-fill'
patternFill(Highcharts)

Highcharts.theme = chartTheme
Highcharts.setOptions(Highcharts.theme)
import VueTheMask from 'vue-the-mask'
import router from './router'
import store from './vuex'
import App from './App.vue'
import helpers from '@/plugins/helpers'
import ecGoogle from '@/plugins/ecGoogle'
import lodash from 'lodash'
import models from '@/models'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import VueObserveVisibility from 'vue-observe-visibility'
import VueSlideoutPanel from 'vue2-slideout-panel'
import rum from '@/plugins/datadogRum'
import i18n from './i18n'

const JSON5 = require('json5')

// throttle number of requests to prevent any client from overwhelming the
// backend. we will allow REQUEST_LIMIT which is tracked with
// currentRequestCount. If the REQUEST_LIMIT is met, we will wait
// REQUEST_LIMIT_CHECK_INTERVAL_MS before checking currentRequestCount to
// see if we can dispatch the next request. Failsafe: If
// REQUEST_LIMIT_RECOVERY_INTERVAL_MS is met, we will decrement
// currentRequestCount so that the process can recover gracefully just in case
// currentRequestCount doesn't decrement because of some unforseen issue
const REQUEST_LIMIT = 10
const REQUEST_LIMIT_CHECK_INTERVAL_MS = 10
const REQUEST_LIMIT_RECOVERY_INTERVAL_MS = 5000
let currentRequestCount = 0
let delayedRequestCount = 0

export const bus = new Vue();

// mixins
import localStoreMixin from '@/mixins/localStore.js'

Vue.use(EcFrontendVuetify)
Vue.use(VueSlideoutPanel)

let vuetify = GetVuetifyInstance({
    iconfont: 'fa',
    theme: {
        options: {
            customProperties: true
        },
        dark: false,
        themes: Defaults.ecThemes
    }
})

EcUpdateTheme(Defaults.ecThemes.light)

Vue.use(EcFrontendLibrary, {
    axios: {
        withCredentials: true,
        baseURL: process.env.VUE_APP_BASE_URI ? process.env.VUE_APP_BASE_URI.replace(/localhost/g, location.host) : '',

        // override default so we can check this in interceptors
        // axios should have sent the response to validateStatus
        validateStatus: null
    },
    overrides: {
        feCrudFailOnEmptyResponse: true,
        feGridDisableResizeOnUpdate: true
    }

})

Vue.prototype.$axios.interceptors.request.use(config => {
    // determine if we're doing a request to eC so that we can inject our custom headers/params
    // this requires that the url has no scheme (relative) or that the origins match
    const url = config.url || '';
    const isRequestToEC = !/^[a-z]+:\/\//.test(url) || url.slice(0, window.location.origin.length) == window.location.origin;
    if (isRequestToEC) {
        if (config.method == 'post') {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        if (store.state.global.sessionUser) {
            config.headers['X-Amzn-Trace-Id'] = `District-ID=${store.state.global.sessionUser.district.login_id};User-ID=${store.state.global.sessionUser.user.id}`
        }
        config.params = config.params || {}
        config.params.v = 2
    }

    // if we don't need to delay this request
    if (currentRequestCount < REQUEST_LIMIT) {
        currentRequestCount++
        return config
    }
    // delayed request
    ++delayedRequestCount
    return new Promise((resolve) => {
        let delayedTime = REQUEST_LIMIT_CHECK_INTERVAL_MS
        let delayedRecovery = false
        let delayedRequestNumber = delayedRequestCount
        let throttledRequestCheck = setInterval(() => {
            if (currentRequestCount >= REQUEST_LIMIT) {
                delayedTime += REQUEST_LIMIT_CHECK_INTERVAL_MS
                if (!delayedRecovery && delayedTime > REQUEST_LIMIT_RECOVERY_INTERVAL_MS) {
                    currentRequestCount = Math.max(0, currentRequestCount - 1)
                    delayedRecovery = true
                }
                return;
            }
            currentRequestCount++
            clearInterval(throttledRequestCheck)
            resolve(config)
        }, REQUEST_LIMIT_CHECK_INTERVAL_MS)
    })
}, error => Promise.reject(error))

Vue.prototype.$axios.interceptors.response.use(response => {
    currentRequestCount = Math.max(0, currentRequestCount - 1)
    if (response.status < 200 || response.status >= 300) {
        window.onbeforeunload = null
        if (response.data.redirect) {
            window.location = response.data.redirect
        }
        Promise.reject(response.data.sessionStatus || 'session closed')
    }

    let recurs = function (item) {
        if (item && typeof item === 'object') {
            Object.keys(item).forEach(property => {
                if (property == 'student_full_name') {
                    item.student_full_name = item.student_id ? String(item.student_id) : String(item.id)
                    item.lname = item.student_id ? String(item.student_id) : String(item.id)
                    item.fname = ''
                    item.mdl_init = ''
                }

                // Old bug where ethnicity was spelled wrong.  Leaving it here
                // in case there really is a backend endpoint that also returns typo
                else if (property == 'ethnicity' || property == 'ethcicity') {
                    item.lname = String(item.id)
                    item.fname = ''
                    item.mdl_init = ''
                } else if (typeof item[property] === 'object') {
                    recurs(item[property])
                }
            })
        }
    }
    if (store.state.global.userPreferences.TOP_SECRET_MODE?.user_value == '1') {
        if (response.request.responseText.includes('student_full_name')) {
            recurs(response.data)
        }
    }

    if (response.data.success === false && response.data.msg_type == 'warn') {
        return new Promise((resolve, reject) => {
            Vue.prototype.$messageBox({
                title: response.data.title || 'Confirm',
                persistent: true,
                message: response.data.msg || 'Are you sure you want to continue?',
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    usage: "ghost",
                    onClick: () => {
                        response.data.canceled = true
                        resolve(response)
                    }
                }, {
                    text: 'Continue',
                    primary: true,
                    onClick: () => {
                        response.config.params.warn_confirmed = 1
                        Vue.prototype.$axios.request(response.config).then(r => {
                            response = r
                            resolve(response)
                        })
                    }
                }]
            })
        })
    }

    return response
}, error => {
    currentRequestCount = Math.max(0, currentRequestCount - 1)
    Promise.reject(error)
})

Highcharts.setOptions({
    credits: {
        enabled: false
    },
    responsive: true,
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    lang: {
        thousandsSep: ','
    }
})

let localUtil = {
    install: function (vue) {
        vue.prototype.$reflow = () => {
            Highcharts.charts.forEach(function (chart) {
                if (chart && chart.reflow) {
                    chart.reflow();
                }
            });
        }
    }
}

Vue.use(localUtil)
Vue.use(HighchartsVue)
Vue.use(VueTheMask)
Vue.use(helpers)
Vue.use(ecGoogle)
Vue.use(VueObserveVisibility)
Sankey(Highcharts)
HighchartsExporting(Highcharts)
HighchartsXrange(Highcharts)

Vue.config.productionTip = false
Vue.prototype.$vuetify = vuetify
Vue.prototype.$_ = lodash
Vue.prototype.$JSON5 = JSON5

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
Vue.prototype.$dayjs = dayjs

Vue.prototype.$models = models // Warning spread operator/Object.assign will modify this object globally directly due to `import` above

lodash.mixin({
    // _.isBlank(undefined) : true
    // _.isBlank(null) : true
    // _.isBlank(NaN) : true
    // _.isBlank('') : true
    // _.isBlank({}) : true
    // _.isBlank([]) : true
    // _.isBlank(false) : true
    // _.isBlank(true) : false
    // _.isBlank('a') : false
    // _.isBlank(0) : false
    // _.isBlank(1) : false
    // _.isBlank({foo: 'bar'}) : false
    // _.isBlank(['foo', 'bar']) : false

    isBlank: v => lodash.isEmpty(v) && (!lodash.isNumber(v) && v !== true) || lodash.isNaN(v)
})

// Check if the user has an active session
Vue.prototype.$axios.post('poll.php?action=get&set_security=1', {
    polls: [{
        key: 'server_last_activity',
        pollIndex: 0
    }]
}).then(() => {
    store.dispatch('global/loadUserPreferences')
        .then(() => {
            store.dispatch('global/loadSessionUser')
                .then(() => {
                    // need user session information to be set before starting datadog rum
                    rum.startRum()
                    rum.setUserSession()
                    rum.startSession()

                    Vue.prototype.$axios.get('qry.php?property=shareable_stores').then(shareables => {
                        new Vue({
                            mixins: [localStoreMixin],
                            router,
                            store,
                            i18n,
                            vuetify,
                            created() {
                                let me = this
                                let d = this.$ecResponse(shareables, 'shareable_stores')
                                this.$store.commit('set', {
                                    module: 'global',
                                    state: 'shareableStores',
                                    value: d
                                })

                                this.$store.commit('set', {
                                    module: 'global',
                                    state: 'currentYear',
                                    value: d.current_year
                                })

                                this.$store.commit('set', {
                                    module: 'global',
                                    state: 'googleClientId',
                                    value: d.google_client_id
                                })

                                /**
                                 *  On load, called to load the auth2 library and API client library.
                                 */
                                gapi.load('client:auth2', function () {
                                    /**
                                     *  Called when the signed in status changes, to update the UI
                                     *  appropriately. After a sign-in, the API is called.
                                     */
                                    gapi.client.init({
                                        clientId: me.$store.state.global.googleClientId,
                                        discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
                                        scope: "https://www.googleapis.com/auth/calendar.events"
                                    })
                                });

                                this.$store.commit('set', {
                                    module: 'global',
                                    state: 'uris',
                                    value: d.uris
                                })

                                this.$axios.get('preferences.php?action=get&property=district')
                                    .then(response => {

                                        let prefs = this.$ecResponse(response, 'preferences')
                                        let c = {}

                                        prefs.forEach(pref => {
                                            c[pref.code] = parseInt(pref.user_value) || parseInt(pref.user_value) === 0 ? parseInt(pref.user_value) : pref.user_value
                                        })
                                        this.$store.commit('set', {
                                            module: 'global',
                                            state: 'districtConfig',
                                            value: c
                                        })
                                    })
                                this.$store.dispatch('global/loadAlphaMaps')

                                if (this.$store.getters['flags/flags']['ec-forethought-chatbot']) this.loadForeThoughtSalesForce()
                                else this.loadZendesk()

                                let SessionUser = this.$store.state.global.sessionUser
                                // Pendo
                                pendo.initialize({
                                    excludeAllText: true,
                                    excludeTitle: true,
                                    visitor: {
                                        id: SessionUser.district.login_id + '-' + SessionUser.user.id,
                                        permissions: SessionUser.security_codes.join(',')
                                    },

                                    account: {
                                        id: SessionUser.district.login_id + '-' + SessionUser.district.name,
                                        module: this.getProducts(),
                                        state: SessionUser.district.state,
                                        sf_nm: d.district.sf_number,
                                        sf_status: d.district.sf_status,
                                        district_age: d.district_age,
                                        fb_pm_single_entry: d.fb_pm_single_entry,
                                    }
                                })
                            },
                            render: h => h(App),
                            methods: {
                                getProducts() {
                                    let SessionUser = this.$store.state.global.sessionUser

                                    return (SessionUser.district.dashboard_only ? "-educlimber" : "+educlimber+ec_early_access") + this.checkProduct('dashboard_enabled', 'AD') + this.checkProduct('psportal_enabled', 'psPortal') + this.checkProduct('edfi_enabled', 'EdFi') + this.checkProduct('dashboard_spark', 'ADSpark')
                                },
                                checkProduct(key, displayAs) {
                                    let SessionUser = this.$store.state.global.sessionUser
                                    if (SessionUser.district[key]) {
                                        return "+" + (displayAs ? displayAs : SessionUser.district[key])
                                    }

                                    return "-" + (displayAs ? displayAs : SessionUser.district[key])
                                },
                                loadForeThoughtSalesForce() {
                                    const script = document.createElement('script')
                                    script.setAttribute('src', "https://solve-widget.forethought.ai/embed.js")
                                    script.setAttribute('id', "forethought-widget-embed-script")
                                    script.setAttribute('data-api-key', "5791f610-77c3-450b-9cef-3b6c3419962d")
                                    script.setAttribute('data-ft-workflow-tag', "eduCLIMBER_Platform")
                                    script.setAttribute('hidden', 'true')
                                    script.setAttribute('hide-ft-after-zd', 'true')
                                    script.setAttribute('offset-x', '80px')
                                    document.body.appendChild(script)

                                    let salesforceScript = document.createElement('script')

                                    salesforceScript.onload = function() {
                                        try {
                                            embeddedservice_bootstrap.settings.language = 'en_US';
                                            window.addEventListener("onEmbeddedMessagingReady", () => {
                                                console.log("Received the onEmbeddedMessagingReady event…");
                                                embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({"ProductFamily" : "eduCLIMBER"});
                                            });
                                                embeddedservice_bootstrap.init(
                                                    '00DHr000001sf9h',
                                                    'RLI_Product_Support_Chat',
                                                    'https://renlearn.my.site.com/ESWRLIProductSupportCh1733220094443',
                                                    { scrt2URL: 'https://renlearn.my.salesforce-scrt.com' }
                                                )
                                        } catch (err) {
                                            console.error('Error loading Embedded Messaging: ', err)
                                        }
                                    }
                                    salesforceScript.setAttribute('type', 'text/javascript')
                                    salesforceScript.setAttribute('src', 'https://renlearn.my.site.com/ESWRLIProductSupportCh1733220094443/assets/js/bootstrap.min.js')
                                    document.body.appendChild(salesforceScript)

                                },
                                loadZendesk() {
                                    if (!process.env.VUE_APP_SKIP_ZENDESK) {
                                        this.$axios.get('qry.php?property=app_config&config=zendesk_token').then(response => {
                                            if (response.data) {
                                                let zd = document.createElement('script')
                                                zd.setAttribute('id', 'ze-snippet')
                                                zd.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${response.data}`)

                                                zd.onload = function () {
                                                    let scr = document.createElement('script')
                                                    scr.setAttribute('type', 'text/javascript')
                                                    scr.innerHTML = `
                                                        zE(function () {
                                                            zE.hide();
                                                        });

                                                        zE("webWidget:on", "chat:end", function () {
                                                            zE.hide();
                                                        });
                                                        `
                                                    document.head.appendChild(scr)
                                                }
                                                document.head.appendChild(zd)
                                            }
                                        })
                                    }
                                }
                            }
                        }).$mount('#app')
                    })
                })
        })
        .catch(error => {
            if (error && error.response && error.response.data && error.response.data.redirect) {
                let host = location.hostname
                if (host === 'localhost') {
                    window.location.href = 'http://localhost:8000/welcome/login.php'
                } else if (host.indexOf('.com') != -1) {
                    window.location.href = 'https://app.educlimber.com/welcome/login.php'
                } else if (host.indexOf('.app') != -1) {
                    window.location.href = 'https://qa01.educlimber.app/welcome/login.php'
                }
            }
        })
})
