import ManageIncident from '@/components/modules/manage/incidents/Index'
import IncidentTypeList from '@/components/modules/manage/incidents/IncidentTypeList'
import ManageIncidentType from '@/components/modules/manage/incidents/IncidentType'
import ManageIncidentBehavior from '@/components/modules/manage/incidents/IncidentBehavior'
import BigFive from '@/components/modules/incidents/BigFive'
import IncidentLanding from '@/components/modules/incidents/Index'
import IncidentCharting from '@/components/modules/incidents/IncidentCharting'

export default [{
    path: '/Incidents',
    name: 'Incidents',
    component: IncidentLanding
}, {
    path: '/Incidents/Student/:studentId',
    name: 'Student Attendance',
    component: BigFive,
    props: (route) => {
        return {
            params: {
                student_id: route.params.studentId,
                showAll: 1
            }
        }
    }
}, {
    path: '/Manage/Incident',
    name: 'Incident Settings',
    component: ManageIncident,
    meta: {
        navigation: 'manage',
        routes: ['/Manage']

    }
}, {
    path: '/Manage/IncidentTypeList',
    name: 'Incident Types',
    component: IncidentTypeList,
    meta: {
        navigation: 'manage',
        routes: ['/Manage', '/Manage/Incident']
    }
}, {
    path: '/Manage/IncidentType/:id',
    name: 'Incident Type',
    component: ManageIncidentType,
    props: true,
    meta: {
        navigation: 'manage',
        routes: ['/Manage', '/Manage/Incident', '/Manage/IncidentTypeList']
    }
}, {
    path: '/Manage/IncidentCodes/:incidentBehaviorTypeId',
    name: 'Incident Codes',
    component: ManageIncidentBehavior,
    props: true,
    meta: {
        navigation: 'manage',
        routes: ['/Manage', '/Manage/Incident', '/Manage/IncidentTypeList']
    }
}, {
    path: '/Incidents/Charting',
    name: 'Incident Charting',
    component: IncidentCharting,
    props: true
}]