import { render, staticRenderFns } from "./AcademicProgressMonitoring.vue?vue&type=template&id=9e7796f8&scoped=true"
import script from "./AcademicProgressMonitoring.vue?vue&type=script&lang=js"
export * from "./AcademicProgressMonitoring.vue?vue&type=script&lang=js"
import style0 from "./AcademicProgressMonitoring.vue?vue&type=style&index=0&id=9e7796f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e7796f8",
  null
  
)

export default component.exports