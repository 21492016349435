<template>
    <div class="flex-fill flex-column no-scroll">
        <student-data-manager
            v-if="showMatchedDialog.show"
            @showStudentManagerClicked="showMatchedDialog.show=false"
            :selectedStudent="showMatchedDialog.records[showMatchedDialog.index]"
        >
            <template v-slot:footer>
                <div class="ml-auto">
                    <fe-btn
                        icon
                        usage="tertiary"
                        useIcon="fas fa-chevron-left"
                        :disabled="showMatchedDialog.index === 0"
                        @click="showMatchedDialog.index--"
                    />
                    <fe-btn
                        icon
                        usage="tertiary"
                        useIcon="fas fa-chevron-right"
                        :disabled="showMatchedDialog.index === showMatchedDialog.records.length-1"
                        @click="showMatchedDialog.index++"
                    />
                    <fe-btn
                        usage="primary" class="ml-auto"
                        @click="setMatchedStudent(showMatchedDialog.records[showMatchedDialog.index])"
                    >Use Student</fe-btn>
                </div>
            </template>
        </student-data-manager>

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="Create Student Alias"
            v-if="dialog.show"
            @dismiss="dialog.show=false"
            @close="dialog.show=false"
            @accept="setAlias"
            acceptButtonText="Alias"
            :acceptButtonDisabled="!dialog.record"
            persistent
        >
            <v-form>
                <fe-label>Student</fe-label>
                <v-autocomplete
                    :items="students"
                    v-model="dialog.record"
                    itemValue="id"
                    itemText="student_full_name"
                    required
                    flat
                    solo
                    dense
                ></v-autocomplete>
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            @gridReady="setGrid"
            @cellClicked="doCellClick"
            showToolbar
            domLayout="normal"
            style="height: calc(100% - 75px); width: 100%;"
        >
            <template v-slot:toolbar-items>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="tertiary" v-on="on">Quick Select</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="selectMatched(0)">
                            <v-list-item-content>
                                <v-list-item-title>By Any Matched Students</v-list-item-title>
                                <v-list-item-subtitle>Select all import records where there is a name suggestion match</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="selectMatched(1)">
                            <v-list-item-content>
                                <v-list-item-title>By Strong Matched Students</v-list-item-title>
                                <v-list-item-subtitle>Select all import records where there is a name suggestion match and date of births are matched</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="selectUnmatched">
                            <v-list-item-content>
                                <v-list-item-title>By Unmatched Students</v-list-item-title>
                                <v-list-item-subtitle>Select all import records where there are no suggested matching students</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on" :disabled="!selections.length">Actions</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="(item, index) in tools"
                            :key="index"
                            @click="item.handler()"
                            :hidden="disableActionItem(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>

        <div class="d-flex pt-2 pr-10">
            <v-spacer/>
            <v-btn text icon small :disabled="pagination.page === 1 || loading" @click="previousPage" data-test="unknown-student-paging-prev">
                <v-icon>fas fa-chevron-left</v-icon>
            </v-btn>
            <fe-label class="mt-3 mr-5"> Page</fe-label>
            <div style="width: 70px;">
                <v-select flat solo dense :items="pages" v-model="pagination.page" @change="loadData"  data-test="unknown-student-paging-page" />
            </div>
            <fe-label class="mt-3 ml-5"> of {{ pages.length }}</fe-label>
            <v-btn text icon small :disabled="pagination.page >= Math.ceil(pagination.total / pagination.limit) || loading" @click="nextPage" data-test="unknown-student-paging-next">
                <v-icon>fas fa-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import StudentDataManager from '@/components/modules/student/Index'

export default {
    name: "UnknownStudent",
    props: {
        uploadId: {
            required: true
        }
    },
    components: {
        StudentDataManager
    },
    data() {
        let me = this;

        return {
            yellow: "#fdff85",
            green: "#beffab",
            loading: false,
            canSave: false,
            selections: [],
            students: [],
            items: [],
            lookup: {},
            pagination: {
                page: 1,
                limit: 100,
                total: 0
            },
            showMatchedDialog: {
                show: false,
                records: [],
                index: 0,
                importStudent: null
            },
            dialog: {
                show: false,
                record: null
            },
            pendingText:
                '<br><p class="caption mt-2">This will also save any pending changes.</p>',
            originalItems: [],
            tools: [
                {
                    title: "Create Student",
                    description: "Create as a new student in eduCLIMBER",
                    handler() {
                        me.$confirmCreate(me.selections, () => {
                            me.selections.forEach(rec => {
                                rec.new_student = true
                                rec.match_student = false
                                rec.matched_student_id = null
                            });
                            me.doSave();
                        })
                    }
                },
                {
                    title: "Match to Student",
                    description:
                        "Set this student status name as an alternate known name for a selected student status",
                    handler() {
                        me.$confirmCreate(me.selections, () => {
                            me.selections.forEach(rec => {
                                rec.new_student = false
                                rec.match_student = true

                                // rec.matched_student_id = null
                            });
                            me.doSave()
                        }, 'Match')
                    }
                }
            ],
            columns: []
        };
    },
    computed: {
        pages() {
            return Array.from({ length: Math.ceil(this.pagination.total / this.pagination.limit) }, (_, i) => i + 1)
        }
    },
    watch: {
        items(v) {}
    },
    mounted() {
        this.loadStudents()
        this.loadData()
    },
    methods: {
        previousPage() {
            if (this.pagination.page > 1) {
                this.pagination.page--
                this.loadData()
            }
        },
        nextPage() {
            const maxPage = Math.ceil(this.pagination.total / this.pagination.limit)
            if (this.pagination.page < maxPage) {
                this.pagination.page++
                this.loadData()
            }
        },
        disableActionItem(item) {
            if (this.selections.length === 0) {
                return true
            } else if (item.title == 'Create Student') {
                return false
            } else if (item.title == 'Match to Student') {
                let found = false
                this.selections.forEach(rec => { if (!rec.matched_student_id) found = true })
                return found
            }
            return false
        },
        doCellClick(meta) {
            let students = meta.data.matched_student_list

            if (meta.data.matched_student_id) {
                students = students.concat({
                    id: meta.data.matched_student_id,
                    student_id: meta.data.matched_student_id,
                    fname: meta.data.matched_student_full_name.split(',')[1],
                    lname: meta.data.matched_student_full_name.split(',')[0],
                })
            }

            if (meta.colDef.headerName == 'View Matched' && students.length > 0) {
                students.forEach(rec => { rec.student_id = rec.id })
                this.showMatchedDialog.records = students
                this.showMatchedDialog.index = 0
                this.showMatchedDialog.show = true
                this.showMatchedDialog.importStudent = meta.data
            }
        },
        setMatchedStudent(student) {
            this.$confirmCreate(student, () => {
                this.showMatchedDialog.importStudent.matched_student_id = student.student_id
                this.showMatchedDialog.importStudent.match_student = true
                this.showMatchedDialog.importStudent.new_student   = false

                this.$refs.crud.update([this.showMatchedDialog.importStudent])
                    .then(response => {})
                    .finally(() => {
                        this.loading = false
                        this.loadStudents()
                        this.showMatchedDialog.show = false
                    })

            }, 'Match')
        },
        selectMatched(strong) {
            let arr = [];
            let warn = [];
            this.$refs.grid.gridApi.deselectAll()
            this.$refs.grid.gridApi.forEachNode(function(node) {
                if (strong && node.data.row_color == 'green-row') {
                    node.setSelected(true)
                } else if (!strong && node.data.quick_match) {
                    node.setSelected(true)
                }
            });
        },
        selectUnmatched() {
            let arr = [];
            this.$refs.grid.gridApi.deselectAll()
            this.$refs.grid.gridApi.forEachNode(function(node) {
                if (!node.data.matched_student_id && node.data.row_color != 'red-row') {
                    node.setSelected(true)
                }
            });
        },
        setGrid() {
            let me = this;
            this.$refs.grid.gridOptions.getRowStyle = function(params) {
                let rec = params.node.data;

                if (rec) {
                    let color = undefined;

                    switch (rec.row_color) {
                        case "green-row":
                            return { color: 'green' }
                            break;
                        case "red-row":
                            return {
                                color: "red"
                            };
                            break;
                        case "yellow-row":
                            return {
                                color: "orange"
                            };
                            break;
                    }
                }
            }
            // make sure table shows all rows
            // column autoHeight can prevent this
            setTimeout(() => {
                this.$refs.grid.gridOptions.api.resetRowHeights()
            }, 1000)
        },
        findStudent(v) {
            let me = this;

            if (v.value) {
                let r = this.lookup[v.value];
                if (r) {
                    let matchedDOB = (v.data.matched_date_of_birth?v.data.matched_date_of_birth : 'Not Provided')
                    return r.lname + ", " + r.fname+'<div>Date of Birth: '+ matchedDOB +'</div>'
                }
            } else if (v.data.row_color == "red-row") {
                return 'Multiple matches'
            }
            return 'No match'
        },
        doSave() {
            let dirty = this.$isDirty(this.originalItems, this.items)
            this.loading = true
            this.$axios
                .post(this.$models.getUrl('unknownStudent', 'update'), {students: dirty})
                .then(response => {
                    this.loadStudents()
                    this.loadData()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        setAlias() {
            let me = this;
            this.dialog.show = false;
            this.selections.forEach(rec => {
                rec.matched_student_id = this.dialog.record;
            });

            this.sanitize();
            this.$refs.grid.gridApi.deselectAll();
            this.doSave();
        },
        selectRow(row) {
            this.selections = this.$refs.grid.gridApi.getSelectedRows();
        },
        sanitize() {
            // this will check to see if the original fetched data set has been manipulated
            let dirty = this.$isDirty(this.originalItems, this.items);
            if (dirty) {
                this.canSave = true;
            } else {
                this.canSave = false;
            }
            // Since we are changing cells behind the ag-grid api, call refresh
            this.$refs.grid.gridApi.refreshCells();
        },
        loadData() {
            this.loading = true;
            this.$axios
                .get(this.$models.getUrl('unknownStudent', 'read') + `&upload_id=${this.$props.uploadId}&page=${this.pagination.page}&limit=${this.pagination.limit}`)
                .then(response => {
                    this.items = response.data.students
                    this.pagination.total = response.data.totalCount
                    this.items.forEach(rec => {
                        rec.new_student = null
                    })
                    this.originalItems = this.$_.cloneDeep(this.items)
                    this.$emit('count', this.pagination.total)
                })
                .finally(() => {
                    this.loading = false
                    this.sanitize()
                })
        },
        loadStudents() {
            let me = this
            let id = this.$props.uploadId;
            this.originalItems = [];
            this.items = [];

            this.loading = true;
            this.$axios
                .get("students.php?action=get&property=student")
                .then(response => {
                    this.students = response.data;

                    this.columns = [
                        me.$grid.checkColumn(),
                    {
                        headerName: "Assessment Group",
                        field: "data_point_type_name",
                        sortable: true,
                        width: 80,
                        flex: 1,
                        cellRenderer: params => {
                            return params.data.data_point_type_id
                                ? params.data.data_point_type_id
                                : "Global";
                        }
                    }, {
                        headerName: "Info",
                        width: 50,
                        field: 'row_color',
                        tooltipValueGetter: meta => {
                            if (
                                meta.data.quick_match === true &&
                                meta.data.row_color == "green-row"
                            ) {
                                return "Student names and date of births match.";
                            } else if (
                                meta.data.quick_match === true &&
                                meta.data.row_color == "yellow-row"
                            ) {
                                return "Student names match but date of births are different or not provided";
                            } else if (meta.data.row_color === "red-row") {
                                return "Multiple matches found for the same student information.";
                            }
                        },
                        cellRenderer(meta) {
                            if (
                                meta.data.quick_match === true &&
                                meta.data.row_color == "green-row"
                            ) {
                                return '<i class="fas fa-check" style="color: green; font-size: 16px;"/>';
                            } else if (
                                meta.data.quick_match === true &&
                                meta.data.row_color == "yellow-row"
                            ) {
                                return '<i class="fas fa-question" style="color: orange; font-size: 16px;"/>';
                            } else if (meta.data.row_color === "red-row") {
                                return '<i class="fas fa-exclamation" style="color: red; font-size: 16px;"/>';
                            }
                        },
                        cellStyle: {
                            textAlign: "center"
                        }
                    }, {
                        headerName: "Import ID",
                        field: "unknown_student_id",
                        sortable: true,
                        width: 80
                    }, {
                        headerName: "Import Student",
                        field: "student_full_name",
                        sortable: true,
                        autoHeight: true,
                        cellRenderer(meta) {
                            return ("<div>" + meta.data.student_full_name +
                                "<div>Date of Birth: " + (meta.data.date_of_birth ? meta.data.date_of_birth: "Not Provided") +"</div>" +
                                "</div>"
                            );
                        },
                        flex: 1
                    }, {
                        headerName: "Matched Student",
                        field: "matched_student_id",
                        editable: true,
                        flex: 1,
                        tooltipValueGetter: param => {
                            let d = param.node.data;
                            if (d.row_color == "red-row") {
                                return "There are multiple students with this name.  eduCLIMBER cannot guarantee they are the same student";
                            } else if (d.row_color == "green-row") {
                                return "This student will also be known as the selected student status in eduCLIMBER";
                            }
                            return "Select a student to match this student to";
                        },
                        cellRenderer: v => me.findStudent(v),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "matched_student_id",
                            mode: "edit",
                            items: me.$data.students,
                            keyProp: "id",
                            labelProp: "student_full_name",
                            disableChips: true
                        }
                    }, {
                        headerName: "Matched IDs",
                        flex: 1,
                        autoHeight: true,
                        cellStyle: {
                            color: "gray"
                        },
                        field: "matched_student_ids",
                        cellRenderer(meta) {
                            let arr = meta.data.matched_student_ids || []
                            return arr.map(rec => '<div>'+rec.unique_id+'</div>').join('')
                        }
                    }, {
                        headerName: "View Matched",
                        cellStyle: {
                            textAlign: "center"
                        },
                        cellRenderer: meta => {
                            if (meta.data.matched_student_list?.length > 0 || meta.data.matched_student_id) {
                                return '<i class="fas fa-users" style="color: #404040; font-size: 16px; cursor: pointer;"/>';
                            }
                            return ''
                        }
                    }]

                    // populate student lookup for speed
                    this.students.forEach(u => {
                        u.student_full_name = u.lname+', '+u.fname
                        this.lookup[u.id] = u;
                    });

                    this.$refs.crud.read()
                        .then(() => {
                            this.items.forEach(rec => {
                                rec.new_student = null;
                                // rec.matched_student_id = null
                            });
                            this.originalItems = this.$_.cloneDeep(this.items)
                            this.$emit('count', this.originalItems.length)
                        })
                        .finally(() => {
                            this.loading = false;
                            this.sanitize();
                        });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
