export default {
    chart: {
        type: 'column',
    },
    credits: {
        enabled: false
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    title: {
        enabled: false,
        text: ''
    },
    exporting: {
        filename: '',
        csv: {
            columnHeaderFormatter: function(item, key) {
                if (item.isXAxis) {
                    return item.options.title
                } else {
                    return item.name
                }
            }
        }
    },
    xAxis: {
        categories: [],
        title: {
            text: undefined
        },
    },
    yAxis: {
        title: {
            text: 'Chronic Absence Rate (%)'
        },
        labels: {
            formatter: function() {
                return new Intl.NumberFormat().format(this.value) + ' %'
            }
        }
    },
    legend: {
        enabled: false,
        align: 'center',
        symbolRadius: 0
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: '',
                style: { color: '#495b8f' },
                formatter: function() {
                    return this.y + '%'
                }
            },
            groupPadding: 0.25,
            pointPadding: 0.15,
        }
    },
    series: []
}
