<template>

    <div class="d-flex flex-column flex-fill" style="padding: 26px;">
        <div class="d-flex pt-5 px-5 pb-1" style="padding-bottom: 24px !important; padding-left: 0 !important">
            <div>
                <fe-icon-btn
                    useIcon="fas fa-chevron-left"
                    usage="ghost"
                    class="menu-btn"
                    @click="goBack"
                >

                </fe-icon-btn>
            </div>
            <span style="font-size: 24px; padding-right: 10px; align-self: center">Chronic Absence</span>
            <fe-info-tip
                color="#495B8F !important"
                icon="fal fa-info-circle"
                size="18px"
                style="align-self: center;"
                tooltip="copy to come"
            >
            </fe-info-tip>
        </div>

        <div v-if="ready">
            <div v-if="prefs.sisReportedConfigured" >
                <div class="d-flex" style="padding-left: 4px !important; padding-bottom: 12px !important;">
                    <fe-filter-btn
                        v-model="filters.school_id"
                        ref="ca-school-filter"
                        rootProperty="schools"
                        title="Schools"
                        :url="schoolUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('school')"
                        @apply="apply('ca-school-filter')"
                        data-test="ca-school-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.grade_id"
                        ref="ca-grade-filter"
                        rootProperty="grades"
                        title="Grade Level"
                        :url="gradeUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('grade')"
                        @apply="apply('ca-grade-filter')"
                        data-test="ca-grade-level-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.ethnicity"
                        ref="ca-ethnicity-filter"
                        rootProperty="ethnicities"
                        itemValue="ethnicity"
                        itemText="display_name"
                        title="Race/Ethnicity"
                        :url="ethnicityUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('ethnicity')"
                        @apply="apply('ca-ethnicity-filter')"
                        data-test="ca-ethnicity-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.gender"
                        ref="ca-gender-filter"
                        rootProperty="genders"
                        itemValue="gender"
                        itemText="display_name"
                        title="Gender"
                        :url="genderUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('gender')"
                        @apply="apply('ca-gender-filter')"
                        data-test="ca-gender-filter-btn"
                    />
                    <more-filters ref="ca-more-filters" v-model="demoFilters" @apply="applyMore"></more-filters>
                </div>

                <div>
                    <fe-tabs
                        class="ca-tabs"
                        id="ca-page-tabs"
                        :tabs="tabs"
                        @tabClicked="tabClicked"
                    />
                </div>

                <div
                    v-if="activeTab.show === 'ethnicity'"
                    style="padding-left: 15px;"
                    class="remote-dem-combo"
                >
                    <div style="padding-top: 8px">
                        <div style="float: left" class="center-demo-combo-title">Explore by:</div>
                        <fe-remote-combo
                            v-model="selectedDemographic"
                            :items="demographicOptions"
                            :clearable="false"
                            hide-details
                            @change="chartParamChange('demographic', $event)"
                            class="demographic-select mb-0"
                            data-test="ca-demo-explore-by-btn"
                        />
                    </div>
                </div>

                <v-container
                    v-if="activeTab.show === tab.show"
                    v-for="(tab, index) in tabs"
                    fluid
                    class="chart-area"
                    :key="`tab-${index}`"
                >
                    <chronic-absence-rate-chart
                        ref="ca-rate-chart"
                        :data="tab"
                        :year="selectedTimePeriod"
                        :ready="tabData[tab.show].loaded"
                        :error="tabData[tab.show].error"
                        :noFilterResults="noFilterResults"
                        :toggle="countsToggle"
                        @change="chartParamChange"
                        @clearAllFilters="clearAllFilters"
                    />
                </v-container>
            </div>

            <div v-else>
                <restricted/>
            </div>
        </div>

        <fe-crud ref="prefsCrud" :config="prefsCrudConfig" @read="prefsFetched" autoload/>
    </div>

</template>

<script>
import { mapState } from "vuex"
import MoreFilters from "@/components/modules/enrollmentReporting/MoreFilters"
import ChronicAbsenceRateChart from "../../charts/ChronicAbsenceRateChart.vue"
import baseConfig from "./baseConfig"
import { singleSeriesPattern, twoSeriesPatterns, fiveSeriesPatterns } from "../../charts/chartFormats/patterns/seriesPatterns"
import NoFilterResults from '../../charts/emptyStateMessaging/NoFilterResults.vue'
import Restricted from "./messaging/Restricted.vue"


export default {
    name: 'ChronicAbsenceReporting',

    components: {
        MoreFilters,
        ChronicAbsenceRateChart,
        NoFilterResults,
        Restricted
    },

    data() {
        return {
            prefs: {},
            activeTab: {
                name: 'Overall',
                show: 'district',
                path: '#'
            },
            selectedTimePeriod: {
                name: 'Last 2 Years (Monthly)',
                id: '2M'
            },
            tabData: {
                district: {data: undefined, info: undefined, loaded: false, lastLoadedTimePeriod: undefined, error: false},
                school: {data: undefined, info: undefined, loaded: false, lastLoadedTimePeriod: undefined, error: false},
                grade: {data: undefined, info: undefined, loaded: false, lastLoadedTimePeriod: undefined, error: false},
                ethnicity: {data: undefined, info: undefined, loaded: false, lastLoadedTimePeriod: undefined, error: false},
                school_group: {data: undefined, info: undefined, loaded: false, lastLoadedTimePeriod: undefined, error: false},
            },
            selectedDemographic: 'ethnicity',
            demographicOptions: [{
                name: 'Race/Ethnicity',
                id: 'ethnicity'
            }, {
                name: 'Gender',
                id: 'gender'
            }, {
                name: 'Meal Status',
                id: 'meal_status'
            }, {
                name: 'Disability',
                id: 'disability'
            }, {
                name: 'ELP Level',
                id: 'ell_level'
            }],
            filters: {
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                gender: undefined
            },
            demoFilters: {},
            countsToggle: true,
            useDefaultToggle: true,
            ready: false,
            rateDescriptor: '',
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'districtPreferences']),

        tabs() {
            return [{
                name: 'Overall',
                show: 'district',
                config: this.districtConfig,
                path: '#',
            },
            {
                name: 'By School',
                show: 'school',
                config: this.schoolConfig,
                path: '#',
            },
            {
                name: 'By Grade Level',
                show: 'grade',
                config: this.gradeConfig,
                path: '#',
            },
            {
                name: 'By Race/Ethnicity',
                show: 'ethnicity',
                config: this.demographicConfig,
                path: '#',
            },
            {
                name: 'By School Group',
                show: 'school_group',
                config: this.schoolGroupConfig,
                path: '#'
            }
        ]
        },

        prefsCrudConfig() {
            let cfg = _.cloneDeep(this.$models.preferences)
            cfg.read.params.property = 'district'
            return cfg
        },

        schoolUrl() {
            return this.$models.getUrl('school', 'read')+'&school_year_id='+this.currentYear.id
        },

        gradeUrl() {
            return this.$models.getUrl('grade', 'read')+'&school_year_id='+this.currentYear.id
        },

        ethnicityUrl() {
            return this.$models.getUrl('ethnicityType', 'read')
        },

        genderUrl() {
            return this.$models.getUrl('genderType', 'read')
        },

        districtConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig())

            if (this.tabData.district.data && this.activeTab.show === 'district' && this.tabData.district.loaded) {
                let data = this.tabData.district.data
                const grouped = {}

                data.forEach(d => {
                    if (!grouped[`${d.start_date}-${d.end_date}`]) grouped[`${d.start_date}-${d.end_date}`] = []
                    grouped[`${d.start_date}-${d.end_date}`].push(d)
                })

                this.rateDescriptor = data[0].hasOwnProperty('month_name') ? 'Month' : 'Year'

                if (this.rateDescriptor === 'Year') {
                    caConfig.series = {
                        data : Object.keys(grouped).map(key => {
                            return {
                                y: grouped[key][0].chronic_absence_rate,
                                name: key
                            }
                        })
                    }
                    caConfig.legend.enabled = false
                    caConfig.xAxis.categories = Object.keys(grouped)
                } else {
                    caConfig.series = Object.keys(grouped).map(key => {
                        return {
                            name: key,
                            data: grouped[key].map(d => d.chronic_absence_rate)
                        }
                    })
                    caConfig.xAxis.categories = [...new Set(data.map(d => d.month_name))]
                    this.setAxisLabels(grouped, caConfig, this.tabData.district.info)
                }

                const seriesData = this.rateDescriptor === 'Year' ? caConfig.series.data : caConfig.series
                this.setSeriesPattern(seriesData)
                this.setExportingParams(caConfig, 'district')
                this.tabData.district.loaded = true
            }
            this.setDataLabelToggle()
            return caConfig
        },

        schoolConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig())
            this.rateDescriptor = null

            if (this.tabData.school.data && this.activeTab.show === 'school' && this.tabData.school.loaded) {
                let grouped = {}
                let data = this.tabData.school.data

                data.forEach(d => {
                    if (!grouped[`${d.start_date}-${d.end_date}`]) grouped[`${d.start_date}-${d.end_date}`] = []
                    grouped[`${d.start_date}-${d.end_date}`].push(d)
                })

                caConfig.xAxis.categories = Object.keys(grouped)
                caConfig.series = Object.keys(grouped).map(key => {
                    return {
                        name: key,
                        data: grouped[key].map(d => { return {y: d.chronic_absence_rate, name: d.school} })
                    }
                })
                caConfig.xAxis.categories = [...new Set(data.map(d => d.school))]
                this.setAxisLabels(grouped, caConfig, this.tabData.school.info)
                this.setSeriesPattern(caConfig.series)
                this.setExportingParams(caConfig, 'School')
                this.tabData.school.loaded = true
            }
            this.setDataLabelToggle()
            return caConfig
        },

        gradeConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig())
            this.rateDescriptor = null

            if (this.tabData.grade.data && this.activeTab.show === 'grade' && this.tabData.grade.loaded) {
                let grouped = {}
                let data = this.tabData.grade.data

                data.forEach(d => {
                    if (!grouped[`${d.start_date}-${d.end_date}`]) grouped[`${d.start_date}-${d.end_date}`] = []
                    grouped[`${d.start_date}-${d.end_date}`].push(d)
                })

                caConfig.xAxis.categories = Object.keys(grouped)
                caConfig.series = Object.keys(grouped).map(key => {
                    return {
                        name: key,
                        data: grouped[key].map(d => { return {y: d.chronic_absence_rate, name: d.grade} })
                    }
                })
                caConfig.xAxis.categories = [...new Set(data.map(d => d.grade))]
                this.setAxisLabels(grouped, caConfig, this.tabData.grade.info)
                this.setSeriesPattern(caConfig.series)
                this.setExportingParams(caConfig, 'Grade')
                this.tabData.grade.loaded = true
            }
            this.setDataLabelToggle()
            return caConfig
        },

        demographicConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig())
            this.rateDescriptor = null

            if (this.tabData.ethnicity.data && this.activeTab.show === 'ethnicity' && this.tabData.ethnicity.loaded) {
                let grouped = {}
                let data = this.tabData.ethnicity.data

                data.forEach(d => {
                    if (!grouped[`${d.start_date}-${d.end_date}`]) grouped[`${d.start_date}-${d.end_date}`] = []
                    grouped[`${d.start_date}-${d.end_date}`].push(d)
                })

                caConfig.xAxis.categories = Object.keys(grouped)
                caConfig.series = Object.keys(grouped).map(key => {
                    return {
                        name: key,
                        data: grouped[key].map(d => { return {y: d.chronic_absence_rate, name: d[this.selectedDemographic]} })
                    }
                })
                caConfig.xAxis.categories = [...new Set(data.map(d => d[this.selectedDemographic]))]
                this.setAxisLabels(grouped, caConfig, this.tabData.ethnicity.info)
                this.setSeriesPattern(caConfig.series)
                this.setExportingParams(caConfig, this.demographicOptions.find(d => d.id === this.selectedDemographic).name)
                this.tabData.ethnicity.loaded = true
            }
            this.setDataLabelToggle()
            return caConfig
        },

        schoolGroupConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig())
            this.rateDescriptor = null

            if (this.tabData.school_group.data && this.activeTab.show === 'school_group' && this.tabData.school_group.loaded) {
                let grouped = {}
                let data = this.tabData.school_group.data

                data.forEach(d => {
                    if (!grouped[`${d.start_date}-${d.end_date}`]) grouped[`${d.start_date}-${d.end_date}`] = []
                    grouped[`${d.start_date}-${d.end_date}`].push(d)
                })

                caConfig.xAxis.categories = Object.keys(grouped)
                caConfig.series = Object.keys(grouped).map(key => {
                    return {
                        name: key,
                        data: grouped[key].map(d => { return {y: d.chronic_absence_rate, name: d.school_group} })
                    }
                })
                caConfig.xAxis.categories = [...new Set(data.map(d => d.school_group))]
                this.setAxisLabels(grouped, caConfig, this.tabData.school_group.info)
                this.setSeriesPattern(caConfig.series)
                this.setExportingParams(caConfig, 'School Group')
                this.tabData.school_group.loaded = true
            }
            this.setDataLabelToggle()
            return caConfig
        },

        filterEmpty() {
            return Object.values(this.filters).every(x => x === null || x === '' || x === undefined || x.included.length === 0)
        },

        demoFilterEmpty() {
            return this.$_.isArray(this.demoFilters) ? this.demoFilters.length === 0 : Object.keys(this.demoFilters).length === 0
        },

        noFilterResults() {
            let filterNum = this.filterEmpty ? 0 : Object.values(this.filters)
                .filter(x => x !== undefined)
                .map(filter => filter.included.length)
                .reduce((a, b) => a + b, 0)

            let totalFilters = filterNum + (this.demoFilterEmpty ? 0 : this.$_isArray(this.demoFilters) ? this.demoFilters.length : Object.keys(this.demoFilters).length)
            let currentTabCount = this.tabData[this.activeTab.show].data?.length ? this.tabData[this.activeTab.show].data[0].chronic_absence_rate : 0

            return (totalFilters !== 0 && (currentTabCount === 0 || currentTabCount === null))
        }
    },

    mounted() {
        this.tabData.district.lastLoadedTimePeriod = this.selectedTimePeriod
        this.loadChartData()
    },

    methods: {
        prefsFetched(v) {
            let prefs = {}
            v.forEach(pref => {
                prefs[pref.code] = pref.user_value !== "0"
            })
            this.prefs.sisReportedConfigured = prefs['ATTENDANCE_EQUIV_RATE']
            this.ready = true
        },

        goBack() {
            this.$router.push('/')
        },

        baseConfig() {
            let config = _.cloneDeep(baseConfig)
            config.plotOptions.column.dataLabels.enabled = this.countsToggle
            return config
        },

        loadChartData() {
            let url = this.$models.getUrl('chronicAbsenceTrends', 'read')
            let params = this.requestParams()

            this.$axios.get(url+'&'+this.$objectToParams(params)).then(response => {
                if (response.request.status !== 200 || response.data.trend.length === 0) {
                    this.tabData[this.activeTab.show] = {data: undefined, info: undefined, lastLoadedTimePeriod: this.selectedTimePeriod.id, loaded: true, error: true}
                } else {
                    this.tabData[this.activeTab.show] = {
                        data: response.data.trend,
                        info: response.data.info,
                        lastLoadedTimePeriod: this.selectedTimePeriod.id,
                        error: false,
                        loaded: true
                    }
                }
            })
        },

        requestParams() {
            let obj = {
                selected_time_period: this.selectedTimePeriod.id
            }
            if (this.activeTab.show !== 'district') obj['group_by'] = this.activeTab.show
            if (this.activeTab.show === 'ethnicity') obj['group_by'] = this.selectedDemographic
            return obj
        },

        clearFilter(filter) {
        },

        clearAllFilters() {
        },

        apply() {
            this.tabData[this.activeTab.show] = {data: undefined, loaded: false, lastLoadedTimePeriod: undefined, info: undefined, error: false}
            this.loadChartData()
        },

        applyMore() {

        },

        tabClicked(tab) {
            this.tabData[this.activeTab.show].loaded = false
            this.tabData[tab.show].loaded = false
            this.activeTab = tab
            if (tab.show !== 'district' && this.selectedTimePeriod.id.includes('M')) {
                const transferId = this.selectedTimePeriod.id[0]
                const newTimePeriod = {name: `Last ${transferId} Years (Annual)`, id: `${transferId}Y`}
                this.chartParamChange('timePeriod', newTimePeriod, false)
            }
            else if (tab.show === 'district' && this.selectedTimePeriod.id === '1Y') {
                this.chartParamChange('timePeriod', {name: 'This Year (Monthly)', id: '1M'}, false)
            } else {
                this.loadChartData()
            }
        },

        chartParamChange(type, value, toggleUsed) {
            switch(type) {
                case 'timePeriod':
                    this.selectedTimePeriod = value
                    this.loadChartData()
                    break;
                case 'countToggle':
                    this.useDefaultToggle = !toggleUsed
                    this.countsToggle = value
                    break;
                case 'demographic':
                    this.selectedDemographic = value.id
                    this.tabData.ethnicity = {data: undefined, loaded: false, error: false}
                    this.loadChartData()
                    break;
                default:
            }
        },

        setExportingParams(config, group) {
            let me = this
            let fileName = group === 'district' ? 'Chronic Absence Rate Overall' : `Chronic Absence Rate By ${group}`
            config.exporting.filename = fileName
            config.exporting.csv = {
                columnHeaderFormatter: function(item, key) {
                    if (item.isXAxis) {
                        return me.rateDescriptor ? me.rateDescriptor : group
                    } else {
                        return item.name.includes('Series') ? 'Rate (%)' : item.name
                    }
                }
            }
        },

        setAxisLabels(grouped, config, info) {
            if (this.tabData[this.activeTab.show].data !== undefined) {
                if (Object.keys(grouped).length > 1) {
                    config.legend.enabled = true
                } else {
                    config.legend.enabled = false
                    config.xAxis.title.text = `${info.start_date} - ${info.end_date}`
                    config.xAxis.title.offset = 70
                }
            }
        },

        setSeriesPattern(series) {
            let patterns =  series.length === 1 ? singleSeriesPattern : series.length === 2 ? twoSeriesPatterns : fiveSeriesPatterns
            series.forEach((s, index) => {
                s.color = patterns[index]
                if (patterns[index].patternIndex) s.color.borderColor = '#8085e9'
            })
        },

        setDataLabelToggle() {
            let toggleOffOptions = (this.selectedTimePeriod.id === '5M') || (this.selectedTimePeriod.id === '5Y' && this.activeTab.show !== 'district')
            if (this.useDefaultToggle && toggleOffOptions) this.chartParamChange('countToggle', false, false)
            else if (this.useDefaultToggle) this.chartParamChange('countToggle', true, false)
        }
    }
}
</script>

<style lang="scss" scoped>

.ca-tabs {
    padding-left: 18px;
    height: 70px !important;

    ::v-deep .fe-tabs-default {
        padding-left: 0 !important
    }
    ::v-deep .fe-tabs a {
        padding-bottom: 15px;
    }
    ::v-deep .fe-tabs .v-tabs-slider {
        width: 100% !important;
        left: 0 !important;
    }
    ::v-deep .fe-tabs-default .v-tab {
        font-size: 14px;
    }
    ::v-deep .fe-tabs-default-color a {
        color: #495B8F !important;
        font-weight: 500 !important;

        &:hover {
            span {
                opacity: 1 !important;
            }
        }
    }
    ::v-deep .fe-tabs-default .v-tab:hover {
        background-color: #ECEDF1;
    }
    ::v-deep .fe-tabs-default .v-tab--active {
        color: #006C90 !important;
    }
    ::v-deep .fe-tabs-default .v-tab--active:hover {
        background-color: #E5F0F4;
    }
}

.center-demo-combo-title {
    font-weight: bold;
    font-size: 16px;
    padding-top: 8px;
    padding-right: 8px;
}

.demographic-select {
    max-width: 20%
}
</style>

