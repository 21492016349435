<template>
    <fe-card
        class="alt-card ma-3"
        :height="cardHeight"
        :style='cardStyle'
        :thumbnail="thumbnail ? thumbnail : null"
    >
        <template v-slot:title-text>
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px">
                {{ widgetTitle }}
            </span>
        </template>

        <template v-slot:title-toolbar>
            <slot name="widget-toolbar"/>
        </template>

        <template v-slot:body>
            <div >
                <slot name="widget-body"/>
            </div>
        </template>
    </fe-card>
</template>

<script>
export default {
    name: 'StandardWidget',
    props: {
        widgetTitle: {
            type: String,
            required: true
        },
        cardHeight: {
            type: String,
            default: '300px'
        },
        thumbnail: {
            type: Object,
            default: null
        },
        cardStyle: {
            type: String,
            default: null
        }
    },
}
</script>
