<template>
    <div class="flex-fill flex-column pa-4">
        <v-carousel
            v-model="activePage"
            height="100%"
            hide-delimiters light
            :show-arrows="false"
        >
            <v-carousel-item key="0">
                <v-container style="height: 100%;">
                    <v-row style="height: 100%;">
                        <v-col cols="6">
                            <fe-grid
                                ref="grid"
                                :columnDefs="columnDefsSingleColumn"
                                :rowData="studentScores"
                                :showDensityToolbar="false"
                                :showSaveAsDefault="false"
                                :showBorderToggle="false"
                                :showFullscreen="false"
                                :showToolbar="false"
                                disable-inline-create
                                :style="{
                                    height: '100%',
                                    width: '100%'
                                }"
                                :emptyState="{
                                    text: 'No student scores found with the current selections.',
                                    imageSrc: '/images/empty-state-horn.png',
                                    imageStyle: 'max-width:50%; max-height:50%;',
                                }"
                                @firstDataRendered="adjustGrid"
                                @gridReady="onGridReady"
                            />
                        </v-col>
                        <v-col cols="6" style="height: 100%; overflow: auto;">
                            <fe-spinner v-if="isLoading" text="Loading..." />
                            <basic-information v-else
                                :model="localModel"
                                :windowItems="windowData"
                                @update="localModel.update($event)"
                                @validate="isBasicInformationValid = $event"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>

            <v-carousel-item key="1">
                <v-container style="height: 100%;">
                    <v-row style="height: 100%;">
                        <v-col cols="6">
                            <fe-grid
                                ref="grid2"
                                :columnDefs="columnDefsTwoColumns"
                                :rowData="studentScores"
                                :showDensityToolbar="false"
                                :showSaveAsDefault="false"
                                :showBorderToggle="false"
                                :showFullscreen="false"
                                :showToolbar="false"
                                disable-inline-create
                                :style="{
                                    height: '100%',
                                    width: '100%'
                                }"
                                :emptyState="{
                                    text: 'No student scores found with the current selections.',
                                    imageSrc: '/images/empty-state-horn.png',
                                    imageStyle: 'max-width:50%; max-height:50%;',
                                }"
                                @firstDataRendered="adjustGrid"
                                @gridReady="onGridReady2"
                            />
                        </v-col>
                        <v-col cols="6">
                            <goals
                                :dataPointTypeId="localModel.plan.data_point_type_id"
                                :targetSetId="localModel.target_set_id || (descriptorItems && descriptorItems[0].target_set_id)"
                                :goals="localModel.goals"
                                :selectedGoalTypeId="selectedGoalTypeId"
                                :canCreateTargetAchievementGoals="assessmentDescriptors.length > 0"
                                @changeGoalType="selectedGoalTypeId = $event"
                                @update="localModel.update($event)"
                                @updateAt="onUpdateAt"
                                @validate="isGoalsValid = $event"
                                :averageScore="averageScore"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>

            <v-carousel-item key="2">
                <v-container style="height: 100%;">
                    <v-row style="height: 100%;">
                        <v-col cols="6">
                            <fe-grid
                                ref="grid3"
                                :columnDefs="columnDefsTwoColumns"
                                :rowData="studentScores"
                                :showDensityToolbar="false"
                                :showSaveAsDefault="false"
                                :showBorderToggle="false"
                                :showFullscreen="false"
                                :showToolbar="false"
                                disable-inline-create
                                :style="{
                                    height: '100%',
                                    width: '100%'
                                }"
                                :emptyState="{
                                    text: 'No student scores found with the current selections.',
                                    imageSrc: '/images/empty-state-horn.png',
                                    imageStyle: 'max-width:50%; max-height:50%;',
                                }"
                                @firstDataRendered="adjustGrid"
                                @gridReady="onGridReady2"
                            />
                        </v-col>
                        <v-col cols="6" style="height: 100%;">
                            <v-container style="height: 100%;">
                                <v-row style="height: 100%;">
                                    <v-col cols="12" style="height: 100%;">
                                        <strategies
                                            :slo="localModel"
                                            :strategies="localModel.strategies"
                                            :dryRun="localModel.id === null"
                                            @created="onCreateStrategy"
                                            @updated="onUpdateStrategy"
                                            @deleted="onDeleteStrategy"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>

            <v-carousel-item key="3">
                <v-container style="height: 100%;">
                    <v-row style="height: 100%;">
                        <v-col cols="6">
                            <fe-grid
                                ref="grid2"
                                :columnDefs="columnDefsTwoColumns"
                                :rowData="studentScores"
                                :showDensityToolbar="false"
                                :showSaveAsDefault="false"
                                :showBorderToggle="false"
                                :showFullscreen="false"
                                :showToolbar="false"
                                disable-inline-create
                                :style="{
                                    height: '100%',
                                    width: '100%'
                                }"
                                :emptyState="{
                                    text: 'No student scores found with the current selections.',
                                    imageSrc: '/images/empty-state-horn.png',
                                    imageStyle: 'max-width:50%; max-height:50%;',
                                }"
                                @firstDataRendered="adjustGrid"
                                @gridReady="onGridReady2"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-container style="height: 100%;">
                                <v-row style="height: 100%;">
                                    <v-col cols="12">
                                        <logistics
                                            :slo="localModel"
                                            :logistics="localModel.logistics"
                                            :dryRun="localModel.id === null"
                                            @created="onCreateLogistic"
                                            @updated="onUpdateLogistic"
                                            @deleted="onDeleteLogistic"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>

            <v-carousel-item key="4">
                <congratulations
                    :isNewSLO="isNewSLO"
                    :id="localModel.id"
                    :name="localModel.slo_name"
                    :parentWindowId="parentWindowId"
                    @close="$emit('close')"
                />
            </v-carousel-item>
        </v-carousel>

        <div class="d-flex">
            <fe-btn v-if="activePage > 0 && activePage < 4" usage="tertiary" @click="activePage -= 1">Back</fe-btn>
            <v-spacer/>
            <fe-btn v-if="localModel.id && activePage === 0 && isBasicInformationValid" @click="saveAndConclude" usage="secondary">Save</fe-btn>
            <fe-btn v-if="activePage > 0 && isGoalsValid && activePage < 3" @click="saveAndConclude" usage="secondary">Save</fe-btn>

            <fe-btn v-if="(localModel.id && activePage === 0) || activePage === 1" :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)" @click="saveAndNext">
                Save and Next
            </fe-btn>
            <fe-btn v-else-if="activePage < 3" :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)" @click="activePage += 1">
                Next
            </fe-btn>
            <fe-btn v-else-if="activePage === 3" :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)" @click="onGoToCongratulationsScreen">
                Save
            </fe-btn>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import windowOptionsMixin from '@/mixins/windowOptions'
    import Strategies from '@/components/modules/slo/creation/datawall/Strategies'
    import BasicInformation from '@/components/modules/slo/creation/datawall/BasicInformation'
    import Logistics from '@/components/modules/slo/creation/datawall/Logistics'
    import Goals from '@/components/modules/slo/creation/datawall/Goals'
    import Congratulations from '@/components/modules/slo/creation/datawall/Congratulations'

    class Generic {
        constructor() {
        }

        update(props) {
            props = props || {}

            for (let prop in props) {
                if (this.hasOwnProperty(prop)) {
                    this[prop] = props[prop]
                }
            }
        }
    }

    class SLO extends Generic {
        constructor(props) {
            super()

            this.id = null
            this.slo_name = null
            this.slo_approval_user_id = null
            this.slo_start_date = null
            this.slo_end_date = null
            this.slo_monitor_date = null
            this.slo_user_user_id = []
            this.students = []
            this.slo_goal_type_id = 5
            this.target_set_id = null

            this.plan = new Plan()

            this.goals = [
                new AverageScoreGrowthGoal()
            ]
            this.strategies = []
            this.logistics = []

            this.update(props)
        }

        update(props) {
            props = props || {}

            for (let prop in props) {
                if (prop == 'plan') {
                    if (!this.plan) {
                        this.plan = new Plan(props[prop])
                    } else {
                        this.plan.update(props[prop])
                    }
                } else if (prop == 'goals') {
                    let me = this
                    this.goals = props.goals.map(itm => {
                        if (me.slo_goal_type_id === 2 || props.slo_goal_type_id === 2) {
                            return new TargetGrowthGoal(itm)
                        } else {
                            return new AverageScoreGrowthGoal(itm)
                        }
                    })
                } else if (prop == 'strategies') {
                    this.strategies = props.strategies || []
                } else if (prop == 'logistics') {
                    this.logistics = props.logistics || []
                } else {
                    super.update({ [prop]: props[prop] })
                }
            }
        }
    }

    class Plan extends Generic {
        constructor(props) {
            super()

            this.slo_plan_id = null
            this.data_point_id = null
            this.data_point_type_id = null
            this.school_year_id = null
            this.data_point_name_id = null
            this.end_data_point_name_id = null
            this.rationale_text = null
            this.statement_text = null
            this.sub_category_id = null

            this.group_by = null
            this.category_id = null
            this.school_id = null
            this.grade_id = null

            this.update(props)
        }
    }

    class TargetGrowthGoal extends Generic {
        constructor(props) {
            super()

            this.slo_goal_type_id = 2
            this.target_descriptor_id = null
            this.total = null
            this.baseline = 0
            this.goal = null
            this.goal_target_descriptor_id = null

            this.update(props)
        }

        update(props) {
            props = { ...props }

            // backend is returning goal and baseline as STRINGS, so we
            // have to convert them - otherwise the UPDATE call will fail
            // because then dr. hyde comes out and demands NUMBERS
            if (props.baseline && typeof(props.baseline) == 'string') {
                props.baseline = parseFloat(props.baseline)
            }
            if (props.goal && typeof(props.goal) == 'string') {
                props.goal = parseFloat(props.goal)
            }

            super.update(props)
        }
    }

    class AverageScoreGrowthGoal extends Generic {
        constructor(props) {
            super()

            this.slo_goal_type_id = 5
            this.baseline = null
            this.goal = null
            this.goal_target_descriptor_id = null

            this.update(props)
        }

        update(props) {
            props = { ...props }

            // backend is returning goal and baseline as STRINGS, so we
            // have to convert them - otherwise the UPDATE call will fail
            // because then dr. hyde comes out and demands NUMBERS
            if (props.baseline && typeof(props.baseline) == 'string') {
                props.baseline = parseFloat(props.baseline)
            }
            if (props.goal && typeof(props.goal) == 'string') {
                props.goal = parseFloat(props.goal)
            }

            super.update(props)
        }
    }

    class Strategy extends Generic {
        constructor(props) {
            super()

            this.id = null
            this.dryRunId = null // for strategies created during a new SLo that don't yet have a PK id but need some unique id
            this.strategy_text = null     // both strategy_text and slo_strategy_text are used
            this.slo_strategy_text = null // interchangeably in various backend gets/posts (it's not consistent)
            this.slo_strategy_users = []
            this.one_time_date = null
            this.start_date = null
            this.strategy_end_date = null
            this.start_time = null
            this.end_time = null
            this.schedule_frequency_cd = null // DAY for single or WEEK for recurring
            this.schedule_frequency_cnt = null
            this.schedule_week_days = null
            this.schedule_start_time = null
            this.schedule_end_time = null

            this.update(props)
        }

        update(props) {
            props = props || {}

            // Migrate existing records data (which returns from backend with slightly
            // different properties) into Strategy model props
            if (!props.strategy_text && (props.text || props.slo_strategy_text)) {
                props.strategy_text = props.text || props.slo_strategy_text
            }

            if (props.schedule_frequency_cd === null && props.schedule_start_date) {
                props.one_time_date = props.schedule_start_date
            }
            else if (props.schedule_frequency_cd !== null && props.schedule_start_date) {
                props.start_date = props.schedule_start_date
                props.strategy_end_date = props.schedule_end_date
            }

            if (!props.schedule_id && props.slo_plan_schedule_id) {
                props.schedule_id = props.slo_plan_schedule_id
            }

            if (!props.start_time && props.schedule_start_time) {
                props.start_time = props.schedule_start_time
            }
            if (!props.end_time && props.schedule_end_time) {
                props.end_time = props.schedule_end_time
            }

            // expecting strategiesmodal to always only send start_time and end time
            if (props.schedule_frequency_cd == 'WEEK' && !props.schedule_start_time && props.start_time) {
                props.schedule_start_time = props.start_time
            }
            if (props.schedule_frequency_cd == 'WEEK' && !props.schedule_end_time && props.end_time) {
                props.schedule_end_time = props.end_time
            }

            if (!props.slo_strategy_users && props.slo_strategy_user_ids) {
                props.slo_strategy_users = props.slo_strategy_user_ids
            }

            // props have been migrated as needed - update as usual
            super.update(props)
        }
    }

    class Logistic extends Generic {
        constructor(props) {
            super()

            this.id = null
            this.dryRunId = null // for strategies created during a new SLo that don't yet have a PK id but need some unique id
            this.slo_logistic_type_id = null
            this.description = null
            this.cost = null
            this.slo_logistic_users = []

            this.update(props)
        }

        update(props) {
            props = props || {}

            // Migrate existing records data (which returns from backend with slightly
            // different properties) into Strategy model props
            // Backend returns existing logistics with users in an { id, name } object array
            // but we only want to provide a simple id
            if (props.slo_logistic_users instanceof Array && props.slo_logistic_users.length && props.slo_logistic_users[0] instanceof Object) {
                props.slo_logistic_users = props.slo_logistic_users.map(itm => itm.user_id)
            }

            super.update(props)
        }
    }

    export default {
        name: 'SloCreationDatawall',
        mixins: [ windowOptionsMixin ],
        components: {
            BasicInformation,
            Strategies,
            Logistics,
            Goals,
            Congratulations,
        },
        props: {
            id: { type: Number, required: false },
            parentWindowId: { type: Number, required: false }, // dockedWindowId of any window that may have launched this component
            raw: { type: Object, required: false },
            assessment: { type: Object, required: false },
            window: { type: Object, required: false },
            windowItems: { type: Array, required: false },
            descriptorItems: { type: Array, required: false }, // only on first creation, passed from data wall
            studentIds: { type: Array, required: false }
        },
        data() {
            return {
                isLoading: true,
                isNewSLO: true, // mainly for tracking messaging (e.g. congratulations screen)

                gridApi: null,
                columnApi: null,

                activePage: 0, // carousel

                assessmentData: null, // primarily for name of the assessment SLO is created from
                assessmentDescriptors: [], // all possible target descriptors for the chosen assessment
                windowData: [], // window data for the assessment SLO is created from
                scoresData: [],

                isBasicInformationValid: false,
                isGoalsValid: false,

                selectedGoalTypeId: 5, // default to "Average Goal" on new SLO
                localModel: new SLO(),

                // these will be swapped in/out of localModel.goals when user
                // changes dropdown, so that dropdown switch does not "truncate" data entered for the other value
                targetGrowthGoals: null,
                averageScoreGrowthGoals: null,
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            columnDefsSingleColumn() {
                let me = this
                return [
                    {
                        headerName: "Student",
                        field: "student_full_name",
                        sortable: true,
                        editable: false,
                        cellStyle(v) {
                            return {
                                fontWeight: v.data.isBold ? "bold" : undefined,
                            }
                        }
                    }, {
                        //headerName: this.assessment.columns[0].full_name.replace("<br>", "\n"),
                        headerName: `${this.assessmentData?.name}\n${this.getWindowName(this.localModel.plan.data_point_name_id)}`,//this.assessment.tooltip.replace("<br>", "\n") + "\n" + this.window.displayText,
                        headerComponentParams: {
                            template: `
                                <div class="ag-cell-label-container" role="presentation">
                                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                                        <span style="flex:1;"></span>
                                        <div ref="eText" class="ag-header-cell-text" role="columnheader" style="text-align: center;"></div>
                                        <span style="flex:1;"></span>
                                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                                    </div>
                                </div>
                            `
                        },
                        field: "data_point_score",
                        tooltip(v) {
                            return v.data.currentAchievementTooltip
                        },
                        sortable: true,
                        editable: false,
                        cellStyle(v) {
                            return {
                                textAlign: "center",
                                //backgroundColor: v.data.isBold ? undefined : "#9D4039",
                                backgroundColor: v.data.isBold ? undefined : v.data.currentAchievementBackgroundColor,
                                color: v.data.isBold ? undefined : me.$calculateForegroundColor(v.data.currentAchievementBackgroundColor),
                                fontWeight: v.data.isBold ? "bold" : undefined,
                            }
                        }
                    }
                ]
            },
            columnDefsTwoColumns() {
                let me = this
                return [
                    {
                        headerName: "Student",
                        field: "student_full_name",
                        sortable: true,
                        editable: false,
                        cellStyle(v) {
                            return {
                                fontWeight: v.data.isBold ? "bold" : undefined,
                            }
                        }
                    }, {
                        //headerName: this.assessment.columns[0].full_name.replace("<br>", "\n"),
                        //headerName: this.assessment.tooltip.replace("<br>", "\n") + "\n" + this.window.displayText,
                        headerName: `${this.assessmentData?.name}\n${this.getWindowName(this.localModel.plan.data_point_name_id)}`,
                        headerComponentParams: {
                            template: `
                                <div class="ag-cell-label-container" role="presentation">
                                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                                        <span style="flex:1;"></span>
                                        <div ref="eText" class="ag-header-cell-text" role="columnheader" style="text-align: center;"></div>
                                        <span style="flex:1;"></span>
                                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                                    </div>
                                </div>
                            `
                        },
                        field: "data_point_score",
                        tooltip(v) {
                            return v.data.currentAchievementTooltip
                        },
                        sortable: true,
                        editable: false,
                        cellStyle(v) {
                            return {
                                textAlign: "center",
                                //backgroundColor: v.data.isBold ? undefined : "#9D4039",
                                backgroundColor: v.data.isBold ? undefined : v.data.currentAchievementBackgroundColor,
                                //color: v.data.isBold ? undefined : "#eee",
                                color: v.data.isBold ? undefined : me.$calculateForegroundColor(v.data.currentAchievementBackgroundColor),
                                fontWeight: v.data.isBold ? "bold" : undefined,
                            }
                        }
                    }, {
                        //headerName: this.assessment.columns[0].full_name.replace("<br>", "\n"),
                        //headerName: this.assessment.tooltip.replace("<br>", "\n") + "\n" + this.window.displayText,
                        headerName: `${this.assessmentData?.name}\n${this.getWindowName(this.localModel.plan.end_data_point_name_id)}`,
                        headerComponentParams: {
                            template: `
                                <div class="ag-cell-label-container" role="presentation">
                                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                                        <span style="flex:1;"></span>
                                        <div ref="eText" class="ag-header-cell-text" role="columnheader" style="text-align: center;"></div>
                                        <span style="flex:1;"></span>
                                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                                    </div>
                                </div>
                            `
                        },
                        field: "data_point_score",
                        tooltip(v) {
                            return v.data.targetAchievementTooltip
                        },
                        sortable: true,
                        editable: false,
                        cellStyle(v) {
                            return {
                                textAlign: "center",
                                backgroundColor: v.data.isBold ? undefined : v.data.targetAchievementOutcomeBackgroundColor,
                                color: v.data.isBold ? undefined : "#eee",
                                fontWeight: v.data.isBold ? "bold" : undefined,
                            }
                        },
                        cellRenderer(v) {
                            return "" // third column never shows anything - just has a flat color, if anything
                        },
                    }
                ]
            },
            studentScores() {
                const findDescriptorColor = (targetDescriptorId) => {
                    let descriptor = this.assessmentDescriptors.find(ad => ad.id === targetDescriptorId)
                    return descriptor?.color || 'transparent'
                }

                const findDescriptorName = (targetDescriptorId) => {
                    let descriptor = this.assessmentDescriptors.find(ad => ad.id === targetDescriptorId)
                    return descriptor?.name || 'Beginning'
                }

                let results = []
                const target_descriptor_id = 0
                //let filtered = this.raw.students.filter(itm => this.studentIds.includes(itm.student_id))
                let targetDescriptorIds = this.$_.uniq(this.scoresData.filter(sd => !!sd.data_point_score_details).map(sd => sd.data_point_score_details[target_descriptor_id]))
                for (let targetDescriptorId of targetDescriptorIds) {
                    let targetGrowthGoal = this.targetGrowthGoals?.find(tgg => tgg.target_descriptor_id == targetDescriptorId)
                    let matchingStudents = this.scoresData.filter(itm => itm.data_point_score_details?.[target_descriptor_id] === targetDescriptorId)

                    results = [
                        ...results,
                        ...matchingStudents.map((itm, index) => ({
                            student_full_name: itm.student_full_name,
                            data_point_score: itm.data_point_score,
                            currentAchievementTooltip: findDescriptorName(itm.data_point_score_details?.[target_descriptor_id]),
                            currentAchievementBackgroundColor: findDescriptorColor(itm.data_point_score_details?.[target_descriptor_id]),
                            targetAchievementTooltip: this.localModel.slo_goal_type_id === 2 && targetGrowthGoal?.goal && targetGrowthGoal?.goal_target_descriptor_id
                                ? (Math.round(index / matchingStudents.length * 100) < targetGrowthGoal.goal ? findDescriptorName(targetGrowthGoal.goal_target_descriptor_id) : findDescriptorName(targetGrowthGoal.target_descriptor_id))
                                : undefined,
                            targetAchievementOutcomeBackgroundColor: this.localModel.slo_goal_type_id === 2 && targetGrowthGoal?.goal && targetGrowthGoal?.goal_target_descriptor_id
                                ? (Math.round(index / matchingStudents.length * 100) < targetGrowthGoal.goal ? findDescriptorColor(targetGrowthGoal.goal_target_descriptor_id) : findDescriptorColor(targetGrowthGoal.target_descriptor_id))
                                : undefined,
                        }))
                    ]
                }

                return results
            },
            averageScore() {
                let allScores = this.studentScores.filter(itm => itm.data_point_score !== "").map(itm => itm.data_point_score)
                return allScores.length ? (allScores.reduce((sum, arr) => sum + arr, 0) / allScores.length).toFixed(2) : null
            },
            footerData() {
                let result = [{
                    isBold: true,
                    student_full_name: "Average Score",
                    data_point_score: this.averageScore === null ? "-" : this.averageScore
                }]

                this.$nextTick(() => {
                    this.gridApi.setPinnedBottomRowData(result)
                })

                return result
            },
        },
        methods: {
            getWindowName(dataPointNameId) {
                let match = this.windowData?.find(itm => itm.id === dataPointNameId)
                return match?.name || 'N/A'
            },
            onChangeAssignee(value) {
                this.localModel.slo_user_user_id = value.map(itm => itm.id)
            },
            onChangeReviewer(value) {
                this.localModel.slo_approval_user_id = value.id
            },
            onChangeDateRange(e) {
                this.localModel.slo_start_date = e.dateRange?.start?.date
                this.localModel.slo_end_date = e.dateRange?.end?.date

                // backend expects yyyy-mm-dd
                if (this.localModel.slo_start_date) {
                    this.localModel.slo_start_date = this.$dayjs(this.localModel.slo_start_date).format("YYYY-MM-DD")
                }
                if (this.localModel.slo_end_date) {
                    this.localModel.slo_end_date = this.$dayjs(this.localModel.slo_end_date).format("YYYY-MM-DD")
                }
            },
            onGridReady(grid) {
                this.gridApi = grid.api
                this.columnApi = grid.columnApi

                this.$nextTick(() => {
                    grid.api.setPinnedBottomRowData(this.footerData)
                })
            },
            onGridReady2(grid) {
                //this.gridApi = grid.api
                //this.columnApi = grid.columnApi

                this.$nextTick(() => {
                    grid.api.setPinnedBottomRowData(this.footerData)
                    grid.api.sizeColumnsToFit()
                })
            },
            onGridReady3(grid) {
                this.$nextTick(() => {
                    grid.api.sizeColumnsToFit()
                })
            },
            adjustGrid (e) {
                this.$nextTick(() => this.$refs.grid.resize())
            },
            onUpdateAt(prop, index, props) {
                this.localModel[prop][index].update(props)
            },
            async onCreateStrategy(obj) {
                let id = obj.id
                let strategy = await this.$axios.get(
                    `${this.$models.getUrl('sloStrategy', 'read')}&id=${id}`
                ).then(res => res.data.slo_strategies[0])

                this.localModel.strategies.push(strategy)
            },
            async onUpdateStrategy(obj) {
                let id = obj.id
                let strategy = await this.$axios.get(
                    `${this.$models.getUrl('sloStrategy', 'read')}&id=${id}`
                ).then(res => res.data.slo_strategies[0])

                let index = this.localModel.strategies.findIndex(itm => itm.id === strategy.id)
                if (index >= 0) {
                    this.localModel.strategies.splice(index, 1, strategy)
                }
            },
            onDeleteStrategy(obj) {
                this.localModel.strategies = this.localModel.strategies.filter(itm => itm.id !== obj.id)
            },
            async onCreateLogistic(obj) {
                let id = obj.id
                let logistic = await this.$axios.get(
                    `${this.$models.getUrl('sloLogistic', 'read')}&id=${id}&detailed=true`
                ).then(res => res.data.logistics[0])

                this.localModel.logistics.push(logistic)
            },
            async onUpdateLogistic(obj) {
                let id = obj.id
                let logistic = await this.$axios.get(
                    `${this.$models.getUrl('sloLogistic', 'read')}&id=${id}&detailed=true`
                ).then(res => res.data.logistics[0])

                let index = this.localModel.logistics.findIndex(itm => itm.id === logistic.id)
                if (index >= 0) {
                    this.localModel.logistics.splice(index, 1, logistic)
                }
            },
            onDeleteLogistic(obj) {
                this.localModel.logistics = this.localModel.logistics.filter(itm => itm.id !== obj.id)
            },
            async onGoToCongratulationsScreen() {
                await this.doSave(false)
                this.activePage += 1
            },
            async doSave(goToEndOnComplete) {
                let result = await this.$axios.post(`slo.php?action=${this.localModel.id ? 'update' : 'create'}`, { slo: this.localModel }).then(res => res.data)

                this.localModel.update(result.slo[0])

                if (goToEndOnComplete) {
                    this.activePage = 4//this.$emit('close')
                }

                this.$emit('closeParent')
            },
            async saveAndNext() {
                await this.doSave()
                this.activePage += 1
            },
            saveAndConclude() {
                this.doSave(true)
            },
        },
        async mounted() {
            let sloData;
            if (this.id) {
                this.isNewSLO = false
                sloData = await this.$axios.get(`slo.php?action=get&id=${this.id}`).then(res => res.data)
                this.localModel = new SLO(sloData.slo)

                this.assessmentData = await this.$axios.get(`dataPointType.php?action=get&id=${this.localModel.plan.data_point_type_id}`).then(res => res.data[0])
                this.windowData = await this.$axios.get(`dataPointName.php?action=get&data_point_type_id=${this.localModel.plan.data_point_type_id}`).then(res => res.data.names)

                this.assessmentDescriptors = await this.$axios.get(
                    this.$models.getUrl('targetSetDescriptor', 'read') + `&target_set_id=${this.localModel.target_set_id}`
                ).then(res => res.data.descriptors)
            } else {
                this.assessmentData = await this.$axios.get(`dataPointType.php?action=get&id=${this.window.dataPointTypeId}`).then(res => res.data[0])
                this.windowData = await this.$axios.get(`dataPointName.php?action=get&data_point_type_id=${this.window.dataPointTypeId}`).then(res => res.data.names)

                // adds in group_by, school_year_id, category_id, school_id and grade_id
                // for brand new SLOs (i.e. from datawall data).  (existing SLO don't need this - the backend returns it)
                this.localModel.update({
                    slo_user_user_id: [this.sessionUser.user.id], // defaults to assigned to "me"
                    plan: {
                        ...this.raw?.params,
                        data_point_id: this.window.dataPointId,
                        data_point_type_id: this.window.dataPointTypeId,
                        data_point_name_id: this.window.dataPointNameId,
                        sub_category_id: this.window.sub_category_id
                    },
                    students: this.studentIds.map(itm => ({ student_id: itm }))
                })

                this.localModel.students = this.raw.students
                    .filter(itm => this.studentIds.includes(itm.student_id))
                    .map(itm => ({ student_id: itm.student_id }))

                // if no performance bands exist, then user can only create
                // an SLO with 'average" goals
                if (this.descriptorItems?.length) {
                    this.assessmentDescriptors = await this.$axios.get(
                        this.$models.getUrl('targetSetDescriptor', 'read') + `&target_set_id=${this.descriptorItems[0].target_set_id}`
                    ).then(res => res.data.descriptors)
                }
            }

            let targetScoresData;

            // For existing SLO we can pull in target scores data from a simple saved search
            if (this.id) {
                targetScoresData = await this.$axios.get(
                    `targetScores.php?property=datawall&action=get&saved_search_id=${sloData.slo.plan.saved_search_id}&student_id=${this.localModel.students.map(stu => stu.student_id).join(',')}&v=2`
                ).then(res => res.data.datawall)
            }

            // However, for new SLO there is no existing saved search (it only gets
            // created after new SLO is POSTed to backend), so we have to supply
            // individual params
            else {
                // from Grade search
                if (this.raw.params.school_id) {
                    targetScoresData = await this.$axios.get(
                        `targetScores.php?property=datawall&action=get&cohort_school_year_id=${this.raw.params.school_year_id}&cohort_school_id=${this.raw.params.school_id}&cohort_grade_id=${this.raw.params.grade_id}&school_year_id=${this.raw.params.school_year_id}&group_by=${this.raw.params.group_by}&v=2`
                    ).then(res => res.data.datawall)
                }

                // if no school_id param is given, this must be a Class search
                else {
                    targetScoresData = await this.$axios.get(
                        `targetScores.php?property=datawall&action=get&cohort_school_year_id=${this.raw.params.school_year_id}&cohort_school_id=${this.raw.params.school_id}&cohort_grade_id=${this.raw.params.grade_id}&school_year_id=${this.raw.params.school_year_id}&user_id=${this.raw.params.user_id}&course_id=${this.raw.params.course_id}&group_by=${this.raw.params.group_by}&student_active_flag=${this.raw.params.student_active_flag}&category_id=${this.raw.params.category_id}&v=2`
                    ).then(res => res.data.datawall)
                }
            }

            this.scoresData = this.localModel.students.map(stu => {
                let result = {
                    student_full_name: 'who am i?',
                    data_point_score: null,
                    data_point_score_details: null,
                }

                let student = targetScoresData.students.find(ts => ts.student_id === stu.student_id)
                if (student) {
                    result.student_full_name = student.student_full_name

                    let assessmentScore = student[`dp${this.localModel.plan.data_point_id}`]
                    if (assessmentScore || assessmentScore === 0) {
                        result.data_point_score = assessmentScore
                    }

                    let assessmentScoreDetails = student[`dp${this.localModel.plan.data_point_id}:Details`]
                    if (assessmentScoreDetails) {
                        result.data_point_score_details = assessmentScoreDetails
                    }
                }

                return result
            })


            // If SLO is created from datawall, selected target descriptors will be passed along
            // as props, but if it's being edited then we have to re-calculate which ones are used
            const target_descriptor_id = 0
            let targetDescriptorIds = this.$_.uniq(this.scoresData.filter(sd => !!sd.data_point_score_details).map(sd => sd.data_point_score_details[target_descriptor_id]))
            let activeTargetDescriptors = this.assessmentDescriptors.filter(ad => targetDescriptorIds.includes(ad.id))

            // If we are editing an existing SLO, ... ?
            if (this.id) {
                if (this.localModel.slo_goal_type_id === 2) {
                    // set a generic average growth goal so that user can
                    // successfully edit afterward to switch goal type
                    this.averageScoreGrowthGoals = [
                        new AverageScoreGrowthGoal({
                            baseline: parseFloat(this.averageScore)
                        })
                    ]

                    this.targetGrowthGoals = this.localModel.goals
                    this.selectedGoalTypeId = 2
                } else {
                    this.averageScoreGrowthGoals = this.localModel.goals

                    this.targetGrowthGoals = activeTargetDescriptors.map(itm => {
                        return new TargetGrowthGoal({
                            baseline: 0,
                            goal: null,
                            target_descriptor_id: itm.id
                        })
                    })
                }
            }

            // For a new SLO, set a gneric average goal (one goal in array) and
            // then set a generic default with goal of (e.g 80%) for each possible target descriptor
            else {
                this.averageScoreGrowthGoals = [
                    new AverageScoreGrowthGoal({
                        baseline: parseFloat(this.averageScore)
                    })
                ]

                this.targetGrowthGoals = activeTargetDescriptors.map(itm => {
                    return new TargetGrowthGoal({
                        baseline: 0,
                        goal: null,
                        target_descriptor_id: itm.id
                    })
                })
            }

            this.isLoading = false

            this.$nextTick(() => {
                if (this.averageScore !== null) {
                    this.averageScoreGrowthGoals[0].update({
                        baseline: parseFloat(this.averageScore),
                        goal: parseFloat(this.averageScore) + 1
                    })

                    // Don't overwrite goals when editing SLO - only force default
                    // values when creating a brand new one
                    if (!this.id) {
                        this.localModel.goals = this.averageScoreGrowthGoals
                    }

                    this.$nextTick(() => {
                        this.gridApi.setPinnedBottomRowData(this.footerData)
                    })
                }
            })
        },
        watch: {
            selectedGoalTypeId(value) {
                this.localModel.update({ slo_goal_type_id: value })

                if (value === 2) {
                    this.localModel.goals = this.targetGrowthGoals
                } else if (value === 5) {
                    this.localModel.goals = this.averageScoreGrowthGoals
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .col {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .container {
        max-width: 100%; /* override vuetify style that applies unwanted margins on smaller screen sizes */
    }
</style>
