import Index from '@/components/modules/attendanceReporting'
import StudentAttendance from '@/components/modules/attendanceReporting/StudentAttendance'
import ChronicAbsence from '@/components/modules/chronicabsenceReporting'

export default [{
    path: '/Attendance',
    name: 'Attendance Reporting',
    component: Index
}, {
    path: '/Attendance/Student/:studentId',
    name: 'Student Attendance',
    component: StudentAttendance,
    props: true
},
{
    path: '/ChronicAbsence',
    name: 'Chronic Absence Reporting',
    component: ChronicAbsence
}]
