<template>
    <explore-widget
        widgetTitle="Attendance"
        toolTip=""
        dataTestBaseString="attendance"
        :cardHeight="launchpadWidgetHeights.secondRow"
        @exploreClick="attendanceClicked"
        @printChart="printChart"
        @exportChart="exportChart"
    >
        <template v-slot:widget-body>
            <div>
                <attendance-chart
                    v-if="att.ready"
                    ref="launchpad-attendance-chart"
                    :config="att.config"
                    :chartParams="att.params"
                    :dataTestName="att.dataTestName"
                    :showInsight="false"
                    :pinnable="false"
                    :collectionChart="false"
                    :menu="false"
                    minimal
                    newLaunchPadEnabled
                    @chartClicked="attendanceClicked"
                />

                <div
                    v-else
                    style="cursor: pointer; vertical-align: middle; height: 280px;"
                    @click="attendanceClicked"
                    data-test="visualizations-attendance-search"
                >
                    <fe-empty-state  icon="fa-search" text="No data found.  Click to search"/>
                </div>
            </div>
        </template>
    </explore-widget>


</template>

<script>
import { mapState } from 'vuex'
import AttendanceChart from '@/components/charts/AttendanceChart'
import windowOptionsMixin from '@/mixins/windowOptions'
import ExploreWidget from './ExploreWidget.vue';

export default {
    name: 'VisualizationsCard',

    components: {
        AttendanceChart,
        ExploreWidget
    },

    mixins: [windowOptionsMixin],

    data() {
        return {
            tools: [],
            att: {
                ready: false,
                params: null,
                config: null
            },
            endDate: undefined,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool', 'launchpadWidgetHeights']),

        startDate() {
            if (!this.endDate) return undefined
            return this.$dayjs(this.endDate).subtract(14, 'd').format('YYYY-MM-DD')
        },

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        },

        attendanceChart() {
            return this.$refs['launchpad-attendance-chart'].$refs['attendanceChart']
        }
    },

    watch: {
        endDate(n, o) {
            if (o && n && n !== o) {
                this.loadAttendance()
            }
        },
    },

    mounted() {
        this.endDate = this.$dayjs().format('YYYY-MM-DD')
        this.loadAttendance()
    },

    methods: {
        loadAttendance() {
            let url = this.$models.getUrl('attendanceCharts', 'read');
            let p = {
                chart_type: ['month'],
                school_year_id: this.currentYear.id,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'charts')

                    data.forEach(chart => {
                        let chartCopy = this.$_.cloneDeep(chart)

                        chartCopy['y_fields'] = this.formatSeriesNames(chartCopy['y_fields'])
                        chartCopy.fields = this.formatSeriesNames(chartCopy.fields)
                        chartCopy.data.forEach(data => {
                            const existingAttendanceTypes = [{type: 'Full Day Attendance Rate', rename: 'Full Day'}, {type: 'Period Attendance Rate', rename: 'Period'}, {type: 'SIS Reported Attendance Rate', rename: 'SIS Reported'}]
                            existingAttendanceTypes.forEach(attType => {
                                if (data[attType.type] !== null && data[attType.type] !== undefined) this.renameKey(data, attType.type, attType.rename)
                            })
                        })

                        this.att.params = chartCopy.params
                        this.att.config = chartCopy

                        this.att.dataTestName = 'visualizations-attendance-chart'
                        if (chart.data.length > 0) this.att.ready = true
                    })
                })
        },

        formatSeriesNames(seriesArray) {
            let newArray = seriesArray.map(series => {
                return series.replace(' Attendance Rate', '')
            })
            return newArray
        },

        renameKey(obj, oldKey, newKey) {
            if (oldKey !== newKey) {
                Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey))
                delete obj[oldKey]
            }
            return obj
        },

        attendanceClicked() {
            this.$dockableWindow({
                name: 'Attendance Reporting',
                component: 'attendance-reporting',
                attrs: {
                    params: {
                        school_year_id: this.currentYear.id,
                        school_id: this.defaultSchool?.id,
                    },
                    dataTestParentName: 'launchapad'
                }
            })
        },

        printChart() {
            this.attendanceChart.printChart()
        },

        exportChart() {
            this.attendanceChart.exportChart({
                    type: 'image/png',
                    filename: 'Attendance Summary'
                })
        }

    }
}
</script>

<style lang="scss" scoped>
</style>
