<template>
    <div class="d-flex flex-fill flex-column no-scroll" style="height: 100%; width: 100%;">
        <fe-mask v-model="loading" :showLoader="true"/>

        <div class="d-flex mt-1">
            <div v-if="schoolYearSelector" class="d-flex flex-shrink-1">
                <template v-for="type in selectionTypes">
                    <fe-filter-btn
                        v-model="selections[type].filtered"
                        :ref="`filter_${type}`"
                        :key="`filter-${type}`"
                        :title="selections[type].title"
                        :items="selections[type].available"
                        :itemValue="selections[type].field.id"
                        :itemText="selections[type].field.name"
                        :multiple="!!selections[type].multiple"
                        :disabled="!selections[type].available.length"
                        :closeOnSelect="false"
                        actions
                        showFirstSelection
                    />
                </template>
            </div>

            <v-spacer/>

            <v-menu
                v-model="summaryShow"
                :close-on-content-click="false"
                nudge-left="400"
            >
                <template #activator="{ on: onMenu }">
                    <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                            <fe-icon-btn
                                v-on="{ ...onMenu, ...onTooltip }"
                                :useIcon="summaryShow ? 'fas fa-lightbulb' : 'far fa-lightbulb-on'"
                                data-test="launchpad-incidents-overlay-insights"
                            />
                        </template>

                        <span>Incident Insights</span>
                    </v-tooltip>
                </template>

                <v-card width="400">
                    <v-card-title>
                        Insight

                        <v-spacer/>

                        <fe-icon-btn useIcon="fas fa-times" @click="summaryShow = false"/>
                    </v-card-title>

                    <v-card-text>
                        <div v-html="summaryStatement"></div>
                    </v-card-text>
                </v-card>
            </v-menu>

            <toggle-button v-model="activeToggle" class="mt-2 mr-2" :items="toggleItems" @input="toggleClick"/>
        </div>

        <fe-overlay
            v-if="!yearIds.length"
            image="/images/rocket.png"
            header="Student Incidents"
            text="Select a school year from the filter above"
            color="white"
            :absolute="false"
        />

        <div
            v-else-if="activeToggle.show === 'charts'"
            class="d-flex flex-column flex-fill mb-10"
            style="overflow: scroll"
            :class="{'chart-window': !fixedTools}"
        >
            <div v-if="whatCharts.length" class="incident-section">What</div>

            <v-row>
                <v-col v-if="whatCharts.length" v-for="(chart, i) in whatCharts" :cols="chartCols + 1">
                    <big-five-chart
                        class="ma-2"
                        :config="chart"
                        :ref="`what-chart-` + i"
                        :collectionChart="collectionChart"
                        :appliedFilters="appliedFilters"
                        incidentChart
                        @chartClicked="handleClick(chart, ...arguments)"
                    />
                </v-col>
            </v-row>

            <div v-if="whenCharts.length" class="incident-section">When</div>
            <v-row>
                <v-col v-if="whenCharts.length" v-for="(chart, i) in whenCharts" :cols="chartCols + 1">
                    <big-five-chart
                        class="ma-2"
                        :config="chart"
                        :ref="`when-chart-` + i"
                        :collectionChart="collectionChart"
                        :appliedFilters="appliedFilters"
                        incidentChart
                        @chartClicked="handleClick(chart, ...arguments)"
                    />
                </v-col>
            </v-row>

            <div v-if="whereCharts.length" class="incident-section">Where</div>
            <v-row>
                <v-col v-if="whereCharts.length" v-for="(chart, i) in whereCharts" :cols="chartCols + 1">
                    <big-five-chart
                        class="ma-2"
                        :config="chart"
                        :ref="`where-chart-` + i"
                        :collectionChart="collectionChart"
                        :appliedFilters="appliedFilters"
                        incidentChart
                        @chartClicked="handleClick(chart, ...arguments)"
                    />
                </v-col>
            </v-row>

            <div v-if="whoCharts.length" class="incident-section">Who</div>
            <v-row>
                <v-col v-if="whoCharts.length" v-for="(chart, i) in whoCharts" :cols="chartCols + 1">
                    <big-five-chart
                        class="ma-2"
                        :config="chart"
                        :ref="`who-chart-` + i"
                        :collectionChart="collectionChart"
                        :appliedFilters="appliedFilters"
                        incidentChart
                        @chartClicked="handleClick(chart, ...arguments)"
                    />
                </v-col>
            </v-row>
        </div>

        <div
            v-else-if="activeToggle.show === 'list'"
            :class="{'grid-window': fixedTools, 'grid-window-2': !fixedTools}"
        >
            <incident-grid ref="list" :params="tableParams" :showAddRowBtn="false" title=""/>
        </div>

        <div v-else-if="activeToggle.show === 'datawall'" class="d-flex flex-column flex-fill">
            <data-wall :params="params"/>
        </div>

        <div class="incident-breadcrumb" v-if="breadcrumbs && activeToggle.show === 'charts'">
            <breadcrumb v-model="crumbs" @click="crumbClick"/>
        </div>

        <fe-crud
            v-if="schoolYearSelector"
            :config="$models.schoolYear"
            autoload
            @read="loadItems('schoolYears', $event)"
        />
    </div>
</template>

<script>
import IncidentGrid from './IncidentGrid'
import BigFiveChart from "../../charts/BigFive"
import Breadcrumb from '@/components/common/Breadcrumb'
import ToggleButton from '@/components/common/button/ToggleButton'
import DataWall from './DataWall'

export default {
    name: 'BigFive',

    components: {
        BigFiveChart,
        IncidentGrid,
        Breadcrumb,
        ToggleButton,
        DataWall
    },

    props: {
        params: {
            required: true
        },
        schoolYearSelector: {
            type: Boolean,
            default: false
        },
        allCharts: {
            type: Boolean,
            default: false
        },
        fixedTools: {
            type: Boolean,
            default: true
        },
        breadcrumbs: {
            type: Boolean,
            default: false
        },
        includeDataWall: {
            type: Boolean,
            default: false
        },
        collectionChart: {
            type: Boolean,
            default: false
        },
        fromIncidents: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            rawData: null,
            loading: false,
            summaryShow: false,
            view: 'charts',
            charts: [],
            crumbs: [],
            tableParams: {},
            toggleItems: [],
            activeToggle: {
                name: 'Charts',
                show: 'charts',
                icon: 'fas fa-chart-bar'
            },
            selections: {
                schoolYears: {
                    field: {id: 'id', name: 'name'},
                    title: 'School Years',
                    available: [],
                    filtered: {included: [], excluded: []},
                    pending: true,
                    multiple: true,
                    filterKey: 'school_year_id'
                }
            },
            appliedFilters: [],
        }
    },

    computed: {
        yearIds() {
            let paramYrs = Array.isArray(this.params.school_year_id)
                ? this.params.school_year_id
                : [this.params.school_year_id]
            return (this.schoolYearSelector)
                ? this.selections.schoolYears.filtered.included.map(x => x.id).filter(x => !!x)
                : paramYrs
        },

        selectionTypes() {
            return Object.keys(this.selections)
        },

        summaryStatement() {
            let stmt = {}
            if (!this.rawData || !this.rawData.charts) return 'Calculating'

            this.rawData.charts.forEach(chart => {
                let max = {
                    title: '',
                    key: '',
                    rec: null,
                    total: 0,
                    count: 0,
                    stmt: ''
                }
                chart.data.forEach(rec => {
                    if (!max.rec || rec.total_count > max.rec.total_count) {
                        max.rec = rec
                    }

                    max.total += rec.total_count
                    max.count++
                })

                if (chart.title.indexOf('Range') === -1) {
                    max.avg = max.total / max.count
                    max.avg = isNaN(max.avg) ? 0 : max.avg.toFixed(1)
                    max.title = chart.title.replace('By ', '').toLowerCase()
                    max.key = chart.x_field
                    stmt[max.title] = max
                }
            })

            let ibt = this.formatTitle(stmt['incident type']) || this.formatTitle(stmt['primary incident type'])
            let beh = this.formatTitle(stmt['incident code']) || this.formatTitle(stmt['primary incident code'])
            let month = this.formatTitle(stmt['month'])
            let hour = this.formatTitle(stmt['hour'])
            let day = this.formatTitle(stmt['weekday'])
            let resp = this.formatTitle(stmt['response']) || this.formatTitle(stmt['primary response'])

            let rtn = '<p style="line-height: 28px;"> '

            if (ibt && beh) {
                rtn += 'The most common incident type is ' + ibt
                rtn += ' with ' + beh + ' being the most commonly exhibited behavior.'

                if (resp) rtn += '  These incidents resulted in a response of ' + resp + '.'

                if (month && day && hour) {
                    rtn += '<br/><br/>These incidents occurred most frequently in ' + month
                    rtn += ', on ' + day
                    rtn += ' at ' + hour
                }
            } else {
                rtn += 'No Incidents Found'
            }

            return rtn + '</p>'
        },

        whatCharts() {
            let c = ['incident_behavior_type', 'incident_behavior', 'incident_response']
            return this.charts.filter((rec => {
                return c.indexOf(rec.chartType) > -1
            }))
        },

        whereCharts() {
            let c = ['incident_location', 'incident_school']
            return this.charts.filter((rec => {
                return c.indexOf(rec.chartType) > -1
            }))
        },

        whenCharts() {
            let c = ['incident_month', 'incident_weekday', 'incident_hour']
            return this.charts.filter((rec => {
                return c.indexOf(rec.chartType) > -1
            }))
        },

        whoCharts() {
            return ['student', 'student_school'].map(type => {
                return this.charts.find(itm => itm.chartType === type)
            }).filter(itm => !!itm)
        },

        chartCols() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 12
            }
            if (this.$vuetify.breakpoint.lgAndDown) {
                return 6
            }

            return 3
        },
    },

    watch: {
        params(v) {
            if (v) this.loadChartData()
        },

        yearIds(v) {
            if (v.length) this.loadChartData()
        }
    },

    mounted() {
        this.toggleItems = [{
            name: 'Charts',
            show: 'charts',
            icon: 'fas fa-chart-bar'
        }, {
            name: this.includeDataWall ? 'Data Wall' : 'Data List',
            show: this.includeDataWall ? 'datawall' : 'list',
            icon: this.includeDataWall ? 'fas fa-table' : 'fas fa-bars'
        }]

        this.crumbs = [{
            name: 'Incidents',
            params: this.params
        }]
        if (this.breadcrumbs) this.fixedTools = false
        this.loadChartData()
        // have to set tiny set timeout as btns don't appear straight away on mounted
        setTimeout(function() {
            let toggleBtnCharts = document.getElementById('toggle-btn-0') 
            let toggleBtnDataWall = document.getElementById('toggle-btn-1')
            toggleBtnCharts.setAttribute('data-test', 'launchpad-incidents-overlay-charts')
            toggleBtnDataWall.setAttribute('data-test', 'launchpad-incidents-overlay-data-list')
        }, 10)
    },

    methods: {
        crumbClick(crumb) {
            this.loadChartData(crumb.params)
        },

        toggleClick(v) {
            if (v.show === 'list') {}
        },

        formatTitle(group) {
            if (!group || !group.total) return
            return '<span style="color: var(--v-primary-base); font-weight: bold;">' +
                group.rec[group.key] + '</span>' +
                this.formatCounts(group)
        },

        formatCounts(group) {
            return ' <span style="font-size: 10px;">(Total: ' + group.rec.total_count + ' Average: ' + group.avg + ')</span>'
        },

        handleClick(chart, key, rec, evt, base) {
            let p = {...this.params}
            p[chart.config.param_key] = rec[chart.config.param_key]
            p.school_year_id = rec.school_year_id
            p.start_date = base.start_date || null

            if (!this.breadcrumbs) {
                this.$store.commit('global/addDockableWindow', {
                    name: 'Incident Drilldown',
                    component: 'incident-overview',
                    attrs: {
                        name: rec[key],
                        params: p,
                        allCharts: true,
                        fixedTools: false,
                        breadcrumbs: true
                    }
                })
            } else {
                this.crumbs.forEach(c => Object.assign(p, c.params))
                this.crumbs.push({
                    name: rec[key],
                    params: p
                })
                this.loadChartData(p)
            }
        },

        /** This doesn't seem to be used anywhere. There is a method in charts/BigFive that handles updating **/
        updateSingleChart(config, range, ref) {
            config.baseParams.start_date = range.value ? this.$dayjs().subtract(range.value, 'd').format('YYYY-MM-DD') : null

            let $ref = this.$refs[ref]
            let reloadParams = {...config.baseParams}
            reloadParams.chart_types = [config.config.chart_type]
            reloadParams.range = range

            let loader = this.$loading.show({
                'is-full-page': false,
                container: $ref.$el
            });

            this.$axios.get(this.$models.getUrl('studentIncidentChart', 'read') + '&' + this.$objectToParams(reloadParams))
                .then(response => {
                    let data = this.$ecResponse(response, 'incidents')
                    let chart = data.charts[0]
                    let categories = chart.fields ? chart.fields : []
                    let existingIndex = this.charts.findIndex(x => x.id === config.id)

                    if (existingIndex !== null) {
                        let series = this.buildSeries(categories, chart)

                        let newData = {
                            id: config.id,
                            title: chart.title,
                            chartType: chart.chart_type,
                            series: series,
                            categories: categories,
                            config: chart,
                            baseParams: reloadParams
                        }

                        this.charts.splice(existingIndex, 1, newData)
                    }
                    loader.hide()
                })
        },

        loadChartData(paramsIn) {
            if (!this.yearIds.length || this.loading) return
            this.loading = true
            let chartParams = {...paramsIn || this.params}
            chartParams.school_year_id = this.yearIds
            this.tableParams = {...chartParams}
            this.charts = []
            // if want to show primary only, then want to pass 0 to include_secondary_behaviors_responses
            // and vice versa - ONLY IF COMING FROM INCIDENT SEARCH DIRECTLY
            if(this.fromIncidents) {
                const primaryOnly = window.localStorage.getItem('ec-incidents-primary-only')
                const showPrimaryOnly = primaryOnly !== null ? primaryOnly : '1'
                chartParams.include_secondary_behaviors_responses = showPrimaryOnly === '1' ? 0 : 1 
            }

            const chartTypes = !this.allCharts ? [
                'incident_behavior_type',
                'incident_behavior',
                'incident_response',
                'incident_month',
                'incident_weekday',
                'incident_school',
                'incident_location',
                'student',
                'student_school',
                'incident_hour'
            ] : null

            const promises = chartTypes.map(chartType => {
                const params = {
                    ...chartParams,
                    chart_types: [chartType]
                }
                return this.$axios.get(
                    this.$models.getUrl('studentIncidentChart', 'read') + 
                    '&' + 
                    this.$objectToParams(params)
                )
            })

            Promise.all(promises)
                .then(responses => {
                    const combinedData = {
                        charts: []
                    }
                    
                    responses.forEach(response => {
                        const data = this.$ecResponse(response, 'incidents')
                        combinedData.charts = [...combinedData.charts, ...data.charts]
                    })
                    
                    this.rawData = combinedData
                    
                    combinedData.charts.forEach((chart, i) => {
                        let categories = chart.fields || []
                        this.charts.push({
                            id: i,
                            title: chart.title,
                            chartType: chart.chart_type,
                            x_field: chart.x_field,
                            categories: categories,
                            config: chart,
                            baseParams: chartParams
                        })
                    })
                })
                .finally(() => {
                    this.subtitleFilters()
                    this.loading = false
                })
        },

        loadItems(type, data) {
            let sels = []
            if (type == 'schoolYears') {
                let fltYrs = [this.params.school_year_id].flat()
                sels = data.filter(itm => fltYrs.includes(itm.id))
            }
            this.selections[type].available = data
            this.selections[type].pending = false
            if (sels.length) {
                this.$nextTick(() => {
                    if (this.$refs[`filter_${type}`]) {
                        sels.forEach(sel => {
                            this.$refs[`filter_${type}`][0].selectItem(sel, true)
                        })
                    }
                })
            }
        },

        subtitleFilters() {
            if (this.charts.length > 0) {
                let filters = this.charts[0].config.filters_applied // only need applied filters from one chart
                if (filters) {
                    this.appliedFilters = filters.split('|').filter(f => f !== '')
                } else {
                    this.appliedFilters = []
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.incident-section {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */
    padding-left: 24px;
    color: #152452;
}

.summary-title {
    color: red;
}

.summary-statement {
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
    background: var(--v-primary-lighten2);
}

.b5-tools {
    position: fixed;
    top: 112px;
    right: 28px;
}

.chart-window {
    height: calc(100% - 80px);
    overflow: scroll;
}

.grid-window {
    height: calc(100% - 20px);
}

.grid-window-2 {
    height: calc(100% - 80px);
}

.incident-breadcrumb {
    position: absolute;
    width: 98%;
    border-top: 1px solid #dddddd;
    bottom: 0;
    background: white;
    padding: 4px 8px 4px 16px;
}
</style>
