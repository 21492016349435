import ld from 'lodash'

// a grouping function should return
// {
//     type: // function name or property name
//     groups: {
//         'groupname1': {
//             name: 'Column 1 Label',
//             data: [...groupDataForColumnOne]
//         },
//         'groupname2': {
//             name: 'Column 2 Label',
//             data: [...groupDataForColumnOne]
//         }
//         ...
//     }
// }

export default {
    setGrouper(charts, grouper) {
        let group = this[grouper.type](charts, grouper.args, grouper.id)
        group.id = grouper.id
        group.grouper = grouper

        group.groups.sort((a,b) => {
            if(a.data.length > b.data.length) return -1
            if(a.data.length < b.data.length) return 1
            return 0
        })

        group.groups.forEach( x => Object.freeze(x.data))

        return group
    },

    none({filters,records}) {
        return {
            type: null,
            total: records.length,
            groups: [{
                name: 'All Students',
                total: records.length,
                data: Object.freeze([...records])
            }]
        }
    },

    // this controller can be called instead of a grouper to decide what grouping function can be used
    controller({filters,records},{property},filterId) {
        let filter = filters.find(x => x.id == filterId)
        if(filter.value_type == 'boolean') {
            return this.boolean(
                {filters:filters, records:records},
                {property:property, textProperty:property}
            )
        } else if(filter.params?.target_set_id) {
            return this.property(
                {filters:filters, records:records},
                {property:property, textProperty:property}
            )
        } else if(isNaN(ld.get(records[0],property))) {
            return this.property(
                {filters:filters, records:records},
                {property:property, textProperty:property}
            )
        } else {
            return this.oldEvenRange( {filters:filters, records:records}, {property:property} )
        }
    },

    boolean({filters,records}, {property, textProperty}) {
        textProperty = textProperty || property
        let groups = {}
        records.forEach(rec => {
            let group = ld.get(rec,property) || 'no'
            groups[group] = groups[group] || {
                name: ld.get(rec,textProperty) ? 'Yes' : 'No',
                data: []
            }
            groups[group].data.push(rec)
        })

        return {
            type: property,
            total: records.length,
            groups: Object.values(groups)
        }
    },

    // puts recs into groups based on a property on the student record.
    // property is in the form on 'school_id' or 'filters.#uniqueId.propertyname'
    property({filters,records}, {property, textProperty}) {
        textProperty = textProperty || property
        let groups = {}
        records.forEach(rec => {
            let group = ld.get(rec,property) || 'not_specified'
            groups[group] = groups[group] || {
                name: ld.get(rec,textProperty) || 'Not Specified',
                data: []
            }
            groups[group].data.push(rec)
        })

        return {
            type: property,
            total: records.length,
            groups: Object.values(groups)
        }
    },

    // puts recs into groups based on an array property on the student record.
    // path is in the form of 'filters.#uniqueId'
    // property 'property' or 'something.property' which comes after path
    // property 'textProperty' or 'something.textProperty' which comes after path
    propertyArray({filters,records}, {path, property, textProperty, emptyName}) {
        textProperty = textProperty || property
        let groups = {}

        // check for aliases if this is an option-type filter
        let filterId = path.substring(path.indexOf('.') + 1)
        let aliases = this.getDisplayNames(filterId, filters)

        records.forEach(rec => {

            // if aliases exist, cycle through student records
            // and change value and display_name to aliases
            if (rec.filters.length > 0) {
                let optionId = rec.filters[filterId][0].supporting.demographic_option_id
                if (aliases) {
                    for (const alias in aliases) {
                        if (aliases[alias].ids) {
                            let recAlias = aliases[alias].ids.find(a => a === optionId)
                            if (recAlias) {
                                rec.filters[filterId][0].extra_values.display_value = aliases[alias].value
                                rec.filters[filterId][0].value = aliases[alias].value
                            }
                        }
                    }
                }
            }

            let group = ld.get(rec,path)
            if(ld.isNil(group) || ld.isEmpty(group)) {
                groups.not_specified = groups.not_specified || {
                    name: emptyName || 'Not Specified',
                    data: {}
                }
                groups.not_specified.data[rec.student_id] = rec
            } else {
                [].concat(group).forEach(x => {
                    let groupName = ld.get(x,property)
                    let name = ld.get(x,textProperty)
                    groups[groupName] = groups[groupName] || {
                        name: name,
                        data: {}
                    }
                    groups[groupName].data[rec.student_id] = rec
                })
                if(group.length>1) {
                    groups.multiple_values = groups.multiple_values || {
                        name: 'Multiple Values',
                        data: {}
                    }
                    groups.multiple_values.data[rec.student_id] = rec
                }
            }
        })

        groups = Object.values(groups)
        groups.forEach(group => {
            group.data = Object.values(group.data)
        })

        return {
            type: property,
            total: records.length,
            groups: groups
        }
    },

    getDisplayNames(uid, filters) {
        // if this is an option-type filter
        // check for and return all aliases and ids
        let names = {}
        if (uid && filters) {
            filters.forEach(filter => {
                if (filter.id === uid && filter.params[uid]) {
                    names = filter.params[uid]
                }
            })
        }
        return names
    },

    // put recs into groups based on performance bands
    oldEvenRange({filters,records},{property}) {
        let filterGroups = []
        let totalRangeMax = 0
        let filterType = (filters[0] !== undefined && filters[0].hasOwnProperty('type') && !filters[0].definition.includes('days_missed')) ? filters[0].type : 'other'
        if (filterType === 'attendance') {
            filterGroups = [
                {
                    name: '0 - 79.99',
                    min: Number(0),
                    max: Number(79.99),
                    data: []
                },
                {
                    name: '80.00 - 89.99',
                    min: Number(80.00),
                    max: Number(89.99),
                    data: []
                },
                {
                    name: '90.00 - 100',
                    min: Number(90.00),
                    max: Number(100.00),
                    data: []
                }
            ]
            totalRangeMax = 100
        } else {
            const totalRange = records.reduce((acc, r) => {
                return {
                    min: Math.min( acc.min, ld.get(r,property) ),
                    max: Math.max( acc.max, ld.get(r,property) ),
                    uniqueVals: !acc.uniqueVals.includes( ld.get(r,property) )
                        ? [ ...acc.uniqueVals, ld.get(r,property) ]
                        : acc.uniqueVals
                }
            }, {
                min: ld.get(records[0],property),
                max: ld.get(records[0],property),
                uniqueVals: []
            })

            let groupCount = records.length < 32
                ? Math.floor(records.length / 4)
                : 8

            groupCount = groupCount > totalRange.uniqueVals.length - 1
                ? totalRange.uniqueVals.length - 1
                : groupCount

            if(!groupCount) groupCount = 1

            const groupRange = (totalRange.max - totalRange.min) / groupCount

            filterGroups = Array.from( { length: groupCount }, (v, i) => i ).map(g => {
                const value = g * groupRange + totalRange.min
                let min = Number(value)
                let max = Number(value + groupRange)
                return {
                    name: `${min.toFixed(2)} - ${max.toFixed(2)}`,
                    min: min,
                    max: max,
                    data: []
                }
            })
            totalRangeMax = totalRange.max
        }

        records.forEach( record => {
            let value = Number( ld.get(record,property) )
            let matchingGroup = filterGroups.find( x => {
                return value >= x.min && value < x.max
            })
            if(!matchingGroup) {
                if(value === totalRangeMax) {
                    filterGroups[filterGroups.length-1].data.push(record)
                } else {
                    console.warn('🚩🚩oldEvenRange Error: no matching group for value ',value,filterGroups)
                    debugger
                }
            } else {
                matchingGroup.data.push(record)
            }
        })

        return {
            type: 'oldEvenRange',
            total: records.length,
            groups: Object.values(filterGroups)
        }
    }
}
