<template>
    <div style="width: 100%; height: 100%;" class="guardian-emails-grid">
        <div class="no-sent-data" v-if="apiFailed">
            <img src="../../../../public/images/guardian-sent-api-failed.png"/>
            <h5>No data available</h5>
            <p>We are experiencing technical difficulties. Refresh the page or try again later.</p>
            <fe-btn
                data-test="refresh-btn"
                usage="primary"
                @click="refresh"
            >
                Refresh
            </fe-btn>
        </div>
        <div class="no-sent-data" v-else-if="!rowData.length && guardianReportingV2Display && !isFiltering">
                <img src="../../../../public/images/no-guardians.png"/>
                <h5>No guardian emails sent</h5>
                <p>There were no guardian emails sent during this time period.</p>
                <fe-btn
                    data-test="send-guardian-email-info-btn"
                    usage="primary"
                    @click="goToSendGuardianEmailDoc"
                >
                <i class="fal fa-external-link mr-2"></i>
                Learn How to Email Guardians
                </fe-btn>
        </div>
        <fe-grid
            v-else-if="guardianReportingV2Display"
            class="guardian-fe-grid"
            :showToolbar="true"
            style="height: 500px;"
            :columnDefs="columnDefsV2"
            :rowData="rowData"
            :showAddRowBtn="false"
            @cellClicked="onCellClicked"
        >
            <template #after-search-toolbar-items>
                <date-range-filter
                    :date-options="displayDateOptions"
                    :filters="filters"
                    :calendar-style="{ left: '198px' }"
                    @date-selected="onFilterDate"
                    @calendar-input="updateSelectedDateRange"
                    @apply-date-range="applyCustomDateRange"
                    ref="dateRangeFilter"
                />
                <fe-filter-btn
                    data-test="template-filter-btn"
                    class="moveup"
                    title="Form Template"
                    :items="processedTemplates"
                    itemText="templateName"
                    itemValue="templateId"
                    :value="filters.templates"
                    @input="onFilterTemplate"
                    multiple
                    :closeOnSelect="false"
                />
            </template>
        </fe-grid>
        <fe-grid
            v-else
            class="guardian-fe-grid"
            :showToolbar="false"
            style="height: 500px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellClicked="onCellClicked"
        />
    </div>
</template>

<script>
import { useCalendarDateRange, updateSelectedDateRange } from '@/components/common/util/FunctionalCalendarUtil'
import DateRangeFilter from '@/components/common/DateRangeFilter'

export default {
    components: {
        DateRangeFilter,
    },
    computed: {
        guardianReportingV2Display() {
            return this.$store.getters['flags/flags']['ec-guardian-reporting-v-2']
        },
        processedDateOptions() {
            return this.displayDateOptions.map((option, index) => ({
                ...option,
                id: index.toString(),
            }))
        },
        processedTemplates() {
            return this.templateOptions.map((template, index) => ({
                ...template,
                templateId: template.templateId,
            }))
        },
        isFiltering() {
            return this.filters.templates.included.length > 0 || this.filters.dates.included.length > 0
        },
        calendarDateRange() {
            return useCalendarDateRange(this.selectedDateRange)
        },
        displayDateOptions() {
            return [...this.dateOptions, { label: 'Custom', value: 'custom' }]
        },
    },
    data() {
        return {
            columnDefs: [
                { headerName: 'Last Sent', field: 'lastSent', flex: 0.5, minWidth: 10, sortable: true, sort: 'desc', sortOrder: 1, valueFormatter: v => this.$dayjs(v.data.lastSent).format('M/D/YY'), },
                { headerName: 'Sent By', field: 'sentBy', flex: 1, sortable: true },
                { headerName: 'Template', field: 'template', flex: 1, sortable: true },
                {
                    headerName: 'Download Response Report',
                    headerTooltip: "Download a CSV report to view a list of student guardians, the delivery status, and whether a response has been received.",
                    field: 'downloadResponseReport',
                    cellRenderer: v => {
                        return '<div style="color: #006C90"><i class="fas fa-arrow-to-bottom"></i> Download Report</div>'
                    },
                    flex: 1,
                    headerComponentParams: {
                        template: '<div>Download Response Report <i style="margin-left: 4px color: #495B8F" class="fas fa-info-circle"></i></div>',
                    },
                    sortable: true,
                },
            ],
            columnDefsV2: [
                { headerName: 'Last Sent', field: 'lastSent', maxWidth: 200, minWidth: 100, sortable: true, sort: 'desc', sortOrder: 1, valueFormatter: v => this.$dayjs(v.data.lastSent).format('M/D/YY'), },
                { headerName: 'Sent By', field: 'sentBy', maxWidth: 420, minWidth: 130, sortable: true },
                { headerName: 'Template', field: 'templateName', minWidth: 300, maxWidth: 840, sortable: true },
                { 
                    headerName: 'Guardians',
                    field: 'totalSent', minWidth: 50,
                    maxWidth: 140,
                    sortable: true,
                    cellRenderer: this.cellRendererWithNavigation.bind(this, 0),
                    cellStyle: { textAlign: 'right' },
                    headerClass: 'ec-cs-light ag-right-aligned-header',
                },
                { 
                    headerName: 'Delivered',
                    field: 'delivered',
                    minWidth: 50,
                    maxWidth: 140,
                    sortable: true,
                    cellStyle: { textAlign: 'right' },
                    headerClass: 'ec-cs-light ag-right-aligned-header',
                },
                {
                    headerName: 'Not Received',
                    field: 'undeliverable',
                    minWidth: 60,
                    maxWidth: 140,
                    sortable: true,
                    cellRenderer: this.cellRendererWithNavigation.bind(this, 1),
                    cellStyle: { textAlign: 'right' },
                    headerClass: 'ec-cs-light ag-right-aligned-header',
                },
                {
                    headerName: 'Response',
                    field: 'responseReceived',
                    minWidth: 60,
                    maxWidth: 240,
                    sortable: true,
                    headerClass: 'ec-cs-light ag-right-aligned-header',
                    valueGetter: (params) => {
                        const responseReceived = params.data.responseReceived
                        const responseRequired = parseInt(params.data.responseRequired, 10)

                        // Generate a fraction and percentage representation of the response rate
                        const percentage = responseRequired > 0 ? Math.round((responseReceived / responseRequired) * 100) : 0
                        return `${responseReceived}/${responseRequired} (${percentage}%)`
                    },
                    cellRenderer: this.cellRendererWithNavigation.bind(this, 2),
                    cellStyle: { textAlign: 'right' }
                },
                {
                    headerName: '',
                    flex: 1,
                    minWidth: 100,
                    maxWidth: 130,
                    cellRenderer: v => {
                        return '<div @onClick=cellRendererWithNavigation(params) data-test="view-guardian-reporting" style="color: #006C90">View Details <i class="far fa-chevron-right" style="margin-left: 8px"></i></div>'
                    },
                },
            ],
            rowData: [],
            templateOptions: [],
            selectedTemplate: null,
            filters: {
                templates: {
                    included: [],
                },
                dates: {
                    included: [],
                },
            },
            dateOptions: [
                { label: 'Last 7 Days', value: { startDate: this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
                { label: 'Last 30 Days', value: { startDate: this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
                { label: 'Last 90 Days', value: { startDate: this.$dayjs().subtract(90, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
            ],
            apiFailed: false,
            showCustomDatePicker: false,
            dateMenuOpen: false,
            selectedDateRange: {
                dateRange: {
                    start: null,
                    end: null
                },
            },
        }
    },
    mounted() {
        if(this.guardianReportingV2Display) {
            this.$modelGet('guardianFormEmailsV2')
            .then(response => {
                this.rowData = response.sent_list
                this.templateOptions = response.sent_list.map(item => ({
                    templateName: item.templateName,
                    templateId: item.templateId
                }))

                // Add this and last school year date filtering if data is available
                const schoolYearDates = response.school_year_dates
                if(schoolYearDates.this_year_start_date) {
                    this.dateOptions.push(
                        { label: 'This School Year', value: { startDate: schoolYearDates.this_year_start_date, endDate: schoolYearDates.this_year_end_date ? schoolYearDates.this_year_end_date : null } },
                    )

                    this.filters.dates = {
                        included: [{ label: 'This School Year', value: { startDate: schoolYearDates.this_year_start_date, endDate: schoolYearDates.this_year_end_date ? schoolYearDates.this_year_end_date : null }}]
                    }
                }
                if(schoolYearDates.last_year_start_date && schoolYearDates.last_year_end_date) {
                    this.dateOptions.push(
                        { label: 'Last School Year', value: { startDate: schoolYearDates.last_year_start_date, endDate: schoolYearDates.last_year_end_date } }
                    )
                }
            })
            .catch(err => {
                console.warn('Unable to load guardian form email setup', err)
                this.apiFailed = true
            })
        } else {
            this.$modelGet('guardianFormEmails')
            .then(response => this.rowData = response.sent_list)
            .catch(err => console.warn('Unable to load guardian form email setup', err))
        }
    },
    methods: {
        onCellClicked(params) {
            if (params.colDef.field === 'downloadResponseReport') {
                const batchId = params.data.batchId
                this.$axios.get(`/api/core/guardianformemail/csv/${batchId}`)
                .then(response => {
                    // Download the CSV file
                    const csvData = new Blob([response.data], { type: 'text/csvcharset=utf-8' })
                    const link = document.createElement('a')
                    const url = URL.createObjectURL(csvData)
                    link.setAttribute('href', url)
                    link.setAttribute('download', 'student-document.csv')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
                .catch(err => console.warn('Unable to download report', err))
            } else {
                const activeTab = this.getActiveTab(params.colDef.field)
                this.navigateToGuardianEmailReporting(params, activeTab)
            }
        },
        onFilterTemplate(v) {
            this.filters.templates = v
            this.loadData()
        },
        onFilterDate(v) {
            if (v.value === 'custom') {
                this.showCustomDatePicker = true
                return
            }
            
            this.filters.dates = {
                included: [v]
            }
            this.loadData()
            this.closeCalendar()
        },
        loadData() {
            const templateIds = this.filters.templates.included.map(x => x.templateId).join(',')
            const dateFilters = this.filters.dates.included.map(x => x.value)
            const startDate = dateFilters.length ? dateFilters[0].startDate : null
            const endDate = dateFilters.length ? dateFilters[0].endDate : null
            this.$modelGet('guardianFormEmailsV2', { templateIds: templateIds, startDate: startDate, endDate: endDate })
                .then(response => {
                    this.rowData = response.sent_list
                })
                .catch(err => console.warn('Unable to load guardian form email setup', err))
        },
        goToSendGuardianEmailDoc() {
            window.open('https://educlimber.illuminateed.com/hc/en-us/articles/29500861860123-Guardian-smartFORM-Emails', '_blank')
        },
        refresh() {
            this.apiFailed = false
            window.location.reload()
        },
        navigateToGuardianEmailReporting(params, activeTab = 0) {
            this.closeCalendar()
            const dateFilter = this.filters.dates.included.length ? this.filters.dates.included[0] : ''
            this.$router.push({
                name: 'Guardian Email Reporting',
                query: {
                    templateId: params.data.templateId,
                    templateName: params.data.templateName,
                    activeTab: activeTab,
                    dateFilter: JSON.stringify(dateFilter),
                }
            })
        },
        cellRendererWithNavigation(activeTab, params) {
            const value = params.value || params.data[params.colDef.field]
            return `<div style="cursor: pointer; color: #006C90;" data-test="view-reporting-from-grid" @click="navigateToGuardianEmailReporting(${params}, ${activeTab})">${value}</div>`
        },
        getActiveTab(field) {
            switch (field) {
                case 'undeliverable':
                    return 1
                case 'responseReceived':
                    return 2
                default:
                    return 0
            }
        },
        applyCustomDateRange() {
            const startDate = this.selectedDateRange.dateRange.start
            const endDate = this.selectedDateRange.dateRange.end
            
            if (startDate && endDate) {
                const dateLabel = startDate === endDate
                    ? this.$dayjs(startDate).format('M/D/YYYY')
                    : `${this.$dayjs(startDate).format('M/D/YYYY')} - ${this.$dayjs(endDate).format('M/D/YYYY')}`
                
                this.filters.dates = {
                    included: [{
                        label: dateLabel,
                        value: { startDate, endDate }
                    }]
                }
                
                this.showCustomDatePicker = false
                this.dateMenuOpen = false
                this.loadData()
            }
        },
        updateSelectedDateRange(event) {
            updateSelectedDateRange(this.selectedDateRange, event)
        },
        closeCalendar() {
            this.$refs.dateRangeFilter.closeMenu()
        },
    },
    beforeRouteLeave(to, from, next) {
        this.closeCalendar()
        next()
    },
}
</script>

<style scoped>
.no-sent-data {
    margin-top: 20px;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.guardian-emails-grid {
    height: 80vh !important; 
}

.guardian-emails-grid h5 {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
}

.guardian-fe-grid ::v-deep .ag-header-cell, 
.guardian-fe-grid ::v-deep .ag-cell {
    border-right: none !important;
}

.guardian-fe-grid {
    height: 100% !important;
}

.moveup ::v-deep button.v-btn {
    margin-top: 0;
}

.fe-filter-btn-menu {
    ::v-deep .v-list-item {
        min-height: 32px !important;
    }
}
</style>