<template>
    <div>
        <fe-card
            class="alt-card"
            headerText="Incidents"
            width=250
            :menu="tools"
            data-test="student-profile-incidents"
            @menu-item-clicked="menuClick"
        >
            <template v-slot:title-toolbar>
                <span><fe-icon-btn v-if="false" size="14px" useIcon="fas fa-plus"></fe-icon-btn></span>
            </template>
            <template v-slot:body>
                <div v-if="!incidents.length" style="cursor: pointer" @click="loadIncident()">
                    No Incidents Reported
                </div>

                <div v-for="item in incidents" :key="`stdnt-incident-${item.name}`">
                    <div class="d-flex mb-1" style="cursor: pointer" @click="loadIncident(item)">
                        <div class="list-text flex-grow-1">{{ item.name }}</div>
                        <div class="list-text justify-end">{{ item.count }}</div>
                    </div>
                </div>
            </template>
        </fe-card>

        <fe-crud
            ref="crud"
            :config="$models.studentIncidentChart"
            :autoload="false"
            @read="v => items = v"
        />
    </div>
</template>

<script>
export default {
    name: 'StudentIncidentCard',

    props: {
        rec: {type: Object, required: true},
        schoolYears: {type: Array, required: true}
    },

    data() {
        return {
            items: [],
            tools: ["Create", "Download"]
        }
    },

    computed: {
        incidents() {
            if (this.items && this.items.charts && this.items.charts.length > 0) {
                let cfg = this.items.charts[0]
                let data = []
                cfg.data.forEach(d => {
                    data.push({
                        name: d[cfg.x_field],
                        count: d[cfg.y_field],
                        rec: d
                    })
                })

                return data
            } else {
                return []
            }
        },

        bundledSchoolYears() {
            let currYrId = this.$store.state.global.currentYear.id
            return this.schoolYears
                .filter(sy => sy.id <= currYrId && sy.id > currYrId - 3)
                .map(sy => sy.id)
                .sort()
                .reverse()
        }
    },

    watch: {
        'rec.student_id': {
            handler(v) {
                this.$nextTick(() => {
                    if (v) this.loadData()
                })
            },
            immediate: true
        },

        'rec.school_year_id'(v) {
            if (v) this.loadData()
        }
    },

    methods: {
        update() {
            this.loadData()
        },

        menuClick(opt) {
            if (opt === 'Download') {
                this.doDownload()
            }
        },

        doDownload() {
            let p = {
                student_id: this.rec.student_id,
                action: 'get',
                property: 'csv'
            }

            this.$downloadFile('behaviorIncident.php?' + this.$objectToParams(p), 'Student Incidents', '.csv')
        },

        loadData() {
            this.$refs.crud?.read({
                chart_types: 'incident_behavior_type',
                school_year_id: this.rec.school_year_id,
                student_id: this.rec.student_id
            }).then(response => {})
        },

        loadIncident(item = null) {
            this.$store.commit('global/addDockableWindow', {
                name: 'Student Incidents',
                data: item,
                component: 'big-five',
                attrs: {
                    breadcrumbs: true,
                    fixedTools: false,
                    schoolYearSelector: true,
                    params: {
                        student_id: this.rec.student_id,
                        school_year_id: this.bundledSchoolYears
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.list-text {
    font-size: 14px;
}
</style>
