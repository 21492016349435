let standardCrud = {
    read: {
        params: {action: 'get'}
    },
    create: {
        params: {action: 'create'}
    },
    update: {
        params: {action: 'update'}
    },
    destroy: {
        params: {action: 'delete'}
    }
}

export default {
    preferences: {
        defaults: {
            endpoint: 'preferences.php',
            rootProperty: null
        },
        read: {
            params: {action: 'get'},
            rootProperty: 'preferences'
        },
        create: {
            params: {action: 'create'}
        },
        update: {
            params: {action: 'update'}
        },
        destroy: {
            params: {action: 'delete'}
        }
    },
    attendanceTerms: {
        defaults: {
            endpoint: 'crud.php?property=SchoolTerm',
            rootProperty: 'school_term'
        },
        ...standardCrud
    },
    courseTerms: {
        defaults: {
            endpoint: 'crud.php?property=CourseTerm',
            rootProperty: 'course_term'
        },
        ...standardCrud
    },
    attendancePeriods: {
        defaults: {
            endpoint: 'crud.php?property=AttendanceSchoolYear',
            rootProperty: 'attendance_school_year'
        },
        ...standardCrud
    },
    attendanceReasons: {
        defaults: {
            endpoint: 'crud.php?property=AttendanceReason',
            rootProperty: 'attendance_reason'
        },
        ...standardCrud
    },
    attendanceStatuses: {
        defaults: {
            endpoint: 'crud.php?property=AttendanceStatus',
            rootProperty: 'attendance_status'
        },
        ...standardCrud
    },
    attendanceCodes: {
        defaults: {
            endpoint: 'crud.php?property=AttendanceExcused',
            rootProperty: 'attendance_excused'
        },
        ...standardCrud
    },
    attendanceAliases: {
        defaults: {
            endpoint: 'crud.php'
        },
        ...standardCrud
    },
    attendanceTargetSets: {
        defaults: {
            endpoint: 'crud.php?&property=AttendanceTargetSet',
            rootProperty: 'attendance_target_set'
        },
        ...standardCrud
    },
    attendanceTargetSetSchool: {
        defaults: {
            endpoint: 'crud.php?&property=AttendanceTargetSetSchool',
            rootProperty: 'attendance_target_set_school'
        },
        ...standardCrud
    },
    attendanceTargetSetSchoolV: {
        defaults: {
            endpoint: 'crud.php?&property=AttendanceTargetSetSchoolV',
            rootProperty: 'attendance_target_set_school_v'
        },
        ...standardCrud
    },
    attendanceTargetDescriptor: {
        defaults: {
            endpoint: 'crud.php?&property=AttendanceTargetDescriptor',
            rootProperty: 'attendance_target_descriptor'
        },
        ...standardCrud
    },
    attendanceTargets: {
        defaults: {
            endpoint: 'crud.php?&property=AttendanceTarget',
            rootProperty: 'attendance_target'
        },
        ...standardCrud
    },
    attendanceCharts: {
        defaults: {
            endpoint: 'attendance.php?&property=charts',
            rootProperty: 'charts'
        },
        ...standardCrud
    },
    attendanceChartTypes: {
        defaults: {
            endpoint: 'attendance.php?&property=chart_types',
            rootProperty: 'chart_types'
        },
        ...standardCrud
    },
    attendanceDetail: {
        defaults: {
            endpoint: 'attendance.php?action=get&property=attendance_detail',
            rootProperty: 'attendance_details'
        },
        ...standardCrud
    },
    attendancePeriodCharts: {
        defaults: {
            endpoint: 'attendance.php?&property=period_charts',
            rootProperty: 'charts'
        },
        ...standardCrud
    },
    attendanceDatawall: {
        defaults: {
            endpoint: 'attendance.php?&property=datawall',
            rootProperty: 'datawall'
        },
        ...standardCrud
    },
    attendanceRateAdjustments: {
        defaults: {
            endpoint: 'crud.php?property=AttendanceDaysEquivalentAdjustment',
            rootProperty: 'attendance_days_equivalent_adjustment'
        },
        ...standardCrud
    },
    chronicAbsenceSummary: {
        defaults: {
            endpoint: '/api/v3/attendance/chronic-summary',
        },
        ...standardCrud
    },
    chronicAbsenceTrends: {
        defaults: {
            endpoint: '/api/v3/attendance/chronic-trend',
        },
        ...standardCrud
    }
}
