<template>
    <implementation-fidelity :launchpadStyle=launchpadStyle :params="searchParams"></implementation-fidelity>
</template>

<script>
    import { mapState } from 'vuex'
    import ImplementationFidelity from "../modules/intervention/analysis/ImplementationFidelity";
    export default {
        name: 'InterventionParticipationCard',
        components: {ImplementationFidelity},
        props: {
            launchpadStyle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tools: [],
                items: [],
                participationDialog: {
                    show: false
                }
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            searchParams() {
                let p = {}
                if(this.sessionUser) {
                    p.user_id = this.sessionUser.user.id
                }
                if(this.currentYear) {
                    p.school_year_id = this.currentYear.id
                }
                return p
            }
        }
    }
</script>
