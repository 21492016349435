<template>
    <div style="background-color: white;" class="d-flex flex-column">
        <div :style="{ height: height+'px' }" class="d-flex flex-column">
            <div v-show="showChart" class="d-flex justify-end" style="min-height: 40px;">
                <fe-icon-btn
                    small
                    useIcon="fas fa-thumbtack"
                    usage="ghost"
                    v-if="pinnable && !enteringScores"
                    @click="pinDialog.show=true"
                />

                <menu-btn v-if="!enteringScores" style="margin-top: 7px;">
                    <v-list-item v-if="raw.length > 0" @click="doExpand">
                        <i class="fas fa-expand-alt mr-2"></i>Expand
                    </v-list-item>

                    <v-list-item @click="updateText">
                        <span v-if="showAllYearsMonitor"><i class="far fa-check mr-2"></i>Show Selected Year</span>
                        <span v-else><i class="far fa-check-double mr-2"></i>Show All Years</span>
                    </v-list-item>

                    <v-list-item @click="doPrint">
                        <i class="fas fa-print mr-2"></i>Print
                    </v-list-item>

                    <v-list-item @click="startModifyMeasure">
                        <i class="fas fa-edit mr-2"></i>Modify Measure
                    </v-list-item>

                    <v-list-item @click="callLinkMonitor">
                        <i class="fas fa-link mr-2"></i>Link To Intervention
                    </v-list-item>

                    <v-list-item @click="enteringScores=true">
                        <i class="fas fa-table mr-2"></i>Enter Scores
                    </v-list-item>

                    <v-list-item @click="startDeleteMeasure">
                        <i class="fas fa-trash mr-2"></i>Delete Measure
                    </v-list-item>
                </menu-btn>
            </div>

            <highcharts
                v-if="!enteringScores"
                v-show="showChart"
                ref='chartRef'
                class="fe-chart"
                :options="chartOptionsWithMarkers"
            />

            <monitor-decisions v-if="monitorDecisions" :params="monitor" @created="loadData(true)"/>

            <div v-if="showStatement && showChart" class="description">
                <v-icon class="mr-2">fal fa-info-circle</v-icon>
                {{ monitor.sub_category_name }} administered every {{ monitor.schedule_frequency_cnt }}
                {{ monitor.schedule_frequency_cd.toLowerCase() }}(s) on {{ monitor.schedule_week_days }} from
                {{ monitor.schedule_start_date }} to {{ monitor.schedule_end_date }}
            </div>

            <student-intervention-card
                ref="interventionCard"
                :rec="localStudent"
                style="visibility: hidden"
            />

        </div>

        <fe-dialog
            v-if="measureDialog.show"
            title="Modify Measure"
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            :acceptButtonDisabled="canUpdateMeasure"
            persistent
            disableAutoclose
            @accept="updateMeasure"
            @close="measureDialog.show=false"
        >
            <academic-monitor-schedule
                ref="monitorSchedule"
                v-model="measureDialog.record"
                :students="measureDialog.record[0].students"
                standalone
            />
        </fe-dialog>

        <fe-dialog
            v-if="enteringScores"
            title="Enter Score"
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="800"
            persistent
            @accept="enteringScores=false"
            @close="enteringScores=false"
        >
            <div style="height: 60vh;">
                <student-monitor-scores
                    :params="{ student_monitor_plan_id: monitor.student_monitor_plan_id, assessment: { data_point_type_id: monitor.data_point_type_id, sub_category_id: monitor.sub_category_id }}"
                    :showFilters="false"
                />
            </div>
        </fe-dialog>

        <pin-dialog v-model="pinDialog.show" :pinParams="pinParams" :title="pinDialog.title"/>

        <monitor-data v-if="showScoreTable" 
            :chartData="chartData" 
            :maps="maps" 
            :selectedSchoolYear="selectedSchoolYear"
            :showAllYearsMonitor="showAllYearsMonitor"
        />
    </div>
</template>

<script>
import MenuBtn from '@/components/common/button/MenuBtn'
import PinDialog from '@/components/common/PinDialog'
import StudentMonitorScores from '@/components/modules/intervention/monitor/StudentMonitorScores'
import MonitorDecisions from '@/components/modules/intervention/common/MonitorDecisions'
import AcademicMonitorSchedule from '@/components/modules/intervention/creation/AcademicMonitorSchedule'
import MonitorData from '@/components/modules/intervention/workspace/MonitorData'
import windowOptionsMixin from '@/mixins/windowOptions'
import StudentInterventionCard from '@/components/modules/studentprofile/InterventionCard'

export default {
    name: 'AcademicProgressMonitoring',

    components: {
        MenuBtn,
        PinDialog,
        StudentMonitorScores,
        MonitorDecisions,
        AcademicMonitorSchedule,
        MonitorData,
        StudentInterventionCard
    },

    mixins: [windowOptionsMixin],

    props: {
        params: {},
        showStatement: {
            type: Boolean,
            default: false
        },
        pinnable: {
            default: true,
            type: Boolean
        },
        height: {
            type: Number,
            default: 400
        },
        chartData: {
            type: Array
        },
        monitorDecisions: {
            type: Boolean,
            default: false
        },
        showScoreTable: {
            type: Boolean,
            default: false
        },
        currentMonitors: null,
        selectedSchoolYear: {},
    },

    data() {
        let me = this

        return {
            pinDialog: {
                show: false,
                title: ''
            },
            enteringScores: false,
            scoreDisplays: [],
            maps: [],
            scoreDisplayExclusions: [],
            subCategoryId: null,
            dataPointTypeId: null,
            raw: [],
            monitor: {},
            title: '',
            showChart: false,
            chartOptions: {
                chart: {
                    height: this.height - (this.monitorDecisions ? 110 : 40)
                },
                events: {
                    load: function () {}
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                xAxis: {
                    categories: [],
                    plotLines: [],
                    gridLineColor: 'transparent',
                    labels: {
                        // "Enable" every x-axis label, and rely upon formatter to hide the ones
                        // we don't want to show (if it's overcrowded)
                        step: 1,
                        rotation: -45,
                        formatter: (params) => {
                            // If there are so many dates/occurrences such that there would bee too many x-axis
                            // labels, then we manually limit to how often x label is displayed. This can be
                            // accomplished with this formatter by just returning a null
                            if (me.chartOptions.xAxis.categories.length > 20) {
                                let stepSize = Math.floor(me.chartOptions.xAxis.categories.length / 20)

                                // Note that we always want to render the very first and very last item
                                if (!params.isFirst && !params.isLast) {
                                    if (params.pos % stepSize !== 0) {
                                        return null
                                    }

                                    // Also, if this is near the end of the x-axis, then maybe we choose
                                    // not to render this otherwise-valid step because we don't want to render
                                    // 2 labels back-to-back
                                    else if (me.chartOptions.xAxis.categories.length - params.pos <= (stepSize / 2)) {
                                        return null
                                    }
                                }
                            }

                            let dt = new Date(params.value)
                            if (dt == 'Invalid Date') {
                                return params.value // Probably an "Intervention" label, or the name of a monitoring probe, etc.
                            } else {
                                return this.$dayjs(params.value).utc().format('M/D')
                            }
                        },
                    },
                },
                tooltip: {
                    formatter() {
                        let serie = this.series
                        let v = '<b>' + serie.name + '</b><br/>'
                        if (['Accuracy', 'Errors'].indexOf(serie.name) > -1) {
                            return v + me.$twoDecimals(this.y)
                        } else {
                            let monitor = me.monitor
                            if (Object.keys(me.maps).length > 0) {
                                let alpha = me.isAlphaMap(monitor.data_point_type_id, monitor.sub_category_id)
                                if(alpha) {
                                    let i = Math.floor(this.y)
                                    let mappedValue = me.maps[i]
                                    if (mappedValue || mappedValue === 0) {
                                        return `${v}${mappedValue}`
                                    } else {
                                        return v + me.$twoDecimals(this.y)
                                    }
                                } else {
                                    return v + me.$twoDecimals(this.y)
                                }
                            }

                            return v + me.$twoDecimals(this.y)
                        }
                    }
                },
                yAxis: [{
                    endOnTick: false,
                    title: {
                        text: 'Score'
                    },
                    labels: {
                        formatter: function () {
                            let monitor = me.monitor
                            if (Object.keys(me.maps).length > 0) {
                                let alpha = me.isAlphaMap(monitor.data_point_type_id, monitor.sub_category_id)
                                if(alpha) {
                                    let i = Math.floor(this.value)
                                    let mappedValue = me.maps[i]

                                    if (mappedValue || mappedValue === 0) {
                                        return `<div>${mappedValue}</div>`
                                    } else {
                                        return `<div>${this.value}</div>`
                                    }
                                } else {
                                    return `<div>${this.value}</div>`
                                }
                            }
                            return `<div>${this.value}</div>`
                        }
                    },
                    min: 0,
                }, {
                    title: {
                        text: 'Accuracy'
                    },
                    opposite: true,
                    min: 0,
                    max: 100,
                }],
                plotOptions: {
                    area: {
                        fillOpacity: .2,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                legend: {
                    align: 'center',
                    alignColumns: false
                },
                series: []
            },
            measureDialog: {
                show: false,
                record: null,
                students: []
            },
            persistentShowAllYearsMonitor: JSON.parse(window.localStorage.getItem('showAllYearsMonitor')) || false,
        }
    },

    computed: {
        localStudent() {
            return this.raw.student_id
        },

        pinParams() {
            return {
                dashboard_saved_search_type_id: 4,
                student_id: this.monitor.student_id,
                intervention_id: this.monitor.intervention_id ? this.monitor.intervention_id : 0,
                student_monitor_plan_id: this.monitor.student_monitor_plan_id,
                sub_category_id: this.monitor.sub_category_id,
                info_text: this.monitor.info_text
            }
        },

        canUpdateMeasure() {
            let canUpdate = true
            let notRequired = ['start_time', 'end_time', 'intervention_id', 'primary_flag']

            Object.keys(this.measureDialog.record[0]).forEach(field => {
                if (!this.measureDialog.record[0][field] && notRequired.indexOf(field) === -1) {
                    canUpdate = false
                }
            })
            return !canUpdate
        },

        chartOptionsWithMarkers() {
            return Object.assign({}, this.chartOptions, {
                series: this.chartOptions.series.map(serie => {
                    let obj = Object.assign({}, serie)
                    if (obj.name === 'Goal') {
                        obj.marker = {enabled: false}
                    } else if (obj.data.filter(itm => !!itm).length <= 1) {
                        if (!obj.marker) {
                            obj.marker = {enabled: true}
                        } else if (!obj.marker.enabled) {
                            obj.marker.enabled = true
                        }
                    }
                    return obj
                })
            })
        },

        showAllYearsMonitor() {
            return this.persistentShowAllYearsMonitor ?? false
        }
    },

    watch: {
        enteringScores(v) {
            if (!v) {
                this.loadData(true)
            }
        },

        chartData(v) {
            this.processData()
        },
    },

    created() {
        window.addEventListener('beforeunload', () => {
            window.localStorage.setItem('showAllYearsMonitor', JSON.stringify(this.persistentShowAllYearsMonitor))
        })
    },

    mounted() {
        this.loadData()
    },

    methods: {
        callLinkMonitor() {
            // get selected monitor
            // and prepare to call linkMonitor function in InterventionCard
            let selectedMonitor = null
            if (typeof this.currentMonitors !== 'undefined') {
                // linking from progress monitoring page...
                selectedMonitor = this.currentMonitors.find(x => x.student_monitor_plan_id === this.monitor.student_monitor_plan_id)
            } else {
                // linking from monitor expanded window...
                selectedMonitor = this.params.monitor
            }
            this.$refs.interventionCard.linkMonitor(selectedMonitor)
        },

        doExpand() {
            let c = this.$_.cloneDeep(this.chartOptions)
            c.chart.height = window.innerHeight - 150
            this.$store.commit('global/addDockableWindow', {
                name: this.monitor.chart_title,
                component: 'highcharts',
                attrs: {
                    options: c
                }
            })
        },

        doPrint() {
            this.$refs.chartRef.chart.print()
        },

        loadData(forceReload = false) {
            if (this.chartData) {
                // If component is pre-provided chart data from a parent, then we have
                // to ask it to get the new data for us
                if (forceReload) {
                    this.$emit('reload')
                } else {
                    this.raw = this.chartData
                    this.processData()
                }

                return
            }

            // forceReload is irrelevant when this component self-controls the fetching of data; it reloads every time
            this.$modelGet('academicMonitorChart', this.params)
                .then(response => {
                    this.raw = response
                    this.processData()
                })
        },

        processData() {
            if (this.raw.length) {
                // only grab the first probe which will be the primary if it has one
                let monitor = this.raw[0]

                this.monitor = monitor
                this.chartOptions.title.text = monitor.chart_title
                this.scoreDisplays = monitor.score_displays
                this.subCategoryId = monitor.sub_category_id
                this.dataPointTypeId = monitor.data_point_type_id

                if (Object.keys(this.scoreDisplays).length > 0 && this.scoreDisplays[this.dataPointTypeId]) {
                    this.maps = this.scoreDisplays[this.dataPointTypeId]
                }

                let series = []

                monitor.series.forEach(serie => {
                    if (serie.name !== "ec_phaseline") {
                        let s = this.createSerie(serie)
                        series.push(s)
                    }

                })

                // highcharts does not need interpolated data
                let yAxisMaxValue = 0
                monitor.chartData.forEach((data, index) => {
                    this.chartOptions.xAxis.categories.push(data.occurrence)
                    series.forEach(serie => {
                        if (serie.meta && serie.meta.hideInterpolate) {
                            serie.data.push((data[serie.key] || data[serie.key] === 0) && !data.interpolated ? data[serie.key] : null)

                            // Track the highest score (or error count) to best determine the
                            // max y-axis value we should use
                            if (!data.interpolated) {
                                if (data[serie.key]) {
                                    yAxisMaxValue = Math.max(yAxisMaxValue, data[serie.key])
                                }
                            }
                        } else {
                            // These baseline and fadeout values aren't "meta" and are rendered as
                            // singular points, but these are still scores (just outside the respected
                            // date range for any probe) and our max y value should consider them as well
                            let isNormsData = (serie.key.startsWith('ec_norm') && !serie.key.endsWith('-details'))
                            let isRelevantSeries = isNormsData || (['ec_baseline', 'ec_fadeout'].includes(serie.key))

                            // Norms data from backend will very specifically omit the -details series data
                            // (specifically: the nodeType: none aspect of it, but generally the entire data is
                            // omitted), which is an implicit command to render a marker/plot point on this date
                            let normsDataDetails = data[serie.key + '-details']
                            if (isNormsData) {
                                if (normsDataDetails?.nodeType !== 'none') {
                                    if (data[serie.key] || data[serie.key] === 0) {
                                        serie.data.push({
                                            marker: {
                                                enabled: true,
                                                symbol: 'circle',
                                            },
                                            y: parseFloat(data[serie.key]),
                                        })
                                    } else {
                                        serie.data.push(null)
                                    }
                                } else {
                                    serie.data.push({
                                        marker: {enabled: false},
                                        y: (data[serie.key] || data[serie.key] === 0) ? data[serie.key] : null,
                                    })
                                }
                            } else {
                                serie.data.push((data[serie.key] || data[serie.key] === 0) ? data[serie.key] : null)
                            }

                            // Typically we don't consider interpolated data, but norms data should be an exception
                            // when it exists (if the student doesn't have scores for future dates, the payload will
                            // be marked as "interpolated" re: student score, but the norms data must still be considered)
                            if (isRelevantSeries && (isNormsData || !data.interpolated) && data[serie.key]) {
                                if (!isNaN(parseFloat(data[serie.key]))) {
                                    yAxisMaxValue = Math.max(yAxisMaxValue, parseFloat(data[serie.key]))
                                }
                            }
                        }
                    })

                    if (data["ec_phaseline"] !== undefined) {
                        this.chartOptions.xAxis.plotLines.push({
                            color: 'black',
                            width: 2,
                            value: index,
                            label: {
                                text: data.phase_name
                            }
                        })
                    }
                })

                // For the "Goal" trendline, we don't want to enable markers all the way
                // through, but we do want to enable marker for the endpoint only
                let goalSerie = series.find(itm => itm.name === 'Goal')
                if (goalSerie && goalSerie.data?.length > 0) {
                    let lastNonNullIndex = goalSerie.data.length - 1
                    while (lastNonNullIndex > 0 && goalSerie.data[lastNonNullIndex] === null) {
                        lastNonNullIndex -= 1
                    }

                    if (goalSerie.data[lastNonNullIndex] !== null) {
                        // data array is just numerics by default, but we have the ability to convert the
                        // last entry into an object with props, and highcharts will consume it properly
                        goalSerie.data[lastNonNullIndex] = {
                            marker: {
                                enabled: true,
                                symbol: 'circle',
                            },
                            y: parseFloat(goalSerie.data[lastNonNullIndex]),
                        }
                    }
                }

                // If monitor's goal is higher than the max score, then prefer the chart show y-axis values up through that goal
                let goal = parseFloat(monitor.goal)
                if (goal && !isNaN(goal) && goal > yAxisMaxValue) {
                    yAxisMaxValue = goal
                }

                // 1.1x is copied from classic ui, can give a little padding with larger numbers.
                this.chartOptions.yAxis[0].max = yAxisMaxValue ? Math.ceil(1.1 * yAxisMaxValue) : 100

                this.chartOptions.series = series

                this.showChart = true
            }
        },

        startDeleteMeasure() {
            let me = this
            this.$confirmDelete([this.monitor], () => {
                this.$axios.post('studentMonitors.php?action=delete', {monitors: [this.monitor]})
                    .then(resp => {
                        if (resp.data.confirm) {
                            me.$confirmDelete([1], () => {
                                me.$axios.post('studentMonitors.php?action=delete&confirm=1', {monitors: [this.monitor]})
                                    .then(cr => {
                                        if (me.$ecResponse(cr) === false) {
                                            return
                                        }
                                        me.$emit('delete', this.monitor)
                                    })
                            }, () => {

                            }, resp.data.msg)

                            return
                        } else {
                            if (this.$ecResponse(resp) === false) {
                                return
                            }
                            me.$emit('delete', this.monitor)
                        }
                    })
            })
        },

        startModifyMeasure() {
            this.$axios.get(`studentMonitors.php?action=get&student_monitor_plan_id=${this.monitor.student_monitor_plan_id}`)
                .then(r => {
                    let monitors = this.$ecResponse(r, 'monitors')
                    if (monitors === false) {
                        return
                    }
                    if (monitors.length) {
                        let m = monitors[0]
                        this.measureDialog.show = true
                        this.measureDialog.record = [{
                            schedule_id: m.schedule_id,
                            start_date: m.schedule_start_date,
                            end_date: m.schedule_end_date,
                            start_time: m.schedule_start_time,
                            end_time: m.schedule_end_time,
                            monitor_frequency_cnt: m.schedule_frequency_cnt,
                            monitor_frequency_cd: m.schedule_frequency_cd,
                            monitor_week_days: m.schedule_week_days,
                            monitor_user_id: m.monitor_user_id,
                            sub_category_id: {
                                id: m.sub_category_id
                            },
                            primary_flag: m.primary_flag,
                            intervention_id: m.intervention_id,
                            students: [{
                                student_id: m.student_id,
                                student_full_name: m.student_full_name,
                                value: m.goal,
                                assessment_grade_id: m.assessment_grade_id,
                                assessment_grade_desc: m.assessment_grade_desc,
                                assessment_grade_rank: m.assessment_grade_rank,
                            }]
                        }]
                    }
                })
        },

        updateMeasure(confirm) {
            let me = this
            let m = this.measureDialog.record[0]
            let rec = {
                student_monitor_plan_id: this.monitor.student_monitor_plan_id,
                intervention_id: m.intervention_id,
                primary_flag: m.primary_flag,
                assessment_grade_id: m.assessment_grade_id ? m.assessment_grade_id : m.students[0].grade_level ? m.students[0].grade_level : m.students[0].assessment_grade_id,
                student_id: m.students[0].student_id,
                schedule_id: m.schedule_id,
                schedule_week_days: m.monitor_week_days,
                schedule_frequency_cd: m.monitor_frequency_cd,
                schedule_frequency_cnt: m.monitor_frequency_cnt,
                schedule_secondary_cnt: '',
                schedule_start_date: m.start_date,
                schedule_end_date: m.end_date,
                schedule_start_time: m.start_time,
                schedule_end_time: m.end_time,
                sub_category_id: m.sub_category_id.id,
                goal: m.students[0].value ? parseFloat(m.students[0].value) : null,
                monitor_user_id: m.monitor_user_id
            }

            this.$axios.post('studentMonitors.php?action=update' + (confirm ? '&confirm=1' : ''), {monitors: [rec]})
                .then(r => {
                    if (r.data && r.data.confirm) {
                        me.$messageBox({
                            title: 'Confirm',
                            persistent: true,
                            maxWidth: 600,
                            message: r.data.msg,
                            actions: [{
                                text: 'Cancel', primary: false,
                                onClick: () => {
                                    me.measureDialog.show = false
                                }
                            }, {
                                text: 'Update', primary: true,
                                onClick: () => {
                                    me.updateMeasure(true)
                                }
                            }]
                        })
                    } else {
                        if (this.$ecResponse(r) === false) {
                            return
                        }
                        this.measureDialog.show = false
                        this.measureDialog.record = null

                        this.loadData(true)
                    }
                })
        },

        createSerie(serie) {
            let s = {
                name: serie.display,
                key: serie.name,
                color: serie.color,
                yAxis: ['errors', 'accuracy'].indexOf(serie.name) === -1 ? 0 : 1,
                data: [],
                marker: {
                    symbol: serie.marker === 'rect' ? 'square' : null
                }
            }

            if (serie.name === "accuracy") serie.type = 'scatter'
            switch (serie.type) {
                case 'scatter':
                    if (serie.name === "ec_phaseline") {
                        s.type = 'column'
                    } else {
                        s.type = 'scatter'
                        s.zIndex = 10
                        s.marker = {
                            lineWidth: 1,
                            zIndex: 100,
                            lineColor: serie.color,
                            symbol: s.name === 'Accuracy' ? 'circle' : 'triangle'
                        }
                    }

                    break
                case 'line':
                    let isTrend = serie.name.includes('trend_')
                    s.type = isTrend ? 'area' : 'line'

                    if (serie.student_intervention_plan_ids && serie.name.indexOf('score_') != -1) {
                        s.meta = {
                            hideInterpolate: true
                        }
                        s.connectNulls = true
                        s.marker = {
                            enabled: true,
                            symbol: 'circle',
                        }
                    }
                    break
            }
            return s
        },
        updateText() {
            // only want to pass show all years value for specific chart to parent for reload
            this.$emit('showAllYearsClicked', !this.showAllYearsMonitor, this.monitor.student_monitor_plan_id)
            this.persistentShowAllYearsMonitor = !this.showAllYearsMonitor
            window.localStorage.setItem('showAllYearsMonitor', JSON.stringify(this.persistentShowAllYearsMonitor))
        },
        isAlphaMap(dataPointTypeId, subCategoryId) {
            let globalAlpha = this.$_.cloneDeep(this.$store.state.global.alphaMaps)
            let map = (globalAlpha && dataPointTypeId)
                ? globalAlpha.find(itm => itm.data_point_type_id == dataPointTypeId)
                : null
            if (map?.exclusions.indexOf(subCategoryId) == -1) {
                map.maps = map.maps.filter(itm => itm.id != dataPointTypeId)
            } else if (map?.exclusions.indexOf(subCategoryId) >= 0) {
                // The given subcat/assessment is not configured to use Score Display, even though the assessment group may define some
                return false
            }

            return (map?.maps.length > 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.description {
    font-size: 12px;
    padding: 16px;
    margin-left: 20px;

}

.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}
</style>
